:root {
  --primary-color: #5f42aa;
  --primary-color-rgb: 95, 66, 170; // rgba color of #5f42aa (primary-color)
}

$theme-color: var(--primary-color);
$grey: #333333;
$mediumGray: #4f4f4f;
$lightGray: #828282;
$white: #fff;
$black: #000;
$error: #f16667;
$border: #44404080;
$secondaryBtnColor: #8f8f8f;
$inputBorder: #eff0f6;

:export {
  themeColor: $theme-color;
}

// Import styles
@import "~@coreui/coreui/scss/coreui.scss";

// Temp fix for reactstrap
@import "~@coreui/coreui/scss/_dropdown-menu-right.scss";

// If you want to add something do it here
@import "custom";

// ie fixes
@import "ie-fix";

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

body {
  font-family: "Poppins", sans-serif;
  color: $grey;
  background: $white;
  letter-spacing: 0.3px;
}

:root {
  touch-action: pan-x pan-y;
  height: 100%;
}

@mixin ripple {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);

  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(circle, $white 10%, transparent 10.01%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(10, 10);
    opacity: 0;
    transition: transform 0.5s, opacity 1s;
  }

  &:active:after {
    transform: scale(0, 0);
    opacity: 0.3;
    transition: 0s;
  }
}

.form-group {
  margin-bottom: 20px;
}

.btn {
  outline: 0 !important;
  letter-spacing: 0.3px;
  box-shadow: none !important;
  cursor: pointer;
}

.themeBtn {
  max-width: 260px;
  font-weight: 600;
  width: 100%;
  background: $theme-color !important;
  font-size: 16px;
  color: $white !important;
  border: 0 !important;
  box-shadow: none !important;
  border-radius: 30px;
  margin: 10px 0;
  height: 42px;
  line-height: 42px;
  padding: 0 6px;
  @include ripple;
}

select {
  background: url("data:image/svg+xml;utf8,<svg fill='dark gray' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 9px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

video {
  background-color: #111;
}

// header & side menu css
.app-header {
  flex: 0 0 48px;
  height: 48px;
  border-bottom: 1px solid #f0f0f0;

  .nav-item {
    min-width: unset;

    .nav-link {
      font-size: 14px;
      color: #999;
      height: 47px;
      align-items: center;
      display: flex;
      border-bottom: 3px solid transparent;
      margin-top: 3px;
      position: relative;
    }
  }

  .dropdown-item {
    min-width: unset;
  }

  .navbar-toggler {
    outline: 0 !important;
  }

  .nav-item .nav-link .badge {
    border-radius: 3px;
    padding: 2px 5px 1px;
    font-size: 12px;
    background-color: $theme-color;
    color: $white;
    right: -10px;
    top: 4px;
    margin-top: unset;
    left: unset;
  }
}

.navbar-nav {
  .nav-link {

    &.active,
    &.active:focus,
    .active:hover {
      color: $theme-color;
      border-bottom: 3px solid $theme-color;
      outline: 0;
    }

    &:focus,
    &:hover {
      color: $grey;
    }
  }
}

.app-footer .nav-item .nav-link {
  position: relative;

  .badge {
    border-radius: 3px;
    padding: 2px 4px 1px;
    font-size: 12px;
    background-color: $theme-color;
    color: $white;
    right: -10px;
    top: 2px;
    position: absolute;
  }
}

.poweredBy a {
  color: #31c5c7;
  text-decoration: none !important;
  display: inline-block;
  margin-left: 5px;
}

.app-footer {
  flex: 0 0 50px;
  color: #999;
  font-size: 13px;
  border-top: 2px solid #f0f0f0;
  background-color: $white;
  justify-content: space-between;
}

.app-header .navbar-brand {
  height: 50px;
  width: 150px;

  img {
    // width: 85%;
    height: 85%;
    object-fit: contain;
    margin-top: 3px;
  }
}

.loginWrapper {
  width: 100%;
  max-width: 480px;
  margin-top: auto;
  margin-bottom: auto;

  .form-group {
    margin-top: 15px;
  }

  h4 {
    margin-bottom: 25px;
    text-align: center;
    font-size: 20px;
    color: $grey;
    font-weight: 600;
  }
}

.backToLogin {
  text-decoration: none !important;
  display: block;
  text-align: center;
  max-width: 140px;
  margin: 20px auto 0;
  color: $lightGray !important;
}

.orTxt {
  width: 100%;
  max-width: 180px;
  height: 1px;
  background: #e0e0e0;
  display: block;
  margin: 35px auto;
  position: relative;

  span {
    color: #999;
    font-size: 14px;
    background-color: $white;
    padding: 2px 10px;
    letter-spacing: 0.5px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.socialLoginBtn {
  border: 1px solid #b6b6b6 !important;
  height: 46px;
  width: 100%;
  max-width: 300px;
  padding: 0 10px;
  color: $grey !important;
  background: $white !important;
  border-radius: 30px;
  margin: 0 auto 15px;
  line-height: 44px;
  @include ripple;
  font-size: 14px;

  img {
    height: 20px;
    vertical-align: -5px;
    margin-left: 6px;
  }
}

.socialLoginWrap {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.socialBtnIconOnly {
  border-radius: 10px;
  padding: 0 15px;
  background-color: $white !important;
  border: 1px solid #e0e0e0;
  height: 46px;
  margin: 0 10px;

  img {
    height: 24px;
  }
}

.googleSignUpBtn,
.fbSignUpBtn,
.appleSignUpBtn {
  border: 1px solid #b6b6b6 !important;
  height: 46px;
  width: 100%;
  max-width: 300px;
  padding: 0 10px;
  color: $grey !important;
  background: $white !important;
  border-radius: 30px;
  margin: 0 auto 15px;
  line-height: 44px;
  @include ripple;
  font-size: 14px;

  img {
    height: 24px;
    vertical-align: -7px;
    margin-right: 10px;
  }
}

// login
.authFormWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 15px;
  width: 100%;
  position: relative;
}

.installPWA {
  background-color: $theme-color !important;
  height: 42px;
  line-height: 42px;
  font-size: 16px;
  font-weight: 600;
  padding: 0 5px;
  border: 0;
  color: $white !important;
  border-radius: 30px;
  width: 175px;
  box-shadow: none !important;
  position: fixed;
  top: 25px;
  right: 25px;
}

.projectLogo {
  width: 220px;
  cursor: pointer;
  display: block;
  margin: 20px auto 0;
}

.authPgFormWrap {
  border-radius: 10px;
  border: 1px solid #e6e6e6;
  padding: 20px 25px;
  margin-top: 30px;
  margin-bottom: 45px;

  h4 {
    font-size: 20px;
    color: $mediumGray;
    font-weight: 600;
    margin-bottom: 0;
    text-align: center;
  }

  label {
    font-size: 14px !important;
    margin-bottom: 5px !important;
    font-weight: 500;
  }
}

.requestInviteWrapper {
  max-width: 600px;

  p {
    text-align: center;
    font-size: 14px;
    color: $lightGray;
    margin-top: 10px;
    font-weight: 400;
    margin-bottom: 8px;
  }

  #selectCountryCode {
    // border-top-right-radius: 0 !important;
    // border-bottom-right-radius: 0 !important;
    // max-width: 100px;
    // padding: 4px 8px;

    ~input {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }

  select {
    background-position-y: 10px;
  }

  .input-group {
    .input-group-text {
      background: $white;
      box-shadow: none;
      padding: 0;
      padding-right: 10px;
      border: 0;
    }

    input {
      border-top-left-radius: 8px !important;
      border-bottom-left-radius: 8px !important;
    }
  }

  .refreshBtn {
    background-color: transparent !important;
    border: 0 !important;
    font-size: 18px;
    color: $lightGray;
    margin-top: 12px;
    margin-left: 5px;
  }

  a {
    color: $theme-color;
  }

  .form-group {
    margin-top: 0;
  }
}

.loginBtn {
  border: 1px solid $theme-color !important;
  height: 50px;
  max-width: 300px;
  font-size: 14px;
  margin: 30px auto 10px;
  line-height: 48px;
  display: block;
}

.loginFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $lightGray;
  width: 100%;

  * {
    font-size: 13px;
  }

  .socialLinksFooter-Login {
    display: flex;
  }

  .socialLinksFooter a {
    color: $lightGray !important;
  }
}

.socialLinksFooter {
  display: flex;

  a {
    text-decoration: none !important;
    display: inline-block;
  }

  a+a {
    margin-left: 10px;
  }
}

// spinner
.spinnerWrap {
  display: block;
  text-align: center;
  margin-top: 20px;
}

// general
.input-group>.form-control {
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  border: 0;

  &:focus {
    border: 1px solid $theme-color;
  }
}

.input-group-text {
  border-radius: 8px;
  justify-content: center;
}

.customPgHeight {
  min-height: unset;
  height: calc(100vh - 98px);
  overflow: auto;
  background: #fafafa;
}

.customPgHeightMsg {
  height: calc(100vh - 170px);
}

label {
  color: $mediumGray !important;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.3px;
  padding-top: 0 !important;
  line-height: unset !important;
}

.pgTitle {
  margin: 30px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    color: $grey;
    margin-bottom: 0;
    font-weight: 600;
    font-size: 24px;
    text-transform: uppercase;
  }
}

.customBackBtn {
  padding: 0;
  font-size: 20px;
  color: $grey !important;
  margin-right: 5px;
  background: transparent !important;
  border: 1px solid transparent !important;
  @include ripple;
  height: 26px;
  line-height: 26px;
  width: 26px;
  border-radius: 6px;

  &:hover,
  &:focus {
    background: rgba($black, 0.08) !important;
    transition: all 0.3s;
  }

  i {
    margin-left: -1px;
  }
}

.form-control,
.multiSelectWrap .select__control,
.customCreateSelect .select__control {
  color: $lightGray !important;
  padding: 6px 10px;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  border: 1px solid $inputBorder;
  background-color: $inputBorder !important;
  border-radius: 8px;
  letter-spacing: 0.3px;
  height: 46px;

  &:focus {
    box-shadow: none !important;
    border-color: $theme-color;
  }
}

.multiSelectWrap .select__control {
  min-height: 46px;
  height: unset;
}

select.form-control {
  padding-right: 19px !important;
}

.fs-13 {
  font-size: 13px;
}

.fs-14 {
  font-size: 14px;
}

.fs-16 {
  font-size: 16px !important;
}

.noContentFound {
  font-size: 14px;
  text-align: center;
  margin: 25px 0;
  font-weight: 500;
  color: $lightGray;
  line-height: 1.4;
  text-transform: lowercase;

  &::first-letter {
    text-transform: uppercase;
  }
}

.btn-instagram {
  background-color: #e1306c !important;
  border-color: #e1306c !important;

  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(225, 48, 108, 0.5) !important;
  }
}

.btn-tiktok {
  background-color: $black !important;
  border-color: $black !important;
  color: $white !important;
  height: 26px;

  &:focus {
    box-shadow: 0 0 0 0.2rem rgba($black, 0.5) !important;
  }
}

.tiktok {
  width: 11px;
}

.btn-brand {
  @include ripple;
  line-height: 1;
}

.customPopoverBtn,
.customDropdown .nav-link {
  font-size: 16px;
  font-weight: 400;
  color: $mediumGray !important;
  padding: 0.1rem 0.5rem;
  background: transparent !important;
  border: 0 !important;
  box-shadow: none !important;
}

.customPopoverBtn {
  padding: 0 14px;
  height: 30px;

  img {
    height: 24px;
    vertical-align: -7px;
  }
}

.customPopover {
  .popover {
    max-width: unset;
    border: 0 !important;
    box-shadow: 0 1px 4px 0 rgba($black, 0.1);

    .arrow::before {
      border-bottom-color: rgba($black, 0.08);
    }
  }

  .popover-body {
    min-width: 125px;
    padding: 10px;
  }

  .form-check {
    margin-bottom: 8px;
  }

  .custom-control-label {
    font-weight: 400;
    cursor: pointer;
    font-size: 13px;
  }
}

// notifications
.notificationSelect {
  margin-bottom: 0;

  select {
    background-position-y: 7px;
  }

  .form-control {
    border: 0;
    height: 40px;
    padding-right: 32px;
    padding-left: 4px;
    text-transform: uppercase;
    background-color: transparent !important;
  }
}

.customTabContent-Notifications {
  background-color: $white;
  margin-top: 10px;
  border-radius: 12px;

  .nav-link i {
    font-size: 20px;
    display: block;
    margin-bottom: 6px;
  }

  label {
    font-size: 14px !important;
    margin-bottom: 5px !important;
    font-weight: 500 !important;
  }

  .notificationOptions {
    label {
      margin-bottom: 0 !important;
      font-weight: 400 !important;
    }
  }
}

.notificationContentWrap {
  padding: 15px 12px;
  background-color: $white;
  border-radius: 10px;
}

.notificationDay {
  font-size: 13px;
  letter-spacing: 0.5px;
  color: $lightGray;
  margin: 25px 0 5px;
  text-transform: uppercase;
  padding-left: 2px;
}

.notificationsWrap {
  padding: 7px 5px;
  border: 0;
  margin-bottom: 1px;
  background: transparent;

  .userImg {
    height: 46px;
    border: 1px solid rgba($black, 0.05);
    background-image: none;
    width: 46px;
    max-width: 46px;
    min-width: 46px;
    margin-right: 6px;
  }

  &:last-child {
    border: 0;
  }
}

.notifications {
  display: flex;
  justify-content: flex-start;
  // align-items: center;

  .profileID {
    color: $grey;
    font-weight: 600;
    padding: 0 2px 0 0;
    font-size: 13.5px;
    text-decoration: none !important;

    &:hover {
      text-decoration: underline !important;
    }
  }

  span {
    font-size: 13px;
    color: $mediumGray;
    font-weight: 400;
  }

  p {
    font-size: 13px;
    color: $mediumGray;
    display: inline-block;
    margin-block: 1px 0;
    line-height: 1.25;

    .btn {
      padding: 0 2px 1px;
      font-size: 13px;
      color: $theme-color;
      text-decoration: none !important;
    }
  }

  .commentTime {
    display: block;
    margin-left: 0;

    &:before {
      display: none;
    }
  }
}

.highlightRow {
  background-color: rgba(var(--primary-color-rgb), 0.12) !important;

  .notesFan {
    background-color: $white !important;
  }
}

.searchIcon {
  font-size: 13px;
  color: #999;
  top: 11px;
  left: 9px;
  position: absolute;
  z-index: 99;
}

img {
  font-size: 9px;
}

.themeColor {
  color: $theme-color !important;
}

.bgOffWhite {
  background: #f2f2f2;
}

.cursorPointer {
  cursor: pointer;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #ececec;
}

.customPadding {
  padding: 0 15px;
}

// table
.table {
  margin-bottom: 0;

  thead th {
    border: 0;
    font-size: 14px;
    color: $grey;
    padding: 10px;
    font-weight: 600;
  }

  tbody td {
    vertical-align: middle;
    font-size: 14px;
    color: $mediumGray;
    padding: 13px 10px;
    border-top: 0;
  }

  tbody td a {
    color: $theme-color;
  }
}

// modal
.modal-content {
  border: 0;
  box-shadow: none;
  overflow: auto;
  border-radius: 20px;
}

.modal-header,
.modal-footer,
.customImageCropModal .MuiDialogTitle-root,
.customImageCropModal .MuiDialogActions-root {
  padding: 16px 15px 12px;
  border-color: rgba($black, 0.05);
  background-color: $white;
}

.modal-footer,
.customImageCropModal .MuiDialogActions-root {
  padding: 9px 15px;
}

.modal-title,
.customImageCropModal .MuiTypography-h6 {
  font-size: 16px;
  color: $grey;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 1.4;
  font-family: "Poppins", sans-serif;
}

.modal-body {
  color: $mediumGray;
  padding: 20px 15px;
}

.modal-header .close {
  padding: 0.3rem 0.6rem;
  margin: -0.2rem -0.6rem -1rem auto;
  font-size: 26px;
  outline: 0 !important;
  line-height: 18px;
  color: $error;
  opacity: 1 !important;
}

.modalBtnSave,
.modalBtnCancel,
.deleteCoverImg,
.customImageCropModal .modalBtnSave,
.customImageCropModal .modalBtnCancel {
  background-color: $theme-color !important;
  color: $white !important;
  font-size: 14px;
  border: 1px solid $theme-color !important;
  padding: 0 15px;
  @include ripple;
  border-radius: 25px;
  height: 36px;
  line-height: 34px;
  font-weight: 600;
  margin: 0 !important;
  font-family: "Poppins", sans-serif;
  text-transform: capitalize;
}

.modalBtnCancel,
.customImageCropModal .modalBtnCancel {
  background-color: $white !important;
  color: #cccccc !important;
  border: 1px solid #cccccc !important;
  margin-right: 10px !important;
}

.deleteCoverImg {
  background-color: $white !important;
  color: $error !important;
  border: 1px solid $error !important;
}

.modalBtnSave {
  &+.modalBtnSave {
    margin-left: 10px !important;
  }

  &+.modalBtnCancel {
    margin-left: 10px !important;
  }
}

// .modalBtnSave + .deleteCoverImg {
//   margin-left: 10px !important;
// }

.modalBtnYes,
.modalBtnNo {
  background-color: $theme-color !important;
  color: $white !important;
  font-size: 14px;
  font-weight: 600;
  border: 1px solid $theme-color !important;
  padding: 0 25px;
  @include ripple;
  border-radius: 25px;
  height: 36px;
  line-height: 34px;
}

.modalBtnNo {
  background-color: #ccc !important;
  border: 1px solid #ccc !important;
  margin-right: 10px !important;
}

// card feed
.cardFeed {
  border: 0;
  margin: 15px auto;
  border-radius: 12px;
  background: transparent;
  max-width: 580px;

  .card-header {
    background: transparent;
    padding: 10px 0;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0;

    h4 {
      margin-bottom: 2px;
      font-size: 14px;
      color: $grey;
      font-weight: 600;
    }

    .profileID {
      font-size: 12px;
      color: $grey !important;
      text-decoration: none !important;
      cursor: pointer;
      font-weight: 300;
      padding: 0;
    }

    p {
      font-size: 12px;
      color: $grey;
      margin-bottom: 0;
    }
  }

  .card-body {
    padding: 0;
    font-size: 14px;
    font-weight: 400;
    position: relative;

    .customPaddingMob {
      margin-bottom: 7px;
      white-space: pre-wrap;
    }
  }

  .postTime-Feed {
    color: $lightGray;
    font-size: 12px;
    margin-top: 6px;
    margin-left: 1px;
  }

  .card-text {
    font-size: 14px;
    color: $grey;
    margin-bottom: 10px;
  }

  // .short-text {
  //   margin-bottom: 8px;
  //   display: inline-block;
  // }
}

.userImgWrap {
  min-width: 42px;
  min-height: 42px;
  width: 42px;
  height: 42px;
  border: 1px solid rgba($black, 0.12);
  border-radius: 50%;
  padding: 2px;
  margin-right: 8px;
  position: relative;
}

.userImg {
  width: 36px;
  height: 36px;
  max-width: 36px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid $theme-color;
  // background-image: linear-gradient(white, white),
  //   linear-gradient(to right, $theme-color, #fe8a2c 71%, #fea054);
  // background-origin: border-box;
  // background-clip: content-box, border-box;
}

video:focus {
  outline: 0 !important;
}

.footerFeed {
  background-color: transparent;
  border-top: 0;
  padding: 10px 0 0;

  .btn {
    padding: 0 6px;
    font-size: 14px;
    font-weight: 600;
    border: 0;
    background: transparent !important;
    color: $grey !important;
    @include ripple;

    i {
      font-size: 20px;
    }

    &:first-child {
      padding-left: 1px;
    }
  }

  .btn .fa-heart,
  .btn .fa-comment {
    color: $theme-color;
  }

  .fa-comment,
  .fa-comment-o {
    vertical-align: 2px;
  }

  span {
    font-size: 11px;
    color: $lightGray;
    display: inline-block;
    margin-left: 6px;
    margin-right: 6px;

    &:first-child {
      margin-left: 1px;
    }
  }

  img {
    height: 20px;
    vertical-align: -3px;
  }

  svg {
    height: 20px;
    width: 20px;
    fill: $grey;
    vertical-align: 0;
  }

  .fillThemeColor {
    fill: $theme-color;
  }
}

.customDropdown-Feed {
  .btn {
    padding: 0 6px;
    border: 0 !important;
    background: transparent !important;
    @include ripple;
    margin-left: 8px;
  }
}

.customDropdown {
  list-style-type: none;

  .dropdown-menu {
    right: 0 !important;
    left: auto !important;
    top: 26px !important;
    transform: none !important;
    border: 0 !important;
    padding: 0;
    box-shadow: 0 1px 4px 0 rgba($black, 0.1);
  }

  .dropdown-item {
    outline: 0 !important;
    border: 0;
    font-size: 13px;
    padding: 9px 10px 8.5px;
    color: $mediumGray !important;
    background: transparent !important;
  }

  .textWarning {
    color: $error !important;
  }

  .dropdown-item+.dropdown-item {
    border-top: 1px solid rgba($black, 0.05);
  }

  .dropdown-item:active {
    background-color: #f0f3f5;
  }
}

.mediaPhoto-Feed {
  border-radius: 16px;
  height: 100%;
  width: 100%;
  // border: 1px solid rgba($black, 0.01);
  object-fit: cover;
  max-height: 580px;
}

.mediaPhotoWrap-Feed,
.mediaVideoWrap-Feed,
.mediaAudioWrap-Feed {
  width: 100%;
  height: 100%;
  max-height: 580px;
  overflow: hidden;
  border-radius: 16px;
  position: relative;
}

.mediaVideoWrap-Feed {

  video,
  .customVideoWrapper {
    max-height: 580px;
    width: 100%;
  }

  video {
    display: block;
  }
}

.mediaPhotoWrap-Feed {
  video {
    height: 100%;
    width: 100%;
    border-radius: 16px;
    max-height: 580px;
  }
}

.feedCarousel,
.profileCarousel {
  position: relative;
  overflow: hidden;
  border: 1px solid rgba($black, 0.04);
  border-radius: 16px;

  .carousel,
  .carousel-inner,
  .carousel-item {
    height: 450px;
    width: 100%;
    border-radius: 16px;
  }

  .videoPreview {
    height: 100%;
    border-radius: 16px;
    width: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  .playVideo {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 50px;
    width: 50px;
    line-height: 50px;
    color: $white !important;
    font-size: 50px;
    z-index: 99;
    color: $white;
    padding: 0;

    img {
      height: 50px;
    }
  }

  video,
  .carouselImg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 16px;
    background-color: #111;
  }

  .carouselImg {
    object-fit: cover;
  }

  .carousel-control-prev,
  .carousel-control-next {
    opacity: 1;
    width: 42px;
    top: 45%;
    height: 10%;
  }

  .carousel-control-prev,
  .carousel-control-next {
    opacity: 1;
    width: 42px;
  }

  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    height: 24px;
    width: 24px;
    background-color: rgba(255, 255, 255, 0.4);
    box-shadow: 0px 0px 5px 0 rgba($black, 0.3);
    border-radius: 50%;
    line-height: 25px;
  }

  .carousel-control-next-icon {
    padding-left: 2px;
  }

  .carousel-control-prev-icon {
    padding-right: 2px;
  }

  .carousel-control-next-icon:before,
  .carousel-control-prev-icon:before {
    font-size: 14px;
    color: #ccc;
  }

  .audioWrapperFeedCarousel {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // border: 1px solid rgba($black, 0.025);
    border-radius: 16px;
  }

  .carousel-indicators {
    margin-bottom: 0;
    width: fit-content;
    margin: unset;
    left: 50%;
    transform: translate(-50%, 0);
    background: $grey;
    border-radius: 10px;
    padding: 4px;
    max-width: 100%;
    bottom: 6px;
    display: none;

    li {
      width: 6px;
      min-width: 6px;
      height: 6px;
      border-radius: 50%;
      border: 0;
      background: #eee;
    }

    .active {
      background: linear-gradient(rgba(var(--primary-color-rgb), 1),
          rgba(var(--primary-color-rgb), 0.5));
    }
  }

  .mediaCountCarousel {
    z-index: 99;
    right: 10px;
    left: unset;
    top: 10px;
  }

  video::-webkit-media-controls-fullscreen-button {
    display: none;
  }
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  background-image: none;
  cursor: pointer;
  height: 34px;

  &:before {
    content: "\f054";
    font-family: "FontAwesome";
    color: $theme-color;
    font-size: 24px;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

.carousel-control-prev-icon {
  background-image: none;

  &:before {
    content: "\f053";
  }
}

audio:focus {
  outline: 0;
}

.readMore {
  color: $grey;
  font-size: 12px;
  margin-bottom: 7px;

  .btn {
    padding: 0;
    font-size: 12px;
    font-weight: 600;
    color: $mediumGray !important;
    margin-left: 2px;
    vertical-align: 0;
    text-decoration: none !important;
    height: 17px;
  }
}

pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-size: 13px;
  color: $grey;
  font-weight: 400;
  margin-bottom: 0;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.3px;
  line-height: 1.45;
}

.pollOptionWrap {
  display: block;

  .radio {
    margin-bottom: 2px;
    padding-left: 0;
  }

  input {
    visibility: hidden;
  }

  label {
    width: 100%;
    position: relative;

    .pollOptions {
      position: absolute;
      top: 12px;
      left: 12px;
      color: $mediumGray;
      color: #edb300;
      font-size: 14px;
      font-weight: 400;
      width: 80%;
    }
  }

  .form-check-input {
    margin-top: 3px;
  }

  .progress {
    height: 44px;
    border-radius: 60px;
    border: 2px solid #e0e0e0;
    background: $white;
  }

  .progress-bar {
    background: #e0e0e0;
    border-radius: 60px;
  }

  .pollPercent {
    position: absolute;
    right: 12px;
    top: 12px;
    font-size: 14px;
    color: $mediumGray;
    color: #edb300;
    font-weight: 500;
  }

  input:checked {
    &~.progress {
      border: 2px solid $theme-color;
      // background-color: $theme-color;

      .progress-bar {
        background: $theme-color;
      }
    }

    &~.pollOptions,
    &~.pollPercent {
      color: $grey;
      color: #edb300;
    }

    &~.pollOptions {
      font-weight: 500;
    }
  }
}

.totalVotes {
  font-size: 14px;
  color: $lightGray;
}

// post locked
.postLocked,
.postUnlocked {
  position: relative;
}

.postLocked {
  height: 400px;
}

.postLockedOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  border-radius: 8px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  border: 1px solid rgba($black, 0.03);
  padding: 10px 10px 20px;
  z-index: 99;

  h3 {
    font-size: 16px;
    margin-bottom: 15px;
  }

  .iconMedia {
    font-size: 14px;
  }

  .imgMedia {
    height: 16px;
  }

  .iconLock {
    height: 70px;
    margin: auto;
    width: unset;
  }

  h4 {
    font-size: 14px;
    margin-bottom: 10px;
  }
}

// comment section
.commentSection {
  background: $white;
  margin-bottom: 15px;
  border-radius: 8px;
  padding: 0 10px;
  margin-top: -10px;
  max-height: 380px;
  overflow: auto;
  scrollbar-color: #e0e0e0 #f5f5f5;
  scrollbar-width: thin;

  span {
    font-size: 12px;
    color: $lightGray;
    margin-bottom: 5px;
    display: inline-block;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba($black, 0.18);
    box-shadow: inset 0 0 6px rgba($black, 0.1);
    border-radius: 0;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 3px;
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 0;
    -webkit-box-shadow: inset 0 0 6px rgba($black, 0.18);
    box-shadow: inset 0 0 6px rgba($black, 0.1);
    background-color: #e0e0e0;
  }
}

.cardFeed+.commentSection {
  max-width: 580px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
  padding-right: 7px;
}

.typeMsgWrap {
  margin-top: auto;
  padding: 8px 8px 8px 0;

  .emoji-picker-react {
    margin-bottom: 5px;
  }
}

.typeMsgWrap,
.commentSection,
.commentShoutout,
.commentArea-liveStream,
.comments-Post {
  textarea {
    scrollbar-color: #e0e0e0 #f5f5f5;
    scrollbar-width: thin;
    appearance: none;
    -webkit-appearance: none;
  }

  textarea::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba($black, 0.18);
    box-shadow: inset 0 0 6px rgba($black, 0.1);
    border-radius: 0;
    background-color: #f5f5f5;
  }

  textarea::-webkit-scrollbar {
    width: 3px;
    background-color: #f5f5f5;
  }

  textarea::-webkit-scrollbar-thumb {
    border-radius: 0;
    -webkit-box-shadow: inset 0 0 6px rgba($black, 0.18);
    box-shadow: inset 0 0 6px rgba($black, 0.1);
    background-color: #e0e0e0;
  }
}

.comments-Post {
  background: #f2f2f2;
  border-radius: 12px;
  padding: 8px 13px 8px 14px;
  font-size: 14px;
  color: $mediumGray;
  position: relative;
  width: fit-content;
  border-top-left-radius: 0;

  p {
    font-size: 14px;
    color: $grey;
    margin-bottom: 0;
  }
}

.commentOptions {
  .nav-link {
    padding: 0;
    margin-left: 4px;
  }

  .nav-link i {
    padding: 0;
    width: 22px;
    height: 22px;
    max-width: 22px;
    line-height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba($black, 0.3);
    border-radius: 50%;
    color: $lightGray;
    font-size: 14px;
  }

  .dropdown-item i {
    margin-left: 0;
    margin-right: 6px;
    color: $lightGray !important;
  }

  .dropdown-menu {
    top: 24px !important;
  }
}

.replyBtn,
.commentLikeBtn {
  font-size: 13px;
  color: $lightGray !important;
  padding: 0 4px;
  height: 26px;
  line-height: 26px;
  margin: 0;
  background: transparent !important;
  border: 0;
  box-shadow: none !important;
}

.commentLikeBtn {
  margin-left: 8px;

  .fa {
    margin-right: 5px;
    vertical-align: -1px;
  }

  .fa-heart {
    color: $theme-color;
  }

  span {
    vertical-align: -1px;
  }
}

.commentTime,
.userName-Comment {
  font-size: 12px;
  color: $lightGray;
  margin-bottom: 0;
}

.commentTime {
  color: $lightGray;
  margin-left: 15px;
  position: relative;

  &:before {
    content: "\f111";
    color: #bbb;
    font-family: "fontAwesome";
    font-size: 4px;
    position: absolute;
    top: 7px;
    left: -9px;
  }
}

.userImg-Comment {
  min-width: 34px;
  min-height: 34px;
  width: 34px;
  height: 34px;
  border: 1px solid rgba($black, 0.05);
  border-radius: 50%;
  margin-right: 6px;
  object-fit: cover;
}

.emojiBtn {
  position: absolute;
  bottom: 2px;
  display: none;
  right: 46px;
  padding: 0.2rem 0.4rem;
  text-decoration: none !important;
  font-size: 15px;
  color: $theme-color !important;
}

.emojiBtnCreatePost {
  position: absolute;
  bottom: 8px;
  right: 4px;
  padding: 0 4px;
  font-size: 20px;
  color: $theme-color !important;
  background-color: transparent !important;
  height: 26px;
  line-height: 26px;
  border: 0;
}

// tab css
.customTabs {
  border-bottom: 1px solid #e6e6e6;

  .nav-item {
    flex: 0.5;
    margin-bottom: -1px;
  }

  .nav-link,
  .nav-link:hover {
    border: 0;
    border-bottom: 2px solid transparent;
    text-align: center;
    padding: 0.4rem 0.25rem;
    color: $lightGray !important;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
  }

  .nav-link {
    div {
      font-size: 20px;
    }

    .activeIcon {
      display: none;
    }

    .inactiveIcon {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .shoutIcon {
    color: #e0e0e0;
    font-size: 23px;
    margin-top: -4px;
  }

  .nav-link.active {
    border-bottom: 2px solid $theme-color;
    background: transparent;
    color: $grey !important;

    .activeIcon {
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-top: -2px;
    }

    .inactiveIcon {
      display: none;
    }

    .shoutIcon {
      color: $grey;
    }
  }

  .activeIcon,
  .inactiveIcon {
    height: 24px;
  }
}

.customTabContent {
  border: 0;
  margin-top: 0;
  background: transparent;

  .tab-pane {
    padding: 2px;
  }
}

// feed
.paymentBtnTier {
  margin: 0 6px 8px;
  width: 100%;
  max-width: 230px;
  background: $theme-color !important;
  font-size: 14px;
  color: $white !important;
  border: 0 !important;
  box-shadow: 0 0.125rem 0.25rem rgba($black, 0.075) !important;
  outline: 0 !important;
  border-radius: 25px;
  padding: 6px 4px 5px;
  @include ripple;
}

.sharedContent {
  border: 1px solid rgba($black, 0.08);
  border-radius: 16px;
  // margin-top: 5px;
  overflow: hidden;
  padding-top: 12px;

  .mediaPhotoWrap-Feed,
  .mediaVideoWrap-Feed,
  .mediaPhoto-Feed {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    cursor: pointer;
  }

  .mediaPhoto-Feed {
    border: 0;
    border-top: 1px solid rgba($black, 0.01);
  }

  .customPaddingMob {
    padding: 0 12px;
  }

  .ppvVideoName,
  .liveStreamEventName {
    margin-bottom: 7px;
  }
}

.sharedContentVault {
  padding-top: 0;

  .mediaPhotoWrap-Feed {
    margin-top: 0;
    cursor: default;
  }

  .mediaPhoto-Feed {
    border: 0;
    cursor: default;
  }
}

.createPostTxtWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;

  .form-control {
    width: max-content;
  }

  h4 {
    margin-bottom: 0;
    font-size: 24px;
    color: $grey;
    text-transform: uppercase;
    font-weight: 600;
  }
}

.expirationRadioBtn {
  margin: 10px 16px 10px 0;
}

.dateTimePickerWrap {
  .rdtPicker {
    width: 100%;
    border: 0;

    .rdtDays table {
      width: 80%;
      margin: 0 auto;
      border: 1px solid $theme-color;

      tr th,
      tr td {
        text-align: center;
        padding: 3px;
        font-size: 14px;
        letter-spacing: 0.3px;
        color: $grey;
      }

      tr td {
        height: 32px;
        padding: 0 3px;
        line-height: 32px;
        font-size: 14px;
        color: $lightGray;
      }

      tr .rdtNext span,
      tr .rdtPrev span {
        font-size: 28px;
        cursor: pointer;
        color: $mediumGray !important;
      }

      tr .rdtSwitch {
        padding: 6px 3px 5px !important;
        color: $grey !important;
        font-weight: 600;
        font-family: "Poppins", sans-serif;
      }

      tr td.rdtActive {
        background: $theme-color !important;
        color: $white !important;
        border-radius: 4px;
      }
    }

    .rdtTimeToggle {
      padding: 10px 3px !important;
      color: $grey !important;
      font-family: "Poppins", sans-serif;
    }
  }

  .rdtTime table {
    width: 80%;
    margin: 0 auto;
    border: 1px solid $theme-color;
  }

  .rdtTime .rdtSwitch {
    height: 50px;
    padding-top: 4px;
  }
}

.rdtPicker {
  border: 1px solid rgba($black, 0.12);
  box-shadow: none;
  margin-top: 3px;

  th {
    border-bottom-color: rgba($black, 0.08);
  }

  tfoot {
    border-color: rgba($black, 0.08);
  }

  td.rdtActive,
  td.rdtActive:hover {
    background: $theme-color !important;
  }

  th.rdtSwitch {
    font-weight: 500;
    height: 30px;
    line-height: 30px;
  }

  td.rdtToday:before {
    border-bottom-color: $theme-color !important;
  }

  .dow {
    padding-top: 10px !important;
    font-weight: 600;
  }

  .rdt .form-control {
    width: 75%;
    display: block;
    margin: 0 auto;
    font-size: 15px;
  }

  .rdtTime .rdtCounter {
    width: 55px;
  }

  .rdtCounter .rdtCount {
    height: 29%;
    color: $grey;
  }

  .rdtTime table {
    width: 100%;
    overflow-x: hidden;
  }

  .rdtTime .rdtCounters {
    margin-top: 0;
    margin-bottom: 6px;
  }

  .rdtTime .rdtBtn {
    font-size: 16px;
  }

  .rdtCounterSeparator {
    padding-top: 9px;
  }

  .rdtTime table {
    margin-top: 0;
  }

  .rdtTimeToggle {
    padding-top: 4px;
  }
}

.createPostWrap {
  margin-top: 10px;
  margin-bottom: 3px;
}

.sendMsg {
  background: transparent !important;
  padding: 0 4px;
  height: 36px;
  line-height: 36px;
  border: 0 !important;
  @include ripple;
  position: absolute;
  right: 8px;
  bottom: 12.5px;

  img {
    width: 20px;
  }
}

.createPostBtnsWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 7px;
}

.createPostBtnsWrap {
  display: flex;
  align-items: center;
}

.filesSelected-CreatePost {
  font-size: 12px;
  color: $mediumGray;
  margin-top: 5px;
  margin-bottom: 7px;
}

.mediaBtn {
  border-radius: 50%;
  padding: 0;
  height: 30px;
  width: 30px;
  margin-right: 8px;
  margin-bottom: 0;
  background-color: transparent !important;
  border: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 21px;
  }

  .videoIcon {
    height: 20px;
  }

  .audioIcon,
  .priceIcon {
    height: 22px;
  }
}

.currencyPostPrice {
  position: absolute;
  top: 16px;
  left: 110px;
  font-size: 14px;
  color: $lightGray;
  z-index: 99;
}

.sendMsgBtn {
  background: transparent !important;
  padding: 0;
  height: 28px;
  width: 28px;
  border: 0;

  img {
    height: 23px;
  }
}

.msgPreviewWrap {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  scrollbar-color: #e0e0e0 #f5f5f5;
  scrollbar-width: thin;
  margin-top: 10px;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba($black, 0.18);
    box-shadow: inset 0 0 6px rgba($black, 0.1);
    border-radius: 0;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0;
    -webkit-box-shadow: inset 0 0 6px rgba($black, 0.18);
    box-shadow: inset 0 0 6px rgba($black, 0.1);
    background-color: #e0e0e0;
  }
}

.msgPreview {
  border: 1px solid rgba($black, 0.08);
  border-radius: 8px;
  position: relative;
  margin: 7px 10px 5px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $white;
  font-size: 14px;
  height: 57px;
  width: 76px;
  min-width: 76px;

  span {
    padding: 6px;
    text-align: center;
    font-size: 14px;
  }

  img,
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }
}

.addPreviewVideo {
  color: $theme-color;
  padding: 0;
  height: 30px;
  margin-left: 0;
  font-size: 12px;
  text-decoration: none !important;
}

.msgPreview-Media {
  width: 54px;
  min-width: 50px;
  max-width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 4px;
}

.msgPreviewCustomDimension {
  width: 56px;
  height: 56px;
  min-width: 56px;
  max-width: 56px;
  padding: 2px;
  font-size: 15px;
}

.deletePreview {
  position: absolute;
  top: -5px;
  right: -5px;
  width: 18px;
  height: 18px;
  padding: 0;
  font-size: 12px;
  line-height: 18px;
  border-radius: 50%;
  color: #ff2323 !important;
  background-color: $white !important;
  border: 0;
  text-align: center;
  box-shadow: 0px 1px 5px 0px rgba($black, 0.22) !important;
}

// .tipWrap {
//   background: $theme-color;
//   color: $white;
//   padding: 0 20px 0 15px;
//   border-radius: 12px;
//   font-size: 14px;
//   font-style: italic;
//   border: 0;
//   height: 44px;
//   line-height: 44px;
//   margin-bottom: 4px;
//   font-weight: 500;
// }

.tipWrap,
.paidMessageText {
  background: $theme-color;
  color: $white;
  padding-left: 14px;
  padding-right: 14px;
  border-radius: 11px;
  font-size: 14px;
  font-style: italic;
  font-weight: 500;
  height: 60px;
  line-height: 60px;
}

.paidMessageText {
  width: fit-content;
  font-style: normal;
  margin-bottom: 3px;
  margin-top: 5px;
}

// ppv
.toggleBtn {
  font-size: 14px;
  color: $grey !important;
  padding: 0 0.4rem;
  background: transparent !important;
  border: 0 !important;
  box-shadow: none !important;
  display: none;
  margin-right: 5px;
}

.addBtn {
  width: max-content;
  font-size: 14px;
  margin: 0;
  cursor: pointer;
  line-height: 38px !important;
  height: 38px;
  padding: 0 20px;
}

.customFilterMob {
  margin-top: 5px;
  padding: 10px 10px 0;
  margin-bottom: 15px;
  background-color: $white;

  .input-group>.form-control {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
}

.customFilterWrap {
  background-color: $white;
  border-radius: 15px;
  padding: 10px 8px;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 25px;
  align-items: flex-end;

  label {
    font-size: 14px !important;
    margin-bottom: 5px !important;
  }

  .form-group,
  .addBtn {
    margin: 5px;
  }

  .form-control {
    padding: 6px;
  }

  .input-group {
    width: 200px;
    margin: 5px;

    .form-control {
      border-top-right-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
      border: 1px solid $inputBorder;

      &:focus {
        border: 1px solid $theme-color;
      }
    }
  }
}

.liveEventWrapper {
  margin-left: 0;
  margin-right: 0;
  background: $white;
  border-radius: 10px;
}

.ppvDetailsWrap {
  width: 100%;
  margin-top: 20px;
}

.videoPreviewTag,
.videoPreviewTag-Feed {
  background-color: $theme-color;
  color: $white;
  font-size: 12px;
  font-weight: 600;
  border-radius: 5px;
  padding: 0 6px;
  position: absolute;
  bottom: 10px;
  right: 10px;
  line-height: 23px;
  height: 23px;
  z-index: 99;
}

.videoPreviewTag-Feed {
  bottom: unset;
  top: 15px;
}

.videoPreviewLiveEvent {
  top: 5px;
  left: 5px;
  right: unset;
  bottom: unset;
}

.videoPlayBtn {
  border: 0;
  color: $white !important;
  font-size: 50px;
  background: transparent !important;
  box-shadow: none !important;
  @include ripple;
  padding: 0 2px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 50px;
  text-shadow: 0px 0px 7px rgba($black, 0.3);
}

.purchaseNViewsWrap {
  margin-top: 12px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;

  span {
    font-size: 12px;
    color: $lightGray;
    text-transform: uppercase;
  }

  p {
    font-size: 14px;
    color: $grey;
    font-weight: 600;
    margin-bottom: 5px;
    margin-left: 1px;
  }
}

// live events
.upcomingPastEventWrap {
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    margin-bottom: 0;
    color: $grey;
    font-size: 16px;
    font-weight: 500;
  }

  .react-switch {
    margin-right: 5px;
    margin-top: -1px;

    .react-switch-bg {
      border: 0;
    }
  }
}

.react-switch .react-switch-bg {
  border: #e5e5e5 solid 1px;
}

.liveStreamEventName,
.ppvVideoName {
  font-size: 14px;
  margin-bottom: 8px;
  font-weight: 600;
  color: $grey;
}

.ppvVideoName {
  cursor: pointer;
}

.eventPrice {
  display: flex;

  div+div {
    margin-left: 18px;
  }
}

.eventPriceWrap {
  color: $grey;
  font-size: 13.5px;
  font-weight: 500;
  margin-bottom: 10px;
}

.eventAttendingCount {
  font-size: 14px;
  color: $white;
  font-size: 14px;
  font-weight: 400;
  padding: 0 12px;
  background-color: rgba($white, 0.15);
  border-radius: 10px;
  position: absolute;
  right: 8px;
  top: 8px;
  line-height: 34px;
  height: 34px;
  cursor: pointer;
  z-index: 1;
}

.liveStreamEventDate {
  font-size: 14px;
  position: absolute;
  background-color: $theme-color;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  color: $white;
  z-index: 99;
  top: 0;
  left: 8px;
  padding: 10px 8px 8px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;

  .eventTime {
    margin-top: 2px;
    text-transform: uppercase;
  }
}

.ppvStatus {
  padding-left: 6px;
  padding-right: 6px;
  background-color: $theme-color;
  border-radius: 6px;
  position: absolute;
  right: 8px;
  top: 8px;
  height: 24px;
  line-height: 24px;
  color: $white;
  font-size: 12px;
  font-weight: 600;
}

.rsvpBtn {
  background-color: $theme-color !important;
  border-radius: 12px;
  padding: 0 6px;
  border: 1px solid $theme-color !important;
  outline: 0 !important;
  box-shadow: none !important;
  color: $white !important;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  position: absolute !important;
  bottom: 8px;
  right: 8px;
  @include ripple;
  width: 120px;
  height: 44px;
  line-height: 1;
}

.rsvpStatusWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  border-bottom: 2px solid #f2f2f2;
  padding-bottom: 20px;

  div {
    flex: 1;
  }

  h4 {
    color: $theme-color;
    margin-bottom: 4px;
    font-size: 18px;
  }

  p {
    font-size: 14px;
    margin-bottom: 0;
    color: $mediumGray;
  }
}

.ppvPreviewStatsWrap {
  padding: 15px;
  border-radius: 12px;
  background: $white;

  audio {
    width: 96%;
    margin: 0 auto;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.cancelledEventWrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  background: rgba($black, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;

  div {
    font-size: 16px;
    background-color: #dc3545;
    color: $white;
    height: 44px;
    line-height: 44px;
    border-radius: 12px;
    padding: 0 25px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-weight: 600;
  }
}

.saveBtn,
.cancelBtn {
  max-width: max-content;
  padding: 0 30px;
  height: 46px;
  margin: 25px auto 15px;
  display: block;
  line-height: 46px;
}

.cancelBtn {
  background: $white !important;
  border: 1px solid rgba($black, 0.1) !important;
  color: #999 !important;
  font-size: 16px;
  @include ripple;
  border-radius: 25px;
}

// vault
.vaultFolderWrap {
  border: none;
  border-radius: 16px;
  margin-bottom: 15px;

  .card-body,
  .folderCoverPic {
    border-radius: 16px;
    height: 210px;
    width: 100%;
    padding: 0;
    position: relative;
    cursor: pointer;
    overflow: hidden;
  }

  .folderCoverPic {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  video {
    height: 100%;
    width: 100%;
    max-height: 210px;
    border-radius: 16px;
  }

  .folderPrice {
    background: $theme-color;
    color: $white;
    border-radius: 25px;
    cursor: text;
    padding: 0 8px;
    font-size: 12px;
    font-weight: 600;
    line-height: 23px;
    height: 23px;
  }

  .vaultFolderInfo {
    display: flex;
    justify-content: space-between;
    // align-items: center;
    position: absolute;
    padding: 8px 8px 0;
    width: 100%;
    left: 0;
    top: 0;
    // z-index: 98;
  }

  .vaultInfo {
    position: absolute;
    padding: 0 8px 8px;
    left: 0;
    bottom: 0;
    width: 100%;

    h4 {
      font-size: 14px;
      margin-bottom: 2px;
      color: $white;
      font-weight: 600;
    }

    p,
    .folderItemsCount {
      font-size: 12px;
      color: $white;
      margin-bottom: 0;
    }

    h4,
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }

    .folderItemsCount {
      display: flex;
      justify-content: space-between;
      margin-top: 5px;
      font-weight: 600;
    }
  }

  .customDropdown-Feed .btn {
    background-color: rgba($white, 0.12) !important;
    color: $white;
    border-radius: 15px;
    height: 20px;
    padding: 0 9px;
    z-index: 110;
  }

  .react-loading-skeleton {
    vertical-align: 3px;
  }

  .vaultFolderPreview {
    width: 100%;
    height: 100%;

    div:first-child {
      width: 100%;
      height: 100%;
    }
  }
}

.activeTxt,
.inactiveTxt {
  font-size: 14px;
  color: $theme-color;
  margin-left: 8px;
}

.inactiveTxt {
  color: $lightGray;
}

.customBreadcrumb {
  .breadcrumb {
    background: transparent;
    border: none;
    padding: 0 0.2rem;
    margin-bottom: 0;
  }

  .breadcrumb li {
    font-size: 18px;
    color: $theme-color;
    display: flex;
    align-items: center;
  }

  .breadcrumb li a {
    color: $grey;
    font-weight: 600;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 24px;
  }
}

.filesTxt {
  font-size: 16px;
  color: $grey;
  margin-bottom: 0;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.vaultDesc {
  background: $white;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 20px;
  font-size: 14px;

  label {
    font-size: 14px !important;
    margin-bottom: 0 !important;
    font-weight: 500 !important;
  }

  .btn {
    color: $theme-color;
    padding: 0 6px;
    margin-left: 8px;
    height: 30px;
    text-decoration: none;
  }
}

.infoIcon {
  cursor: pointer;
  font-size: 14px;
  color: $theme-color;
  margin-left: 4px;
  top: -0.3rem;
}

.videoInModal,
.pdfInModal {
  width: 91%;
  height: 91%;
  position: relative;
  top: 4.5%;
  left: 4.5%;
  outline: 0 !important;
}

.pdfInModal {
  height: calc(100vh - 11.5%);
}

.audioInModal {
  outline: 0 !important;
  max-width: 600px;
  width: 91%;
  margin: 0 auto;
  display: block;
}

.vaultEarningStatsWrap {
  border-radius: 12px;
  background: $white;
  padding: 20px 15px;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    color: $grey;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
  }

  .btn {
    height: 40px;
    background-color: transparent !important;
    border: 0;
    font-size: 14px;
    color: $theme-color !important;
    padding: 0 2px;
  }

  label {
    margin-bottom: 5px !important;
    font-size: 14px !important;
    font-weight: 400 !important;
  }
}

.sharePostWrap {
  margin-top: 15px;

  img {
    width: 133px;
    height: 100px;
    border-radius: 8px;
    background-color: $inputBorder;
    object-fit: cover;
    margin-bottom: 10px;
    border: 1px solid rgba($black, 0.03);
  }

  .short-text {
    font-size: 14px;
  }

  p {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
  }

  .eventPrice {
    font-size: 12px;
  }
}

.customFilterShare {
  margin-top: 20px;

  p {
    font-size: 14px;
    color: $mediumGray;
    font-weight: 500;
    margin-bottom: 0;
  }

  .custom-switch {
    .custom-control-label::after {
      top: 4px;
    }
  }

  .setExpirationDateWrap {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;

    .custom-control {
      margin-top: 12px;
      width: 33.33%;
    }

    label {
      color: $mediumGray;
      font-weight: 400;
    }
  }
}

.postEarningHeader {
  font-size: 16px;
  margin-bottom: 10px;

  span {
    color: $theme-color;
    font-weight: 600;
  }
}

// FAQ
.faqCategoryTitle {
  font-size: 18px;
  color: $grey;
  font-weight: 600;
  margin-top: 25px;
}

.someTipsFaq {
  font-size: 14px;
  color: $lightGray;
  line-height: 1.4;
  font-weight: 400;

  a {
    color: $theme-color;
  }
}

#accordion {
  margin-bottom: -1px;

  .card {
    border: 0;
    border-top: 1px solid #4d4d4d;
    border-bottom: 1px solid #4d4d4d;
    border-radius: 0;
    margin-bottom: 0;
  }

  .card-header {
    background: $white;
    border: 0;
    padding: 16px 5px 14px;
    border-radius: 0;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    i {
      font-size: 14px;
      color: $mediumGray;
      margin-top: 1px;
      margin-left: 5px;
      margin-right: 0;
      transition: all 0.3s;
    }
  }

  .card-header[aria-expanded="true"] i {
    transform: rotate(-180deg);
    transition: all 0.3s;
    margin-top: 3px;
  }

  .faqQues,
  p {
    font-size: 14px;
    line-height: 1.4;
    color: $grey;
    margin-bottom: 0;
    font-weight: 500;
  }

  .card-body {
    padding: 15px 5px;
    border-top: 1px solid #4d4d4d;
    border-bottom: 0;
    border-radius: 0 !important;
  }

  p {
    color: $lightGray;
    font-weight: 400;
  }

  a {
    color: $theme-color;
    display: inline-block;
    text-decoration: underline !important;
  }
}

.youtubeEmbedWrapper {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  box-shadow: 0 1px 4px 0 rgba($black, 0.1);
  max-width: 100%;
  margin: 25px 0 10px;
}

.youtubeEmbedWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.shareEarnTxt {
  font-size: 16px;
  color: $grey;
  line-height: 1.4;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 35px;
}

.faqSectionHeader {
  color: $grey;
  margin-bottom: 10px;
  font-size: 17px;
  padding-left: 1px;
}

// promote
.promoteAccount {
  padding: 18px 15px;
  margin-bottom: 20px;
  border: 1px solid rgba(201, 201, 201, 0.8);
  border-radius: 15px;
  display: flex;
  align-items: center;

  h5 {
    font-size: 17px;
    font-family: inherit;
  }

  .btn-share {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    margin-left: 10px;
    border-radius: 50%;
  }

  .copyClipboardBtn {
    font-size: 14px;
    border-radius: 25px;
    max-width: fit-content;
    padding: 0 18px;
    margin: 0 0 0 10px;

    i {
      font-size: 16px;
    }
  }
}

.btn-telegram {
  background-color: #34aadf !important;
  color: $white !important;
  border-color: #34aadf !important;
}

.btn-twitter {
  background-color: #18a0fb !important;
  border-color: #18a0fb !important;
}

.inviteCreatorForm {
  margin-top: 40px;
}

.countryWithCode {
  background-color: $inputBorder;
  padding: 3px 0 3px 3px;
  border-radius: 8px !important;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;

  select {
    width: 190px;
    background-color: $white !important;
    border-top-left-radius: 6px !important;
    border-bottom-left-radius: 6px !important;
    border-radius: 0;
    height: 40px;
    background-position-y: 6px;
  }

  input {
    height: 40px;
    border-radius: 0 8px 8px 0;
  }
}

.inviteCountLeft {
  font-size: 14px;
  color: $theme-color;
  width: 150px;
  margin-left: auto;
  text-align: center;
  font-weight: 500;
}

.inviteBtn {
  max-width: 150px;
  margin: -8px 0 10px auto;
  display: block;
}

.referralHistoryTable {
  margin-top: 20px;
  border: 1px solid rgba(201, 201, 201, 0.8);
  border-radius: 20px;
  padding: 15px;

  table {
    margin-bottom: 0;
  }
}

.invitesLeftMobView {
  display: none;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 20px;

  h4 {
    color: $theme-color;
    font-weight: 500;
    font-size: 16px;
  }

  p {
    letter-spacing: 0.5px;
    color: $lightGray;
    font-size: 14px;
    padding: 0 15px;
  }
}

.sendInviteBtn {
  background-color: transparent !important;
  border: none;
  font-size: 18px;
  padding: 0 8px;
  height: 30px;
  color: $grey !important;
  display: none;
  margin-left: auto;
  margin-top: 1px;
}

.referralHistoryList {
  display: none;
  margin-top: 15px;

  li {
    display: flex;
    justify-content: space-between;
    border: 0;
    padding: 9px 0;

    .referralProfile {
      width: 50px;
      height: 50px;
      min-width: 50px;
      margin-right: 8px;
      border-radius: 50%;
      border: 1px solid $theme-color;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }

      i {
        font-size: 24px;
        color: $lightGray;
      }
    }

    .referralInfo {
      h5 {
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 0;
        margin-top: -1px;
        color: $grey;
      }

      p {
        color: $lightGray;
        font-size: 13px;
        margin-top: 2px;
        margin-bottom: 0;
      }
    }

    .badge {
      font-size: 12px;
      margin-left: 5px;
      border-radius: 5px;
      padding: 7px 10px;
      font-weight: 500;
    }

    .badge-success {
      background: #5bd19f33 !important;
      color: #31b27c !important;
    }

    .badge-danger {
      background: #ff424133;
      color: #ff4241 !important;
    }

    .badge-pending {
      background: #fdb92733 !important;
      color: #fdb927;
    }
  }
}

.info {
  font-size: 10px;
  color: $mediumGray;
}

.referralHistoryTxt {
  font-size: 18px;
  // margin-top: 10px;
  color: $grey;
  font-weight: 500;
  margin-bottom: 0;
}

.tableDataStatus {
  padding: 10px 2px;
  width: 110px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 50px;

  &.badge-success {
    background: #5bd19f33;
    color: #31b27c !important;
  }

  &.badge-danger {
    background: #ff424133;
    color: #ff4241 !important;

    a {
      color: #ff4241 !important;
    }
  }

  &.badge-pending,
  &.badge-warning {
    background: rgba(253, 185, 39, 0.2);
    color: #fdb927 !important;
  }
}

.badge-warning {
  background: rgba(253, 185, 39, 0.2);
  color: #fdb927 !important;
}

// broadcast message stats page
.broadcastMsgTxt {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 180px;
  display: block;
}

.mediaPreview {
  width: 80px;
  height: 60px;
  margin-top: 4px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba($black, 0.1);

  img,
  video {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.listInPopover {
  p {
    font-size: 12px;
    margin-bottom: 4px;
    margin-top: 4px;
    color: $mediumGray;
    font-weight: 500;
  }
}

// table in card layout
.tableData-Card {
  border: 0;
  background: #fafafa;
  margin-bottom: 10px;
  border-radius: 8px;

  .card-header {
    font-size: 14px;
    color: $mediumGray;
    background-color: transparent;
    padding: 13px 10px 15px;
    border-bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    p {
      margin-bottom: 3px;
      color: $grey;
      font-size: 12px;
      font-weight: 600;
    }
  }

  .card-body {
    padding: 0 10px;
  }

  .broadcastMsgStats {
    display: flex;
    justify-content: space-between;
    font-size: 14px;

    &:last-child {
      margin-bottom: 0;
      border-bottom: 0;
      padding-bottom: 0;
    }

    p {
      margin-bottom: 3px;
      color: $grey;
      font-weight: 600;
      font-size: 12px;

      &+p {
        font-weight: 400;
        color: $mediumGray;
        font-size: 14px;
      }
    }

    span {
      color: $mediumGray;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      line-height: 20px;
      max-height: 46px;
      -webkit-line-clamp: 2;
      /* number of lines to show */
      -webkit-box-orient: vertical;
      white-space: normal;
      margin-bottom: 15px;
    }
  }
}

.msgStatsMob {
  margin-left: 0;
  margin-right: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: $white;
  border-radius: 8px;

  .customColMassMsg:nth-child(odd) {
    padding-left: 10px;
    padding-right: 5px;
  }

  .customColMassMsg:nth-child(even) {
    padding-right: 10px;
    padding-left: 5px;
  }
}

// settings
.header_Settings {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 18px 0 15px;

  h4 {
    color: $grey;
    margin-bottom: 0;
    font-size: 16px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-weight: 600;
  }

  p {
    margin-bottom: 0;
  }

  label {
    margin-bottom: 0 !important;
  }
}

.checkbox_Settings {
  margin: 13px 0;

  .form-check-label {
    font-size: 15px;
    margin-left: 5px;
  }

  .form-check-input {
    margin-top: 2.5px;
  }
}

.customSwitch {
  width: 36px !important;
  height: 20px !important;

  .switch-slider {
    border: 1px solid #a0a3bd;
    background: #a0a3bd;
  }

  .switch-slider::before {
    background-color: $white;
    border-color: $white;
    height: 14px;
    width: 14px;
  }

  .switch-input:checked+.switch-slider {
    background-color: $theme-color;
    border-color: $theme-color;
  }

  .switch-input:checked+.switch-slider::before {
    border-color: $white;
    transform: translateX(15px);
    background: $white;
  }
}

.vaultVideoPreviewDefaultBackground {
  height: 150px;
  width: 200px;
  max-width: 200px;
  box-shadow: 0 1px 3px 0 rgba($black, 0.1);
  object-fit: cover;
  border-radius: 10px;
  display: block;
  margin-bottom: 0;
  border: 1px solid rgba($black, 0.08);
  background: url("../../img/upload_video_icon.png");
  background-size: 70px 60px;
  background-repeat: no-repeat;
  background-position: center;
}

.header_Settings[aria-expanded="false"] {
  span {
    transition: all 0.3s;
    margin-top: 1px;
  }
}

.header_Settings[aria-expanded="true"] {
  span {
    transition: all 0.3s;
    margin-top: 2px;
    transform: rotate(180deg);
  }
}

.loginSessions {
  margin-top: -6px;
  cursor: pointer;

  li h5 {
    margin-bottom: 5px;
    font-size: 14px;
    color: $grey;
  }

  li {
    border: 0;
    border-bottom: 1px solid rgba($black, 0.1);
    margin-bottom: 0;
    padding: 11px 0 9px;
    border-radius: 0 !important;
  }

  p {
    margin-bottom: 0;
    font-size: 13px;
    color: $lightGray;
  }
}

.loginSessionsCollapse {
  padding: 0 5px 0 0;
  max-height: 300px;
  overflow-y: auto;
  scrollbar-color: #e0e0e0 #f5f5f5;
  scrollbar-width: thin;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba($black, 0.18);
    box-shadow: inset 0 0 6px rgba($black, 0.1);
    border-radius: 0;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 3px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0;
    -webkit-box-shadow: inset 0 0 6px rgba($black, 0.18);
    box-shadow: inset 0 0 6px rgba($black, 0.1);
    background-color: #e0e0e0;
  }

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .modalBtnCancel {
    display: block;
    margin: 20px auto 5px !important;
  }
}

.tooltip-inner {
  max-width: 250px;
  background-color: $white;
  border: 1px solid #ddd;
  color: $grey;
  font-size: 12px;
  text-align: left;
  border-radius: 8px;
  padding: 8px;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.3px;
}

.bs-tooltip-auto[x-placement^="right"] {
  padding-left: 10px;

  .arrow {
    left: 4px;

    &::before {
      border-right-color: #aaa;
    }
  }
}

.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #aaa;
}

.profileCard {
  border: 0;
  border-radius: 0;

  .card-header {
    position: relative;
    padding: 0;
    background-color: $white;
    border-bottom: 0;
    margin-bottom: 20px;
  }

  .coverImgWrap {
    border-radius: 0;
  }
}

.coverImgWrap {
  width: 100%;
  min-height: 250px;
  max-height: 400px;
  position: relative;
  background-color: $inputBorder;
  border-radius: 8px;
  overflow: hidden;
  color: $lightGray;
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;

  .uploadImg {
    width: 51px;
    height: 40px;
    margin-bottom: 10px;
  }

  img,
  video {
    width: 100%;
    height: 100%;
    max-height: 400px;
    object-fit: contain;
  }

  audio {
    width: 90% !important;
    box-shadow: 0 0.125rem 0.25rem rgba($black, 0.075);
    border-radius: 30px;
    max-width: 350px;
    height: 54px !important;
  }

  .uploadVideoAudioWrap {
    min-height: 250px;
    max-height: 400px;
    width: 100%;
    text-align: center;
    z-index: 98;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .customVideoWrapper {
    border-radius: 8px;
    height: unset !important;

    .videoControlsWrap {
      bottom: 6px;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    video {
      border-radius: 8px;
    }
  }
}

.changeImg {
  width: 27px;
  height: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: $theme-color !important;
  border: 1px solid $theme-color;
  background-color: $white;
  border-radius: 50%;
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
  z-index: 100;
}

.dollarWrap {
  background-color: $theme-color;
  border: 0;
  color: $white;
  padding: 0 20px;
  font-weight: 600;
  line-height: 1;
}

.dollarWrapInput {
  padding-bottom: 3px;
}

.vaultMediaWrap {
  padding-left: 1%;
  padding-right: 1%;
  width: 25%;
  position: relative;

  label {
    margin-bottom: 0 !important;
  }
}

.vaultMedia {
  width: 100%;
  height: 110px;
  background-color: $inputBorder;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 18px;
  border-radius: 12px;

  img,
  video {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    object-fit: cover;
  }

  svg {
    fill: $theme-color;
    height: 45px;
    width: 45px;
  }

  video {
    object-fit: contain;
  }
}

.deleteVaultMedia {
  width: 19px;
  height: 19px;
  padding: 0;
  border-radius: 10px;
  position: absolute;
  top: -10px;
  right: 0;
  background-color: $white !important;
  border: 0;

  svg {
    fill: $theme-color;
    width: 19px;
    height: 19px;
  }
}

.coverImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.editProfileImg {
  height: 28px;
  width: 28px;
  border: 0;
  background: $theme-color !important;
  color: $white !important;
  cursor: pointer;
  font-size: 14px;
  border-radius: 50%;
  padding: 0;
  position: absolute;
  bottom: 0;
  right: -3px;
  line-height: 28px !important;
}

.personImgWrap-Search {
  min-width: 76px;
  min-height: 76px;
  width: 76px;
  height: 76px;
  border: 1px solid rgba($black, 0.1);
  border-radius: 50%;
  padding: 2px;
  margin-right: 10px;
  position: relative;
}

.personImg-Search {
  width: 70px;
  min-width: 70px;
  height: 70px;
  border-radius: 50%;
  object-fit: cover;
}

// crop image modal
.customImageCropModal {
  .MuiDialogContent-root {
    padding: 5px 15px 15px;
    width: 500px;
  }

  .ReactCrop__image {
    height: 450px;
    width: 100%;
    object-fit: contain;
  }

  .ReactCrop {
    height: 300px;
    width: 100%;
    display: block;
    margin: 0 auto;
  }

  .MuiDialog-paper {
    margin: 12px;
  }
}

.tierInfoHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background-color: $white;
  padding: 15px 15px 14px;
  background: rgba(230, 230, 230, 0.5);
  margin-bottom: 20px;
  cursor: pointer;

  h5 {
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 0;
    color: $grey;
    font-weight: 600;
  }
}

.tierInfoBody {
  padding: 15px 15px 5px;
  margin-top: -20px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background: #fbfbfb;
  margin-bottom: 20px;
}

.subscriptionTable {
  .btn:not(:last-child) {
    margin-right: 10px;
  }

  .btn {
    padding: 0 5px;
    height: 18px;
    line-height: 18px;
    margin-top: -2px;

    img,
    svg {
      height: 14px;
      width: 14px;
      margin-top: -2px;
      fill: $theme-color;
    }

    .fa {
      color: $theme-color;
      font-size: 15px;
      line-height: 18px;
    }
  }

  thead th,
  tbody td {
    padding-left: 5px;
    padding-right: 5px;
  }
}

// chat list
.msgInfoIcon {
  font-size: 14px;
  color: $theme-color;
  margin-left: 2px;
  cursor: pointer;
}

.initiateMsg {
  padding: 0 6px;
  background: transparent !important;
  border: 0;
  box-shadow: none !important;
  height: 22px;
  line-height: 22px;
  font-size: 18px;
  color: #bbb !important;
  margin-right: 5px;

  img {
    height: 18px;
    margin-top: -2px;
  }
}

.recentWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 4px;
  border-bottom: 1px solid $grey;
  margin-bottom: 4px;

  p {
    font-size: 14px;
    color: $grey;
    font-weight: 500;
    margin-bottom: 0;
  }

  label {
    font-weight: 400 !important;
    margin-bottom: 0 !important;
    margin-right: 8px;
    cursor: pointer;
    font-size: 14px !important;
  }

  .custom-control {
    width: 21px;
  }
}

.massMsgListWrap {
  background: $white;
  border-radius: 10px;
  padding: 1px 10px;
  margin-bottom: 20px;
}

.unreadMsg {
  p {
    color: $theme-color !important;
  }

  span {
    color: $theme-color !important;
  }
}

.selectSubscriber {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .form-check-label {
    display: block;
    width: 100%;
    cursor: pointer;
  }

  .form-check-input {
    position: absolute;
    right: 12px;
    margin-left: 0;
    margin-top: 0;
    top: 25px;
  }
}

.subscriberSelectedWrap {
  display: flex;
  margin-bottom: 12px;
  overflow-x: auto;
  margin-top: -2px;

  scrollbar-color: #e0e0e0 #f5f5f5;
  scrollbar-width: thin;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba($black, 0.18);
    box-shadow: inset 0 0 6px rgba($black, 0.1);
    border-radius: 0;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    height: 3px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0;
    -webkit-box-shadow: inset 0 0 6px rgba($black, 0.18);
    box-shadow: inset 0 0 6px rgba($black, 0.1);
    background-color: #e0e0e0;
  }
}

.subscriberSelected {
  padding: 6px;
  box-shadow: 0 1px 4px 0 rgba($black, 0.1);
  border-radius: 8px;
  background: #fafafa;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2px 7px 6px 1px;

  p,
  span {
    font-size: 12px;
    margin-bottom: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 14px;
    max-height: 14px;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    color: $grey;
  }

  span {
    margin-bottom: 0;
    color: $lightGray;
  }

  .btn {
    padding: 0;
    margin-left: 4px;
    @include ripple;
    min-width: 17px;

    svg {
      width: 15px;
      height: 15px;
      fill: $theme-color;
    }
  }
}

.massMsgFilterWrap {
  background-color: $white;
  border-radius: 15px;
  padding: 15px 0;
  margin-bottom: 20px;

  .btn {
    border: 0 !important;
    font-size: 14px;
    padding: 0 6px;
    background: #f2f2f2;
    outline: 0 !important;
    box-shadow: none;
    border-radius: 30px;
    height: 42px;
    color: $lightGray;
    width: 100%;
    font-weight: 500;

    &:focus,
    &:active {
      color: $lightGray !important;
    }
  }

  .addBtn {
    width: max-content;
    padding: 0 20px;
    margin-left: 20px;
    color: $white;

    &:focus,
    &:active {
      color: $white !important;
    }
  }

  .activeMassMessageFilterBtn {
    background: $theme-color !important;
    color: $white !important;

    &:focus {
      color: $white !important;
    }
  }

  .btn-secondary:not(:disabled):not(.disabled):active {
    background: #f2f2f2;
  }

  .colPaddingWrapDropdown {
    label {
      font-size: 14px !important;
      margin-bottom: 5px !important;
    }

    .custom-control label {
      margin-bottom: 0 !important;
    }
  }
}

.topFan {
  position: absolute;
  right: -3px;
  bottom: -6px;
  font-size: 20px;
  color: $theme-color;
}

.expiredSubscriber {
  position: absolute;
  right: -2px;
  bottom: -4px;
  font-size: 18px;
  color: $theme-color;
  background-color: $white;
  border-radius: 50%;
}

// chat
.conversationDetails {
  overflow: auto;
  height: calc(100vh - 255px);
  scrollbar-color: #e0e0e0 #f5f5f5;
  scrollbar-width: thin;
  padding-right: 8px;
}

.receiverWrap,
.senderWrap {
  margin-top: 5px;
  width: 90%;
  margin-bottom: 12px;
}

.msg {
  // padding: 12px 15px;
  // border-radius: 25px;
  padding: 10px 15px;
  margin-bottom: 3px;
  border-radius: 12px;

  pre {
    color: $mediumGray;
    font-size: 14px;
    word-break: break-all;
  }
}

.bgThemeColor {
  pre {
    color: $white;
  }

  background-color: $theme-color !important;
}

.senderWrap {
  margin-left: 10%;

  .sentTime {
    text-align: right;
  }

  .msg {
    border-bottom-right-radius: 0;
  }
}

.sentTime {
  color: $lightGray;
  font-size: 12px;
}

.receiverWrap {
  .bgOffWhite {
    align-self: flex-start;
  }

  .sentTime {
    margin-left: 40px;
  }

  .msg {
    border-bottom-left-radius: 0;
  }
}

.chatDate {
  font-size: 14px;
  color: $lightGray;
  font-weight: 400;
  margin-bottom: 5px;
  margin-top: 5px;
  text-align: center;
}

.senderImg {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid rgba($black, 0.05);
  margin-right: 5px;
}

.makeFavoriteWrap {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 3px;
}

.makeFavorite {
  padding: 0 6px;
  color: $lightGray !important;
  background: transparent !important;
  border: 0 !important;
  font-size: 16px;
  height: 20px;
  line-height: 20px;
  margin-left: 7px;
}

.mediaPhotoWrap,
.mediaVideoWrap,
.mediaAudioWrap {
  width: 100%;
  max-width: 262px;
  height: 100%;
  max-height: 272px;
  overflow: hidden;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba($black, 0.05);
  margin-bottom: 3px;
  position: relative;
  cursor: pointer;
}

.mediaAudioWrap {
  width: 250px;
  height: 150px;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.mediaVideoWrap video,
.mediaAudioWrap audio {
  width: 100%;
  height: 100%;
}

.mediaPhoto {
  width: 100%;
  max-width: 240px;
  height: 100%;
  max-height: 240px;
  object-fit: cover;
  border-radius: 6px;
  cursor: pointer;
}

.msgPrice {
  position: absolute;
  top: 8px;
  left: 8px;
  z-index: 99;
  border-radius: 25px;
  height: 22px;
  padding: 0 11px;
  background-color: $theme-color;
  color: $white;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
}

.addMessageWrap {
  height: calc(100vh - 96px);
  display: flex;
  flex-direction: column;
}

.receiverInfo-Chat {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $white;
  min-height: 52px;
  border-bottom: 1px solid rgba($black, 0.05);
}

.receiverName-Chat {
  cursor: pointer;

  h4 {
    font-size: 14px;
    margin-bottom: 1px;
    font-weight: 500;
    color: $grey;
  }

  span {
    font-size: 12px;
    font-weight: 400;
    color: $mediumGray;
  }
}

.typeMessage {
  padding-right: 25px;
}

.profileMsgPg {
  min-width: 36px;
  min-height: 36px;
  width: 36px;
  height: 36px;
  border: 1px solid rgba($black, 0.05);
  border-radius: 50%;
  margin-right: 5px;
  object-fit: cover;
}

// emoji
aside.emoji-picker-react {
  width: 100% !important;
  margin-top: -2px;
  border: 0 !important;
  border-radius: 6px !important;
  box-shadow: 0 0.125rem 0.25rem rgba($black, 0.075) !important;
}

// date range
.DateRangePicker {
  width: 100%;
}

.DateRangePickerInput__withBorder {
  border-radius: 8px !important;
  border: 0 !important;
  background-color: $inputBorder !important;
  width: 100%;

  .DateInput {
    width: calc(50% - 12px) !important;
    border-radius: 8px;
  }
}

.DateRangePicker_picker {
  z-index: 99 !important;
}

.DateInput_input {
  display: block;
  width: 100%;
  height: 46px;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  background-clip: padding-box;
  color: $lightGray !important;
  font-family: "Poppins", sans-serif;
  font-size: 14px !important;
  border: 1px solid $inputBorder !important;
  padding: 6px 8px !important;
  border-radius: 8px !important;
  background-color: $inputBorder !important;
  outline: 0 !important;

  &:focus {
    border-color: $theme-color !important;
  }
}

.DateRangePickerInput_arrow_svg {
  width: 20px !important;
  height: 20px !important;
  margin-top: -4px;
  margin-right: 4px;
}

// date-range
.dateRange {
  // width: 246px;
  display: block !important;

  .react-daterange-picker__wrapper {
    padding: 6px 8px;
    height: 46px;
    background-color: $inputBorder;
    border: 1px solid $inputBorder;
    border-radius: 8px;

    .react-daterange-picker__clear-button,
    .react-daterange-picker__calendar-button {
      display: none;
    }

    .react-daterange-picker__inputGroup,
    .react-daterange-picker__inputGroup input {
      color: $grey;
      outline: 0 !important;
      min-width: unset;
      color: $grey;
      font-size: 14px;
    }

    .react-daterange-picker__inputGroup {
      flex: 0;
    }

    .react-daterange-picker__range-divider {
      margin: 5.5px 8px 0 8px;
    }
  }
}

.react-calendar__tile--active {
  background: $theme-color !important;
}

.react-calendar__navigation {
  margin-bottom: 0 !important;

  button {
    color: $grey;
    font-size: 21px;
    min-width: 36px !important;
  }
}

.react-daterange-picker__calendar {
  margin: 3px auto;
  border: 1px solid rgba($black, 0.12);
  width: 270px !important;
  z-index: 99 !important;
}

.react-calendar__navigation__label {
  font-size: 14px !important;
  font-weight: 500;
  margin-top: 4px !important;
}

.react-calendar__month-view__weekdays__weekday {
  font-size: 14px;
  font-weight: 600;
  padding: 4px !important;
}

.react-calendar {
  border: 0 !important;
  width: 270px !important;
  font-family: "Poppins", sans-serif !important;
}

.react-daterange-picker__inputGroup__input:invalid {
  background-color: transparent !important;
}

// profile
.fansImgWrap {
  min-width: 36px;
  min-height: 36px;
  width: 36px;
  height: 36px;
  overflow: hidden;
  border: 1px solid rgba($black, 0.1);
  border-radius: 50%;
  padding: 2px;
  margin-right: 8px;
}

.fansImg {
  width: 30px;
  min-width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
}

.notesFan {
  width: 150px;
  padding: 4px;
}

.fansInfoCard {
  margin-bottom: 18px;
  border: 0;

  .card-header {
    padding: 0;
    padding-bottom: 5px;
    background-color: transparent;
    border-bottom: 0;
  }

  a {
    color: $theme-color;
  }

  .card-body {
    padding: 0;
  }

  li {
    padding: 9px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    color: $mediumGray;
    border: 0;
    border-bottom: 1px solid rgba($black, 0.08);
    margin-bottom: 0;
    border-radius: 0 !important;

    div:nth-child(2) {
      font-weight: 500;
    }
  }

  .card-footer {
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    background-color: transparent;
    border-top: 0;

    .btn {
      color: $grey !important;
      border: 0;
      outline: 0;
      background: transparent !important;
      padding: 0 10px;
      font-size: 15px;
    }
  }

  .fanInfo {
    p {
      margin-top: 0;
      margin-bottom: -2px;
    }
  }

  .notesFan {
    font-size: 12px;
  }
}

.filterWrap-Feed {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;

  p {
    color: $grey;
    font-size: 14px;
    margin-bottom: 0;
    font-weight: 600;
  }

  .btn {
    padding: 0 14px;
    background-color: rgba(var(--primary-color-rgb), 0.2) !important;
    border-radius: 25px;
  }
}

.galleryImg {
  height: 200px;
  width: 100%;
  border-radius: 12px;
  object-fit: cover;
  cursor: pointer;
}

.influencerDetailsWrap {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  padding: 12px 14px;
  position: relative;
}

.img100by100Wrap {
  border: 1px solid rgba($black, 0.05);
  width: 102px;
  height: 102px;
  margin-right: 10px;
  position: relative;
  border-radius: 50%;
  background-color: $white;
}

.img100by100 {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
}

.influencerName {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  margin-top: 3px;
  flex-direction: column;

  h3 {
    font-size: 17px;
    margin-bottom: 0;
  }

  a {
    font-size: 15px !important;
  }

  h4 {
    margin-bottom: 2px !important;
  }

  p {
    font-size: 14px !important;
    margin: 4px 0 0;
    color: $grey !important;
  }

  p i {
    font-size: 13px;
    color: #aaa;
    margin-right: 8px;
    margin-left: -1px;
  }
}

.profileDescWrap {
  padding: 16px 15px !important;
}

.profileDesc {
  font-size: 15px;
  color: $grey;
  margin: 2px 0 8px;
}

// statement
.paymentTime {
  font-size: 14px;
  color: $lightGray;
  margin-top: 18px;
}

.earningsDataWrap {
  background: #fafafa;
  padding: 10px 8px 12px;
  border-radius: 4px;
  margin-bottom: 10px;

  p {
    font-size: 14px;
    color: $mediumGray;
    margin-bottom: 0;
  }

  .earningsData {
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;

    p,
    h5 {
      font-size: 14px;
      color: $theme-color;
      margin-bottom: 6px;
      font-weight: 400;
    }

    h5 {
      color: $grey;
      font-weight: 500;
      font-size: 13.5px;
    }
  }
}

.earningsDataTable,
.earningsDataWrap {
  .btn {
    color: $theme-color;
    padding: 0;
    margin-top: -1px;
  }
}

.balanceStatus {
  background: $theme-color;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 22px 5px;
  margin-bottom: 15px;
  text-align: center;
  height: 100%;

  @media (max-width: 768px) {
    height: auto;
  }

  p {
    font-size: 14px;
    color: $white;
    text-transform: uppercase;
    margin-bottom: 10px;
    letter-spacing: 0.5px;
  }

  h5 {
    font-size: 20px;
    font-weight: 600;
    color: $white;
    margin-bottom: 0;
  }

  img {
    height: 75px;
    margin-bottom: 12px;
  }

  .btn {
    background-color: $white !important;
    padding: 0 20px;
    height: 36px;
    line-height: 36px;
    font-weight: 600;
    font-size: 14px;
    border: 0;
    outline: 0;
    border-radius: 18px;
    color: $theme-color !important;
    box-shadow: none !important;
    width: max-content;
    margin-top: 36px;
  }
}

// alert css
.alert-dismissible .close {
  outline: 0;
  padding: 0.75rem 0.65rem 0.6rem;
  font-size: 28px;
}

.alert {
  font-size: 14px;
  padding: 15px 42px;
  position: relative;
  margin-bottom: 20px;
  margin-top: 20px;
  color: $grey;
  border-radius: 8px;
}

.alert-danger {
  border-color: #ffb8b8;
}

.alertIconDanger {
  position: absolute;
  top: 17px;
  left: 12px;
  color: $error;
  font-size: 18px;
}

.alert-warning {
  background-color: #fff3cd;
  border-color: #ffecb5;
}

.alertIconWarning {
  position: absolute;
  top: 16px;
  left: 12px;
  font-size: 18px;
  color: #ffc107;
}

// image modal
.ReactModal__Overlay.ReactModal__Overlay--after-open {
  background-color: rgba($black, 0.98) !important;
  z-index: 1040 !important;
}

.ReactModal__Content.ReactModal__Content--after-open {
  width: 94%;
  padding: 0 !important;
  border: 0 !important;
  height: 98%;
  top: 50% !important;
  border-radius: 12px !important;
  background: #f7f7f7;
  background: transparent !important;

  .lazy-load-image-background {
    display: block !important;
  }

  .mediaPhoto-Feed {
    object-fit: contain;
    max-height: unset;
  }

  &:has(.storyContainer) {
    border-radius: 0 !important;
  }
}

.closeModalFeed {
  font-size: 16px !important;
  border-radius: 50%;
  padding: 0;
  height: 22px;
  width: 22px;
  line-height: 22px;
  position: absolute;
  top: 12px;
  right: 3px;
  z-index: 999;
  color: #ff2323 !important;
  outline: 0 !important;
  background-color: $white !important;
  border: 0;
  box-shadow: 0px 1px 5px 0px rgba($black, 0.22) !important;
}

// toast
.toast {
  background-color: $white !important;
  border-color: $theme-color !important;
  color: $theme-color !important;
  font-family: "Poppins", sans-serif !important;
  padding: 5px 12px !important;
  font-size: 14px !important;
  border-radius: 8px !important;
  letter-spacing: 0.3px;
  flex-basis: 0 !important;
  margin-bottom: 52px !important;
  margin-right: 10px !important;
}

// subscriber & non-subscriber view
.subscriberViewTxt {
  display: block;
  margin: 8px auto 20px;
  padding: 8px 5px 5px;
  outline: 4px double $theme-color;
  color: $theme-color;
  font-size: 22px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  background: $white;
  max-width: 280px;
  width: 100%;
  text-align: center;
}

.tabsWrap-InfluencerDetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 35px;
  width: 100%;

  h4 {
    color: $grey;
    margin-bottom: 0;
    font-size: 18px;
  }

  .btn {
    padding: 3px 6px;
    background: transparent !important;
    border: 1px solid transparent !important;
    box-shadow: none !important;
    @include ripple;
    margin-top: -2px;
  }

  .subscribeInfBtn {
    background: $theme-color !important;
    font-size: 15px;
    padding: 0 18px;
    color: $white !important;
    border: 0 !important;
    border-radius: 25px;
    @include ripple;
    height: 32px;
    line-height: 34px;
  }
}

.subscribeBtn {
  margin: 5px 0;
  padding: 9px 6px 8px;
  color: $white !important;
  font-size: 14px;
  border-radius: 30px;
  border: 0 !important;
  box-shadow: none !important;
  @include ripple;
  width: 250px;
  text-transform: uppercase;
  background: linear-gradient(45deg,
      rgba(var(--primary-color-rgb), 1) 0%,
      rgba(var(--primary-color-rgb), 0.6) 71%,
      rgba(var(--primary-color-rgb), 0.3) 100%);
}

.subscribeBtnOutline {
  border: 1px solid $theme-color !important;
  background-color: $white !important;
  color: $theme-color !important;
  background: unset !important;
}

.makePaymentBtn {
  margin: 0;
  max-width: 250px;
  height: 40px;
  line-height: 42px;
}

.stripeWrapper {
  margin-top: 20px;
  border-bottom: 1px solid rgba($black, 0.1);

  .CardField-number {
    width: 140px !important;
  }
}

.stripeWrapper * {
  margin-bottom: 6px;
  width: 100% !important;
}

// live streaming
.liveEventStreamingWrap {
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  background-color: $white;
  overflow: hidden;
}

.liveEventStreaming {
  width: calc(100% - 360px);
  height: 100%;
  background: rgba($black, 0.95);
  padding: 0 !important;

  iframe {
    width: 100%;
    height: 100%;
  }
}

.liveStreamCommentToggle {
  position: absolute;
  top: 66px;
  right: 15px;
  padding: 0;
  border: 0 !important;
  z-index: 99;
  background-color: rgba($black, 0.3) !important;
  border-radius: 8px;
  width: 40px;
  height: 34px;
  font-size: 20px;
  color: $white !important;
  transition: all 0.8s;

  i {
    vertical-align: 1px;
  }
}

.liveStreamCommentWrap {
  position: absolute;
  top: 50px;
  right: 0;
  bottom: 0;
  z-index: 99;
  transition: all 0.5s;
  transform: translateY(-150%);
  background-color: rgba($black, 0.3);

  &.show+.liveStreamCommentToggle {
    right: 310px;
    transition: all 0.3s;
  }

  &.collapsing {
    transform: translateY(-150%);
    transition: all 0.5s;
  }

  &.show {
    transform: translateY(0px);
    transition: all 0.8s;
  }
}

.commentSectionWrap-liveStream {
  min-width: 300px;
  max-width: 300px;
  height: 100%;
  padding: 0 8px;
  position: relative;
  // background: $white;
}

.liveStreamBrief {
  padding: 8px 0 5px;
  border-bottom: 1px solid rgba($white, 0.15);
  position: relative;
  margin-bottom: 2px;
}

.creatorName-liveStream {
  margin-bottom: 0;
  font-size: 14px;
  // color: $lightGray;
  color: $white;
  margin-left: 5px;

  span {
    color: $theme-color;
  }
}

.liveStreamName {
  margin-top: 6px;
  font-size: 14px;
  // color: $lightGray;
  color: $white;
  font-weight: 400;
}

.comment-LiveStreamEvent {
  margin: 0;
  max-height: unset;
  padding: 0;
  padding-right: 8px;
  position: relative;
  height: calc(100% - 180px);
  padding-bottom: 10px;
  background-color: transparent;

  .noComments {
    font-size: 14px;
    // color: $lightGray;
    color: $white;
    margin-top: 12px;
    font-weight: 300;
  }
}

.commentArea-liveStream {
  // position: absolute;
  width: 100%;
  // left: 0;
  // bottom: 5px;
  padding: 8px 0;
  // background: $white;
  background-color: transparent;
}

.liveStreamingBtnOptions {
  display: flex;
  padding-left: 40px;
  margin-top: 4px;

  .btn {
    padding: 0.2rem 0.4rem;
    text-decoration: none !important;

    img {
      width: 20px;
    }
  }

  .btn+.btn {
    margin-left: 7px;
  }
}

.emojiImgAsComment-liveStreaming {
  width: 24px;
}

.stickerLiveStreaming {
  height: 70px;
  border-radius: 8px;
  margin-bottom: 5px;
}

// shout out
.shoutOutWrap {
  background: $white;
  border-radius: 12px;
  padding: 12px 12px 10px;
  margin-top: 20px;
}

.shoutOutPriceWrap {
  display: flex;
  align-items: center;
  margin-top: 25px;

  p {
    font-size: 14px;
    margin-bottom: 0;
    color: $mediumGray;

    span {
      font-weight: 500;
    }
  }

  .input-group {
    width: 130px;
    margin-left: 15px;
  }
}

.samplesTxt {
  margin-top: 25px;
  margin-bottom: 8px;
  font-size: 16px;
}

.shoutOutMediaWrapper {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 10px;

  .addBtn {
    display: block;
    margin: 15px auto 0;
    cursor: pointer;
  }
}

.uploadMedia-ShoutOut {
  width: 210px;
  max-width: 210px;
  height: 160px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $white;
  padding: 5px;
  border: 1px dashed rgba($black, 0.15);
  border-radius: 8px;
  margin: 15px auto 0 !important;
}

.shoutOutMediaWrap {
  width: 100%;
  height: 100%;
  overflow: hidden;

  video {
    outline: 0 !important;
    width: 100%;
    border-radius: 16px;
    margin-top: 15px;
  }

  .audioWrapper {
    height: 266px;
  }

  .uploadMedia-ShoutOut {
    width: 100%;
    max-width: unset;
  }

  .customVideoWrapper {
    height: unset !important;

    .playPauseVideo {
      font-size: 28px;
      height: 30px;
      width: 30px;

      img {
        height: 24px;
        width: 24px;
      }
    }

    .videoControls {
      .btn {
        height: 23px;
        width: 23px;
        font-size: 20px;

        img {
          height: 20px !important;
          width: 20px !important;
        }
      }

      #videoSetting img,
      #videoMaximize img {
        height: 18px !important;
        width: 18px !important;
      }
    }

    .videoTimestamp {
      font-size: 10px;
      line-height: 1.2;
    }

    &:hover {
      .videoControlsWrap {
        bottom: 6px;
      }
    }

    .videoControlsWrap {
      bottom: 0;
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
      padding: 9px 5px;
    }

    .videoVolume {
      &:hover .videoSeekBar {
        display: none;
      }
    }
  }
}

.shoutOutMediaContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

// shout out
.shoutOutAccordion {
  margin-bottom: 5px;
  border: 0;

  .card-header {
    padding: 10px 0;
    background-color: $white;
    cursor: pointer;
    border-bottom: 0;
  }

  .fanName {
    color: $grey;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 2px;
    margin-top: -1px;
  }

  .shoutOutType,
  .shoutOutDate,
  .shoutOutStatus {
    font-size: 12px;
    color: $lightGray;
    margin-bottom: 0;
  }

  .shoutOutStatus {
    margin-right: 10px;
    text-transform: uppercase;
    color: $theme-color;
    font-weight: 500;
  }

  .headerIcon {
    font-size: 16px;
    margin-left: 15px;
    color: $grey;
    margin-top: 1px;
  }

  .message {
    color: $lightGray;
    font-size: 14px;
    margin-bottom: 0;
    margin-top: 4px;
    font-weight: 400;
  }

  .card-body {
    padding: 5px 0;
    border-bottom: 1px solid rgba($black, 0.05);
    margin-bottom: 5px;

    p {
      color: $grey;
      font-size: 14px;
      margin-bottom: 0;

      margin-right: 6px;
    }

    h5 {
      color: $grey;
      font-size: 14px;
      margin-bottom: 0;
    }

    h4 {
      color: $grey;
      font-size: 16px;
      font-weight: 500;
      margin-top: 8px;
      margin-bottom: 10px;
    }

    .commentSection {
      padding: 5px 5px 0 5px;
      border: 1px solid rgba($black, 0.08);
      border-radius: 10px !important;
      margin-top: 25px;

      p {
        color: $lightGray;
        margin-bottom: 10px;
        margin-top: 5px;
        margin-left: 6px;
      }

      .userName-Comment {
        margin: 0;
      }
    }
  }
}

.shoutOutOccasionWrap {
  p {
    font-size: 12px !important;
    color: $lightGray !important;
    text-transform: uppercase;
  }

  h5 {
    font-weight: 500;
    margin-top: 5px;
  }
}

.commentShoutout {
  position: relative;
  display: flex;
  margin-bottom: 10px;
  margin-top: 15px;

  .sendMsg {
    bottom: 6px;
  }
}

.videoCallRoomModal {
  height: 100vh !important;
}

// video call room
.videoCallRoom {
  width: 100%;
  position: relative;
  height: 100vh !important;

  img {
    max-width: 55px;
  }
}

.videoRoomActionBtn {
  width: 100%;
  position: absolute;
  bottom: 20px;
  left: 0;
}

.btnVideoRoomWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.btnVideoRoom {
  box-shadow: none !important;
  outline: 0 !important;
  border: 0 !important;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: $theme-color !important;
  font-size: 24px;
  color: $white !important;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 12px;

  img {
    height: 24px;
  }
}

.btnEndCall {
  background-color: #ea1c43 !important;

  img {
    height: 30px;
  }
}

.cameraOffTxt {
  position: absolute;
  z-index: 9;
  color: $white;
  left: 0;
  width: 100%;
  top: 35%;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
}

.joinBtn {
  width: 180px;
  background: $theme-color !important;
  border: 0 !important;
  color: $white !important;
  text-align: center;
  margin: 15px auto 5px;
  height: 42px;
  line-height: 1.3;
  padding: 0 6px;
  font-size: 16px;
  border-radius: 25px;
  display: block;
  outline: 0 !important;
  box-shadow: 0 0.125rem 0.25rem rgba($black, 0.075) !important;
  @include ripple;
}

.play-bar .rounded-box {
  .logo {
    display: none;
  }
}

.postPreviewVideo {
  top: 0;
  left: 0;
  position: absolute;
  z-index: 9;
  width: 100%;
  height: 100%;
}

.subscribeBtnsWrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}

// .pagination {
//   .page-link {
//     padding: 0 0.75rem;
//     height: 36px;
//     line-height: 36px;
//     color: $theme-color;

//     &:hover {
//       color: $theme-color;
//     }
//   }

//   .page-item.active .page-link {
//     background-color: $theme-color;
//     color: $white;
//     border-color: $theme-color;
//   }
// }

.mediaLibraryFilesWrap {
  position: relative;
  cursor: pointer;
  margin: 4px 0;
  overflow: hidden;
  height: 152px;

  .fa {
    color: $theme-color;
    font-size: 25px;
    float: right;
    background-color: $white;
    border-radius: 50%;
    line-height: 19px;
    width: 19px;
  }

  .selectVideoCheckbox {
    position: absolute;
    right: 5px;
    top: 8px;
    z-index: 9;
  }

  .mediaFileSelected {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
    background-color: rgba($black, 0.35);
    border-radius: 16px;
    padding: 10px 12px;
  }

  .selectVideo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 28px;
    color: $theme-color;

    .fa {
      background-color: transparent;
    }
  }
}

.mediaLibraryFiles {
  width: 100%;
  object-fit: contain;
  border-radius: 16px;
  height: 100%;
  background: #fafafa;
  border: 1px solid rgba($black, 0.04);

  audio {
    width: 95% !important;
    height: 54px !important;
  }
}

.tabContentMediaLibrary {
  border: 0;
  border-top: 1px solid #e6e6e6;

  .tab-pane {
    padding: 8px 0;
  }
}

.mediaFileUploadWrap {
  border: 1px dashed rgba($black, 0.15);
  border-radius: 10px;
  height: 152px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  label.themeBtn {
    line-height: 42px !important;
  }
}

.mediaUploadPreviewWrap {
  position: relative;
  margin: 5px;
}

.mediaUploadPreview {
  width: 60px;
  min-width: 60px;
  max-width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 4px;
}

.filesSelectedTxt {
  display: flex;
  color: $lightGray;
  font-size: 14px;
}

.short-text {
  font-size: 13px;
  color: $mediumGray;
  word-break: break-word;
}

.play-bar .rounded-box {
  .logo {
    display: none;
  }
}

.lazy-load-image-loaded {
  height: 100%;
  width: 100%;
}

.mediaLibraryPagination {
  display: flex;
  justify-content: flex-end;

  .pagination {
    margin-bottom: 0;
    margin-top: 0;

    .page-link {
      border: 0;
    }

    .page-item.disabled .page-link {
      // color: $mediumGray;
      display: none;
    }

    .page-link {
      color: $theme-color;
      box-shadow: none !important;
      font-weight: 400;
      margin-left: 2px;
      margin-right: 2px;
      height: 28px;
      width: 28px;
      line-height: 28px;
      padding: 0 1px;
      text-align: center;
      font-size: 12px;
      border-radius: 8px;
    }

    .page-item.active .page-link {
      background-color: $theme-color;
      color: $white;
      border-radius: 50%;
    }
  }
}

.earningsInDetailWrap {
  margin-top: -5px;
  margin-bottom: 0;

  li {
    border: 0;
    padding: 8px 0;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    color: $mediumGray;

    div {
      font-weight: 500;
      color: $grey;
    }
  }
}

.mediaPhotoWrap,
.mediaPhotoWrap-Feed {
  .groupMediaWrap:nth-child(1) {
    .shadowOverlayImg {
      border-radius: 0;
      border-top-left-radius: 12px;
    }
  }

  .groupMediaWrap:nth-child(2) {
    border-left: 1px solid rgba($black, 0.05);

    .shadowOverlayImg {
      border-radius: 0;
      border-top-right-radius: 12px;
    }
  }

  .groupMediaWrap:nth-child(3) {
    border-top: 1px solid rgba($black, 0.05);

    .shadowOverlayImg {
      border-radius: 0;
      border-bottom-left-radius: 12px;
    }
  }

  .groupMediaWrap:nth-child(4) {
    border-left: 1px solid rgba($black, 0.05);
    border-top: 1px solid rgba($black, 0.05);

    .shadowOverlayImg {
      border-radius: 0;
      border-bottom-right-radius: 12px;
    }
  }

  .groupMediaWrap {
    width: 130px;
    height: 135px;
    cursor: pointer;
    overflow: hidden;
    flex-grow: 1;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .videoPlayBtn {
      z-index: 99;
      font-size: 45px;
      line-height: 45px;
    }

    audio {
      width: 90%;
    }
  }

  .lazy-load-image-background {
    width: 100%;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.mediaPhotoWrap-Feed {
  .groupMediaWrap {
    width: 50%;
    height: 300px;
  }
}

.seeMoreMedia {
  background-color: rgba($black, 0.65);
  position: absolute;
  bottom: 0;
  right: 0;
  height: 135px;
  width: 130px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  border-bottom-right-radius: 4px;
  border-left: 1px solid $white;
  border-top: 1px solid $white;

  .btn {
    color: $white !important;
    text-decoration: none;
    font-size: 18px;
    font-weight: 600;
  }
}

.multipleMediaModal {

  .carousel,
  .carousel-inner,
  .carousel-item {
    height: 100%;
  }

  .lazy-load-image-background {
    display: block !important;
    width: 94%;
    margin: 0 auto;
  }

  .carousel-indicators {
    margin-bottom: 0;
    width: fit-content;
    margin: unset;
    left: 50%;
    transform: translate(-50%, 0);
    background: $grey;
    border-radius: 6px;
    padding: 4px;
    max-width: 100%;
    bottom: 5px;

    li {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      border: 0;
      background: #dcdcdc;
    }

    .active {
      background: linear-gradient(rgba(var(--primary-color-rgb), 1),
          rgba(var(--primary-color-rgb), 0.3));
    }
  }

  .carousel-control-prev {
    left: -2%;
  }

  .carousel-control-next {
    right: -2%;
  }

  .carousel-control-prev,
  .carousel-control-next {
    opacity: 1;
    width: 42px;
    top: 45%;
    height: 10%;
  }

  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    height: 24px;
    width: 24px;
    background-color: rgba(255, 255, 255, 0.4);
    box-shadow: 0px 0px 5px 0 rgba($black, 0.3);
    border-radius: 50%;
    line-height: 25px;
  }

  .carousel-control-next-icon {
    padding-left: 2px;
  }

  .carousel-control-prev-icon {
    padding-right: 2px;
  }

  .carousel-control-next-icon:before,
  .carousel-control-prev-icon:before {
    font-size: 14px;
    color: #ccc;
  }

  .videoPreview {
    height: 100%;
    width: 100%;
    object-fit: contain;
    position: absolute;
    top: 0;
    left: 0;
  }

  .playVideo {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 50px;
    width: 50px;
    line-height: 50px;
    color: $white !important;
    font-size: 50px;
    z-index: 99;
    color: $white;
    padding: 0;

    img {
      height: 50px;
    }
  }

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
  }
}

.customSidebarWrap {
  .customSidebar {
    background-color: $white;
    padding: 10px 15px;
    position: fixed;
    z-index: 999;
    height: calc(100vh - 50px);
    overflow: auto;
    scrollbar-color: #e0e0e0 #f5f5f5;
    scrollbar-width: thin;

    ul {
      margin-bottom: 130px;

      a,
      li {
        border: 0;
        padding: 11px 0;
        display: flex;
        font-size: 14px;
        color: $grey;
        font-weight: 500;
        text-decoration: none;
        background-color: transparent;
        position: relative;
        cursor: pointer;

        span {
          width: 25px;
          margin-right: 15px;
          height: 20px;
        }

        img {
          height: 21px;
          margin-top: -4px;
        }

        i {
          font-size: 20px;
          color: #555;
          margin-top: 0;
        }

        svg {
          height: 25px;
          width: 25px;
        }
      }

      a.active {
        color: $theme-color;
      }

      li.active {
        color: $theme-color;
      }

      .customTopBorder:before {
        content: "";
        height: 3px;
        width: 200px;
        position: absolute;
        background-color: #e5e5e5;
        border-radius: 100px;
        top: -17px;
        left: 0;
      }
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba($black, 0.18);
      box-shadow: inset 0 0 6px rgba($black, 0.1);
      border-radius: 0;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
      width: 5px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 0;
      -webkit-box-shadow: inset 0 0 6px rgba($black, 0.18);
      box-shadow: inset 0 0 6px rgba($black, 0.1);
      background-color: #e0e0e0;
    }
  }

  .showSideMenu .customSidebar {
    width: 250px;
    transition: all 0.3s;
  }

  .hideSideMenu .customSidebar {
    margin-left: -250px;
    transition: all 0.3s;
  }
}

// .showSideMenu + .main:before {
//   position: absolute;
//   top: 0;
//   left: 0;
//   z-index: 998;
//   width: 100%;
//   height: 100vh;
//   content: "";
//   background: rgba($black, 0.7);
//   animation: opacity 0.3s;
// }

.showSideMenu .sidebarOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 998;
  width: 100%;
  height: 100vh;
  content: "";
  background: rgba($black, 0.7);
  animation: opacity 0.3s;
}

.bottomMenu {
  padding: 0 20px;
  height: 50px;
  width: 100%;
  background-color: $white;
  border-bottom: 2px solid #f0f0f0;
  top: 0;

  img {
    height: 22px;
  }

  ul {
    width: 100%;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
  }

  .nav-item {
    cursor: pointer;

    .activeIcon {
      display: none;
    }

    .inactiveIcon {
      display: initial;
    }

    svg {
      height: 24px;
      width: 24px;
    }
  }

  .nav-item.active {
    .activeIcon {
      display: initial;
      fill: $theme-color;
    }

    .inactiveIcon {
      display: none;
    }

    svg {
      fill: $theme-color;
    }
  }
}

.img120by120 {
  background-color: $white;
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 50%;
  border: 2px solid $theme-color;
  // background-image: linear-gradient(white, white),
  //   linear-gradient(to right, $theme-color, #fe8a2c 71%, #fea054);
  // background-origin: border-box;
  // background-clip: content-box, border-box;
  position: relative;
  margin-bottom: 20px;

  .imagePreview {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      font-size: 40px;
      color: #999;
    }
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
}

.vaultFolderDetailsWrap {
  background: $white;
  border-radius: 12px;
  padding: 15px;
}

.vaultFolderDetails {
  padding-top: 18px;

  label {
    font-size: 14px !important;
    margin-bottom: 5px !important;
    font-weight: 500 !important;
  }

  .custom-control label {
    margin-bottom: 0 !important;
  }
}

.deleteButton {
  padding: 0 4px;
  font-size: 14px;
  color: $error;
}

.vaultMediaThumbWrap {
  height: 200px;
  width: 100%;
  border-radius: 16px;
  position: relative;
  overflow: hidden;
  margin-bottom: 14px;
  cursor: pointer;

  .selectFile {
    position: absolute;
    display: flex;
    justify-content: space-between;
    top: 0;
    left: 0;
    padding: 8px 8px 0;
    width: 100%;
    z-index: 99;

    .customDropdown-Feed .btn {
      background-color: rgba($white, 0.12) !important;
      color: $white;
      border-radius: 15px;
      height: 20px;
      padding: 0 9px;
    }

    .custom-checkbox {

      .custom-control-label::after,
      .custom-control-input:checked~.custom-control-label::after {
        cursor: pointer;
      }

      .custom-control-label::before {
        border-color: $white !important;
        background-color: transparent;
      }

      .custom-control-input:checked~.custom-control-label::before {
        background-color: $theme-color;
        border-color: $theme-color !important;
      }
    }
  }

  video,
  .vaultMediaThumb {
    width: 100%;
    height: 200px;
    border-radius: 16px;
    object-fit: cover;
  }

  .vaultMediaInfo {
    position: absolute;
    padding: 0 10px 8px;
    width: 100%;
    left: 0;
    bottom: 0;
    color: $white;

    p {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 1px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    span {
      font-size: 12px;
      font-weight: 400;
    }
  }
}

.liveEventActionBtns {
  position: absolute;
  left: 8px;
  bottom: 15px;

  .btn {
    padding: 0 6px;
    font-size: 14px;
    font-weight: 600;
    border: 0;
    background: transparent !important;
    color: $white !important;
    @include ripple;

    i {
      margin-right: 6px;
      font-size: 18px;
      vertical-align: -1px;
    }
  }

  .btn .fa-heart,
  .btn .fa-comment {
    color: $theme-color;
  }
}

.msgWrapWeb {
  display: flex;
  width: 100%;
  height: calc(100vh - 95px);
  background-color: $white;
  border: 1px solid #e5e5e5;
  border-top: 0;

  .chatListWeb {
    border-right: 1px solid #e5e5e5;
    padding-left: 8px;
    padding-right: 8px;
    position: relative;

    .pgTitle {
      border-bottom: 1px solid #e5e5e5;
      margin: 15px 0;
      padding: 0 15px 15px;

      h2 {
        font-size: 20px;
      }
    }

    .chatList {
      height: calc(100vh - 244px);
      overflow-x: hidden;
      overflow-y: auto;
      scrollbar-color: #e0e0e0 #f5f5f5;
      scrollbar-width: thin;

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba($black, 0.18);
        box-shadow: inset 0 0 6px rgba($black, 0.1);
        border-radius: 0;
        background-color: #f5f5f5;
      }

      &::-webkit-scrollbar {
        width: 3px;
        background-color: #f5f5f5;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 0;
        -webkit-box-shadow: inset 0 0 6px rgba($black, 0.18);
        box-shadow: inset 0 0 6px rgba($black, 0.1);
        background-color: #e0e0e0;
      }
    }

    label {
      margin-bottom: 5px !important;
      font-weight: 500 !important;
      font-size: 14px !important;
    }
  }

  .conversationWeb {
    flex: 1;
    padding-left: 8px;

    .customBackBtn {
      display: none;
    }
  }

  .selectChatUserWeb {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .fa {
      font-size: 100px;
      color: #e0e0e0;
      margin-bottom: 15px;
    }

    p {
      font-size: 16px;
      font-weight: 500;
      color: #ccc;
    }
  }
}

.chatList {
  margin-bottom: 15px;

  .list-group-item {
    display: flex;
    justify-content: space-between;

    border: 0;
    border-radius: 0 !important;
    padding: 8px 0;
    background-color: $white;
    text-decoration: none !important;
  }
}

.userChatList {
  font-size: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;

  h5 {
    color: $grey !important;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 17px;
    max-height: 17px;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    margin-bottom: 1px;
  }

  .userImg {
    width: 40px;
    height: 40px;
    min-width: 40px;
  }

  p,
  span {
    font-size: 12px;
    margin-bottom: 0;
    color: $lightGray;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 20px;
    max-height: 20px;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  span {
    color: $mediumGray;
  }
}

.mediaCountCarousel {
  height: 21px;
  line-height: 21px;
  text-align: center;
  font-weight: 500;
  font-size: 12px;
  color: $white;
  background: $grey;
  position: absolute;
  top: 12px;
  left: 4px;
  border-radius: 10px;
  padding: 0 10px;
}

.form-error {
  display: block !important;
  color: $error;
  font-size: 13px;
  padding-left: 5px;
  margin: 3px 0 0;
}

.greyCheck {
  color: $lightGray;
  font-size: 13px;
}

.seenTick {
  color: $theme-color;
  font-size: 13px;
}

.loaderWrap {
  width: 300px;
  height: 250px;
  text-align: center;

  p {
    font-size: 16px;
    margin-top: 10px;
    color: $grey;
    text-transform: lowercase;

    &::first-letter {
      text-transform: uppercase;
    }
  }
}

.tier-icon {
  height: 22px;
  margin-right: 8px;
}

.audioWrapper {
  height: 260px;
  width: 100%;

  img {
    height: 35px !important;
    width: initial;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
  }

  audio {
    width: 90% !important;
    display: block;
    margin: 0 auto;
    height: 44px !important;
    max-width: 500px;
  }

  .audioRipple {
    width: 180px;
    height: 180px;
    border: 5px solid rgba($black, 0.02);
    display: block;
    margin: 30px auto;
    border-radius: 50%;
    position: relative;
  }

  .audioRipple:before {
    content: "";
    position: absolute;
    bottom: 10px;
    top: 10px;
    left: 10px;
    right: 10px;
    border: 5px solid rgba($black, 0.04);
    border-radius: inherit;
  }

  .audioRipple:after {
    content: "";
    position: absolute;
    bottom: 40px;
    top: 40px;
    left: 40px;
    right: 40px;
    border: 5px solid rgba($black, 0.12);
    border-radius: inherit;
  }

  .audioRippleStart {
    animation: pulse 1.25s infinite linear;
  }

  .audioRippleStart:before {
    animation: pulse 1.25s infinite linear;
  }

  .audioRippleStart:after {
    animation: pulse 1.25s infinite linear;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(var(--primary-color-rgb), 0.2),
      0 0 0 0.25em rgba(var(--primary-color-rgb), 0.2),
      0 0 0 0.75em rgba(var(--primary-color-rgb), 0.2),
      0 0 0 1.25em rgba(var(--primary-color-rgb), 0.2);
  }

  100% {
    box-shadow: 0 0 0 0.25em rgba(var(--primary-color-rgb), 0.2),
      0 0 0 0.75em rgba(var(--primary-color-rgb), 0.2),
      0 0 0 1.25em rgba(var(--primary-color-rgb), 0.2),
      0 0 0 2em rgba(var(--primary-color-rgb), 0);
  }
}

.createBtn {
  display: none;
}

.searchCategory {
  position: relative;

  .fa-search {
    font-size: 14px;
    color: $lightGray;
    position: absolute;
    top: 15px;
    left: 10px;
  }

  input {
    padding-left: 34px;
    padding-right: 34px;
  }

  .btn {
    background-color: transparent !important;
    border: 0;
    font-size: 16px;
    color: $error;
    padding: 0;
    position: absolute;
    top: 9px;
    right: 6px;
    height: 28px;
    width: 26px;
    line-height: 28px;
  }
}

.categoryList {
  position: absolute;
  top: 50px;
  width: 100%;
  z-index: 999;
  border-color: #eee;
  scrollbar-color: #e0e0e0 #f5f5f5;
  scrollbar-width: thin;
  appearance: none;
  -webkit-appearance: none;
  max-height: 400px;
  overflow: auto;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba($black, 0.18);
    box-shadow: inset 0 0 6px rgba($black, 0.1);
    border-radius: 0;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 3px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0;
    -webkit-box-shadow: inset 0 0 6px rgba($black, 0.18);
    box-shadow: inset 0 0 6px rgba($black, 0.1);
    background-color: #e0e0e0;
  }

  .list-group-item {
    border-color: #e5e5e5;
    color: $mediumGray;
    font-size: 14px;
    line-height: 14px;
    padding: 13px 15px 12px;

    &:hover {
      border-color: transparent;
    }

    &:first-child {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    &:last-child {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }

  .list-group-item.active {
    background-color: $theme-color;
    color: $white;
    border-color: $theme-color;
    font-weight: 600;
  }
}

.input-group>.customRadius {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

// sticker page
.manageStickerWrap {
  padding: 20px 15px 12px;
  border-radius: 12px;
  background-color: $white;

  .customPaddingSticker {
    &:nth-child(4n + 1) {
      padding-right: 8px;
    }

    &:nth-child(4n + 2) {
      padding-left: 8px;
      padding-right: 8px;
    }

    &:nth-child(4n + 3) {
      padding-right: 8px;
      padding-left: 8px;
    }

    &:nth-child(4n + 0) {
      padding-left: 8px;
    }
  }
}

.stickerWrap {
  margin-top: 7.5px;
  margin-bottom: 7.5px;
  border: 1px solid #f0f0f0 !important;
  padding: 10px !important;
  border-radius: 16px;
  position: relative;

  p {
    text-align: center;
    margin-top: 22px;
    color: $mediumGray;
  }

  .stickerImg {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
    display: block;
    margin: 15px auto;
  }

  .customDropdown {
    position: absolute;
    top: 5px;
    right: 10px;
  }

  .stickerInfo {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    font-size: 14px;

    span {
      color: $mediumGray;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      /* number of lines to show */
      line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
}

.shadowOverlayImg,
.vaultFolderOverlay {
  width: 100%;
  height: 100%;
  background: rgba($black, 0.25);
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 12px;
}

.vaultFolderOverlay {
  background-color: transparent;
  z-index: 99;
}

.tab-Notification {
  align-items: flex-end;

  .nav-link,
  .nav-link:hover {
    text-align: center;
  }
}

.fanInfo {
  display: flex;

  .lazy-load-image-loaded {
    width: unset;
    height: unset;
  }

  p {
    font-size: 12px;
    margin: -3px 0 -3px 1px;
  }
}

.expiredSubscriberProfile {
  display: block;
  color: #dc3545;
  font-size: 12px;
  text-transform: uppercase;
  margin-left: 1px;
}

.actionBtnSubscriber {
  .btn {
    background-color: transparent !important;
    border: 0 !important;
    padding: 0 4px;
    color: $mediumGray;
    font-size: 15px;
    margin-right: 4px;
  }
}

.newMemberPermission {
  margin-top: 10px;
  margin-bottom: 10px;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  label {
    font-weight: 400;
    cursor: pointer;
  }
}

.toggleBtnStatement {
  display: initial;
  margin-left: 5px;
}

.customFilterEarnings {
  padding: 15px;

  .checkboxEarnings {
    width: 16.5%;

    label {
      font-weight: 400;
      cursor: pointer;
    }
  }
}

.earningStatsSummary {
  border: 1px solid #c9c9c9;
  padding: 10px 20px 8px;
  border-radius: 20px;
  margin-top: 20px;

  li {
    display: flex;
    justify-content: space-between;
    padding: 16px 1px 15px;
    border: 0;
    margin-bottom: 0;
    border-bottom: 1px solid #d6d6d6;
    border-radius: 0 !important;
    color: $lightGray;
    font-size: 14px;

    &::before {
      font-family: "fontAwesome";
      content: "\f111";
      color: $theme-color;
      font-size: 6px;
      margin-right: 7px;
      margin-top: 6px;
    }

    div {
      color: $grey;
      font-weight: 500;
      margin-left: auto;
    }

    &:last-child {
      border-bottom: 0;

      div {
        font-weight: 600;
      }

      &::before {
        display: none;
      }
    }
  }
}

.myProfileCard {
  margin-top: 0;
  border-radius: 0;
  max-width: 801px;

  .card-header {
    position: relative;
    flex-direction: column;
    padding: 0 !important;

    h4 {
      margin-left: 2px;
    }
  }

  .card-body {
    padding: 0 10px !important;
    margin-top: 7px;
  }

  .profileDesc {
    font-size: 13px;
    margin-top: 10px;
    color: $mediumGray;
    margin-bottom: 0;

    .readMore {
      font-size: 13px;
    }
  }

  .coverImgWrap {
    height: 280px;
    min-height: 280px;
    width: 100%;
    overflow: hidden;
    border-bottom: 1px solid rgba($black, 0.03);
    border-radius: 0;
  }

  .coverImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .influencerDetailsWrap {
    display: flex;
    width: 100%;
    padding: 0 10px;
    align-items: unset;
    flex-direction: row !important;

    .img100by100 {
      margin-top: -40px;
      border: 2px solid $theme-color;
      // background-image: linear-gradient(white, white),
      //   linear-gradient(to right, $theme-color, #fe8a2c 71%, #fea054);
      // background-origin: border-box;
      // background-clip: content-box, border-box;
      margin-bottom: 5px;
    }
  }

  .profileInfoWrap {
    display: flex;
    margin-left: 15px;
    width: 100%;
    height: fit-content;

    div {
      text-align: center;
      margin-top: 10px;
      width: 100%;

      &:not(:first-child) {
        border-left: 1px solid #e5e5e5;
      }
    }

    h5 {
      font-size: 18px;
      color: $grey;
      margin-bottom: 0;
      font-weight: 600;
    }

    p {
      color: $lightGray !important;
    }
  }

  .editProfile {
    background-color: rgba(var(--primary-color-rgb), 0.2) !important;
    color: $theme-color !important;
    box-shadow: none !important;
    max-width: 150px;
    margin-right: 15px;
  }
}

.customFilterSubscriberWrap {
  margin-top: 8px;
  margin-bottom: 15px;
}

.customTabsMyProfile {
  margin-top: 20px;
  padding: 0 10px;

  .customTabs {
    width: 100%;
    justify-content: center;

    .nav-link,
    .nav-link:hover {
      text-align: center;

      .tabActiveIcon {
        display: none;
      }

      .tabInactiveIcon {
        display: initial;
      }

      .tabActiveIcon,
      .tabInactiveIcon {
        height: 24px;
      }
    }

    .nav-link.active,
    .nav-link.active:hover {
      text-align: center;

      .tabActiveIcon {
        display: initial;
      }

      .tabInactiveIcon {
        display: none;
      }
    }

    .nav-item {
      margin-bottom: -1px;
    }
  }
}

.chat-oscilloscope {
  border-radius: 8px;
  height: 50px;
  border: 1px solid rgba($black, 0.08);
  width: 75%;
  display: block;
  margin: 0 auto 15px;
  padding-left: 15px;
  padding-right: 15px;
}

.chat-oscilloscope-hidden {
  width: 0% !important;
}

.audioRecorded {
  width: 75% !important;
  display: block;
  margin: 216px auto 0;
  height: 44px !important;
}

.customExpirationDate {
  color: $grey;
  font-size: 14px;
  font-weight: 500;
}

// msg preview
.cancelVideoRecord {
  position: absolute;
  left: 50%;
  top: 70%;
  transform: translate(-50%, -50%);
  background-color: $white !important;
  border-radius: 30px;
  z-index: 999;
  padding: 0 25px;
  height: 38px;
  color: $mediumGray !important;
  border: 0 !important;
  font-weight: 500;
  line-height: 38px;
  font-size: 14px;
}

.conversationDetailsBroadcast {
  height: calc(100vh - 263px);
}

.expandIcon {
  position: absolute;
  z-index: 99;
  left: 15px;
  top: 15px;
  height: 20px;
  cursor: pointer;
}

// upload progress css
#mediaQueueViewerWrapper {
  position: fixed;
  right: 25px;
  bottom: 62px;
  z-index: 999;
  height: 50px;
  background-color: $theme-color;
  padding: 0 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;

  #uploadProgressHeader {
    color: $white;
    font-size: 14px;
    font-family: "Poppins", sans-serif;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
}

select::-ms-expand {
  display: none;
}

select {
  background: url("data:image/svg+xml;utf8,<svg fill='dark gray' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 9px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.btn-link:hover {
  text-decoration: none;
  color: $theme-color;
}

.registerBtn {
  color: #999 !important;
  font-size: 14px;
  padding: 6px 0;
  background: transparent !important;
  border: 0 !important;
  outline: 0 !important;
  box-shadow: none !important;
  display: block;
  margin: 6px auto 0;
  @include ripple;

  span {
    color: $theme-color;
    text-decoration: underline;
  }
}

.eyeIcon,
.spinnerLogin {
  position: absolute;
  top: 12px;
  right: 12px;
  color: $grey;
  font-size: 16px;

  .fa {
    cursor: pointer;
  }
}

.forgotPassword {
  float: right;
  padding: 0;
  font-size: 14px;
  background: transparent !important;
  border: 0 !important;
  outline: 0 !important;
  color: $theme-color !important;
  box-shadow: none !important;
  font-weight: 500;
}

.addPWATooltipWrap {
  width: 91%;
  border-radius: 6px;
  box-shadow: 0 0 0.5rem rgba($black, 0.1);
  position: fixed;
  bottom: 28px;
  left: 4.5%;
}

.addPWATooltip {
  padding: 12px 10px;
  border-radius: 5px;
  background-color: $white;
  position: relative;
  font-size: 14px;
  color: $mediumGray;
  letter-spacing: 0.3px;
  font-family: "Poppins", sans-serif;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  i {
    font-size: 32px;
    color: #bbb;
    margin-right: 8px;
  }

  .btn {
    position: absolute;
    top: -6px;
    right: -6px;
    padding: 0;
    height: 20px;
    width: 20px;
    line-height: 20px;
    border-radius: 50%;
    background-color: crimson !important;
    border: 0 !important;
    text-align: center;

    i {
      font-size: 14px !important;
      color: $white !important;
      margin-right: 0;
    }
  }

  img {
    height: 20px;
    vertical-align: -4px;
    margin-left: 5px;
  }

  &:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    margin-left: -0.46em;
    bottom: -2em;
    left: 46%;
    box-sizing: border-box;
    border: 1em solid black;
    border-color: transparent transparent $white $white;
    transform-origin: 0 0;
    transform: rotate(-45deg);
    box-shadow: -2px 2px 3px 0 rgba($black, 0.12);
  }
}

.signUpWrap {
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;
}

// .phoneWithCode {
//   .phInput {
//     flex: 1;

//     .form-control {
//       border-bottom-left-radius: 0;
//       border-top-left-radius: 0;
//     }
//   }

//   .phoneSignUp {
//     max-width: 160px;
//     border-top-right-radius: 0 !important;
//     border-bottom-right-radius: 0 !important;
//     padding-right: 18px;
//   }
// }

.profileWrapper {
  height: 160px;
  width: 160px;
  position: relative;
  margin: -80px 0 0 10px;

  .uploadBtn {
    width: 36px;
    height: 36px;
    text-align: center;
    line-height: 37px !important;
    position: absolute;
    right: 10px;
    bottom: 8px;
    padding: 0;
    font-size: 16px !important;
    color: $white !important;
  }
}

.imagePreview {
  height: 160px;
  width: 160px;
  border: 4px solid $theme-color;
  background-color: $white;
  border-radius: 50%;
  position: relative;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  i {
    font-size: 50px;
    color: $theme-color;
    margin-top: 5px;
  }
}

.withtabs .tab-content {
  border: none;
}

.profile_content {
  text-align: center;

  h5 {
    font-weight: 600;
    color: $grey;
  }

  p {
    color: $lightGray;
    padding: 0 15px;
  }
}

.uploadBtn {
  width: 24px;
  height: 24px;
  font-size: 12px;
  position: absolute;
  background-color: $theme-color;
  border-radius: 50%;
  bottom: 5px;
  right: 5px;
  margin: 0;
  color: $white !important;
  line-height: 24px !important;
  text-align: center;
  cursor: pointer;
}

.imagePreview {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.skipTxt {
  color: $lightGray !important;
  font-weight: 500;
  text-align: center;
  display: block;
  margin: 0 auto;
}

// congratulations
.congContent {
  display: flex;
  justify-content: center;
  font-size: 22px;

  h4 {
    color: $grey;
    margin-bottom: 10px;
    margin-right: 8px;
    margin-top: 4px;
  }
}

.price_group {
  .input-group-text {
    background-color: $theme-color;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    font-size: 18px;
  }
}

.category {
  input#ProductName {
    padding-left: 0;
  }
}

//set subscription price page
.price_box {
  padding: 0;
  border: none;
}

.subscrip_content {
  color: $lightGray;
  font-size: 15px;
  margin-top: 30px;
  text-align: center;

  h4 {
    color: $grey;
    margin-top: 28px;
    font-weight: 600;
    font-size: 18px;
  }
}

.subscriptionPrice {
  margin-left: auto;
  margin-right: auto;
  flex-wrap: nowrap;
  // width: 190px;
  justify-content: center;

  .input-group-text,
  input {
    background-color: transparent !important;
    border: none;
    border-radius: 0;
    font-size: 44px;
    color: $grey !important;
    padding: 0;
    font-weight: 700;
    margin-top: -1px;
  }

  >.form-control {
    height: auto;
    margin-left: 5px;
    flex: unset;

    &:focus {
      border: 0 !important;
    }
  }

  input::placeholder {
    color: $grey;
  }
}

.customMarginFaq {
  margin-left: 0;
  margin-right: 0;
}

.searchBarFaqWrap,
.searchChatListWrap {
  margin-top: 18px;
  margin-bottom: 18px;
  position: relative;

  input {
    height: 44px;
    box-shadow: none !important;
    border: 0;
    letter-spacing: 0.3px;
    padding: 0 34px;
    background: #f2f2f2 !important;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 400;
    color: $lightGray;
  }

  i {
    color: $lightGray;
    font-size: 14px;
  }

  .searchIconFaq,
  .clearSearch,
  .searchIcon {
    position: absolute;
    top: 14px;
    left: 12px;
  }

  .clearSearch {
    right: 12px;
    left: unset;
    top: 13px;
    font-size: 16px;
    cursor: pointer;
  }
}

.searchChatListWrap {
  margin-top: 10px;
}

.faqCategoryWrap {
  height: 160px;
  border-radius: 16px;
  background: #fafafa;
  width: 100%;
  border: 1px solid rgba($black, 0.02);
  box-shadow: 0 6px 10px 0 rgba($black, 0.06);
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 15px;
  cursor: pointer;

  svg {
    height: 45px;
    width: 45px;
    fill: $theme-color;
  }

  p {
    font-size: 14px;
    color: $grey;
    font-weight: 500;
    margin-bottom: 0;
  }
}

.notificationOptions {
  margin: 14px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .custom-control {
    height: 15px;
    min-height: 1.11rem;
    cursor: pointer;
  }

  .custom-control-label::before,
  .custom-control-label::after {
    top: 0.05rem;
    left: -1rem;
  }

  label {
    margin-bottom: 0;
    cursor: pointer;
    font-weight: 400;
  }
}

.custom-switch {

  .custom-control-label::after,
  .custom-control-input:checked~.custom-control-label::after {
    top: 4px;
  }

  .custom-control-label {
    cursor: pointer;
  }
}

// welcome modal
.welcomeModel {
  .modal-body {
    padding: 20px 15px;
  }

  .modal-content {
    border: 0;
    box-shadow: none;
    overflow: auto;
    border-radius: 20px;
  }
}

.welcomeModalTxt {
  margin-top: 12px;
  text-align: center;

  h4 {
    margin-bottom: 2px;
    font-size: 25px;
    color: $grey;
    font-weight: 700;
  }

  p {
    margin-top: 20px;
    margin-bottom: 25px;
    color: $mediumGray;
    line-height: 1.5;
    font-size: 16px;
  }
}

.customSidebar {
  .imagePreview {
    border-width: 2px;

    i {
      color: #818181;
      font-size: 40px;
      margin-top: 0;
    }
  }
}

// referral modal
.inviteUrl {
  margin-bottom: 22px;

  input {
    flex: 1;
  }

  .copyClipboardBtn {
    padding: 0 25px;
    display: block;
    margin: 20px auto;
    height: 42px;
    line-height: 42px;
    border: 0 !important;
    border-radius: 24px;
    background-color: $theme-color !important;
    color: $white !important;
    font-size: 14px;
    font-weight: 600;

    i {
      font-size: 20px;
      vertical-align: -2px;
      margin-right: 3px;
    }
  }
}

// setting page
.custom-control-label::before {
  border-color: $mediumGray;
}

.custom-control-label::before,
.custom-control-label::after {
  top: 2px;
}

.custom-control-input:checked~.custom-control-label::before {
  border-color: $theme-color;
  background-color: $theme-color;
  color: $theme-color;
}

.custom-control-input {
  &:checked {

    &~.custom-control-label::before,
    &~.custom-control-label::after {
      top: 2px;
    }
  }
}

.custom-control-label {
  &:before {
    top: 2px;
  }
}

.custom-control-input:focus:not(:checked)~.custom-control-label::before,
.custom-control-input:focus~.custom-control-label::before {
  box-shadow: none !important;
  border-color: rgba($black, 0.5);
}

.custom-control-input:focus:checked~.custom-control-label::before {
  border-color: $theme-color;
}

.custom-control-input:checked+label {
  color: $theme-color;
}

.custom-control-input:disabled~.custom-control-label {
  color: #999;
}

.custom-control-input:disabled~.custom-control-label::before {
  background-color: #e4e7ea;
  border: #b1b8bf solid 1px;
}

.custom-control-label {
  font-size: 14px;
  color: $grey;
}

.custom-control {

  input,
  label {
    cursor: pointer;
  }
}

// product list page
.filterOption,
.customizeFilter {
  background-color: $white;
  margin: 10px 0;
  border-radius: 20px;
  padding: 20px 20px 30px;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 25px;
  align-items: flex-end;
  box-shadow: 0 0 10px 0 rgba($black, 0.01);

  .form-group {
    flex: 1;
    margin-bottom: 0;
    margin-right: 15px;
  }

  .input-group {
    width: auto;
  }

  .search_box {
    width: 200px;
  }

  .reset_box {
    width: 120px;

    button {
      height: 42px;
      padding: 0 20px;
    }
  }
}

span.input-group-text {
  border: none;
  // background-color: $inputBorder;
}

.product_item {
  border-color: #f1f1f1;
  border-radius: 0.75rem;
  overflow: hidden;
  margin-bottom: 30px;

  .card-body {
    padding: 14px;
  }

  .price {
    font-weight: 600;
  }

  .category {
    color: $lightGray;
  }

  .date {
    color: $lightGray;
  }

  .card-title {
    font-weight: 500;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    -webkit-box-orient: vertical;
  }
}

.Card_image_wrap {
  height: 210px;
  overflow: hidden;
  background-color: $white;
  border-bottom: 1px solid rgba($black, 0.05);

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .custom-switch {
    position: absolute;
    top: 0;
    left: 10px;
    top: 10px;
    padding: 0;

    input {
      height: auto !important;
    }

    label {
      cursor: pointer;

      &:before,
      &:after {
        left: 0;
      }

      &::after {
        top: 2px;
        left: 2px;
      }

      &:before {
        top: 0;
      }
    }
  }
}

.filterIcon {
  margin: 0 15px;
  cursor: pointer;
  display: none;
}

.Customize_products {
  h5.card-title {
    margin-bottom: 8px;
  }

  span.price {
    font-weight: 500;
  }

  img.filterIcon {
    margin: 0;
  }
}

.logo_preview {
  width: 90px;
  height: 90px;
  background: $white;
  overflow: hidden;
  border: 1px solid #f1ecec;
  border-radius: 100px;
  position: relative;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    object-fit: cover;
  }
}

.custom-range::-webkit-slider-thumb {
  background-color: $theme-color;

  &:active,
  &:focus {
    background-color: $theme-color;
    box-shadow: 0 0 0 1px #e4e5e6,
      0 0 0 0.2rem rgba(var(--primary-color-rgb), 0.5);
  }
}

.product_customize {
  .imageUploader {
    margin: 10px 0;
  }
}

.custom-range::-webkit-slider-runnable-track {
  height: 18px;
}

.custom-range::-webkit-slider-thumb {
  width: 24px;
  height: 24px;
  margin-top: -3px !important;
}

.rangePrice {
  .price {
    font-size: 16px;
    font-weight: 500;
  }

  .min,
  .max {
    font-size: 16px;
    font-weight: 600;

    span {
      display: block;
      font-size: 12px;
      font-weight: 500;
      color: $lightGray;
    }
  }
}

.uploadLogo {
  width: 90px;

  .closeIcon {
    position: absolute;
    right: 6px;
    top: 6px;
    background: $white;
    width: 18px;
    border-radius: 50px;
    height: 18px;
    font-size: 12px;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    color: #ff2323 !important;
    box-shadow: 0px 1px 5px 0px rgba(155, 148, 148, 0.2) !important;
  }
}

.orderlist {
  .pgTitle {
    .orderID {
      font-size: 14px;
      color: $grey;
      max-width: inherit;
      width: 161px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
    }
  }

  .order_item {
    border-color: #f1f1f1;
    border-radius: 0.75rem;
    overflow: hidden;
    margin-bottom: 30px;

    .card-header {
      background-color: transparent;
      border-bottom-style: dashed;
      border-width: 1px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px;

      .date {
        color: $lightGray;
        font-size: 13px;
        margin-top: 6px;
      }

      i {
        margin-left: 10px;
        color: $theme-color;
        font-size: 22px;
        cursor: pointer;
        transform: scale(1);
        transition: 0.2s ease-in-out;

        &.popupIcon {
          transform: scale(1.5);
        }
      }
    }

    .card-body {
      padding: 15px;

      h4 {
        margin-bottom: 0px;
        font-size: 16px;
        color: $grey;

        span {
          display: block;
          font-size: 14px;
          color: $lightGray;
          font-weight: 400;
          margin-top: 8px;
        }
      }
    }

    .products_name {
      margin: 20px 0 0 !important;

      span {
        margin-right: 5px;
        font-size: 14px;
        color: $mediumGray;

        &.more {
          color: $theme-color;
        }
      }
    }
  }

  .orderStatus {
    .price {
      font-size: 16px;
      font-weight: 500;
    }

    .order {
      padding: 4px 16px;
      border-radius: 50px;
      font-size: 14px;

      &.placed {
        background-color: #5bd19f33;
        color: #31b27c;
      }

      &.pending {
        background-color: #fdb92733;
        color: #fdb927;
      }

      &.Cancelled {
        background: #ff424133;
        color: #ff4241 !important;
      }
    }
  }

  .order_wrapper {
    max-width: 100%;
  }

  .orderId {
    display: -webkit-box;
  }
}

.order_card_wrapper {
  border: none;
  margin-bottom: 0px;
  overflow: hidden;

  .marginBottom_30 {
    margin-bottom: 30px;
  }

  >.card-header {
    background-color: $white;
    border-bottom-color: #efefef;
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    font-size: 16px;
    align-items: center;
    padding: 0 0 10px;
  }

  >.card-body {
    padding: 0;
    padding-top: 30px;

    .product_variants {
      border-color: #f1f1f1;
      border-radius: 0.75rem;
      margin-bottom: 0;
      height: 100%;
      overflow: hidden;

      .card-header {
        background-color: $white;
        border-bottom-color: #c8ced3;
        border-bottom-style: dashed;
        font-weight: 500;
        font-size: 14px;
      }

      .card-body {
        ul {
          padding: 0;
          margin: 0;

          li {
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 0;
            padding-top: 14px;
            display: flex;
            justify-content: space-between;
            color: $grey;

            &:first-child {
              padding-top: 0;
            }

            div {
              &:first-child {
                max-width: 40%;
                width: 100;
                flex: auto;
              }

              &:last-child {
                flex: 1;
                text-align: left;
                font-weight: 500;
              }

              .variants {
                border-right: 1px solid #ddd;
                margin-right: 0px;
                padding: 0 8px;
                display: inline;

                &:nth-child(2) {
                  padding-left: 0px !important;
                }

                &:last-child {
                  border: none;
                  padding-right: 0;
                }
              }
            }

            span {
              margin-right: 8px;
            }
          }
        }
      }
    }
  }

  .print_icon {
    color: #777;
    font-size: 22px;
    padding: 0;
  }

  &.order_buyer {
    >.card-body {
      .product_variants .card-body {
        ul li div:first-child {
          max-width: 22%;
        }
      }
    }
  }

  &.order-details {
    >.card-body {

      .product_variants,
      .card-body {
        ul li {
          div:first-child {
            max-width: 24%;
          }

          button {
            margin: 0;
            height: auto;
            line-height: 1;
            padding: 10px 18px;
            font-size: 14px;
            letter-spacing: 0;
            max-width: inherit;
            display: inline-block;
            width: auto;
            margin-right: 10px;

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}

.orderProcess {
  padding: 0;

  li {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
    padding-right: 0;
    position: relative;
    z-index: 1;

    .circle {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: $grey;
    }

    span {
      font-size: 15px;
      font-weight: 500;
      margin-bottom: 0px;

      &.status {
        margin-top: 10px;
      }

      &.date {
        color: $lightGray;
        font-size: 13px;
        margin-top: 4px;
      }
    }

    &::before {
      content: "";
      position: absolute;
      left: 17px;
      top: 16px;
      width: 100%;
      height: 3px;
      background-color: gray;
      z-index: -1;
    }

    &::after {
      content: "";
      position: absolute;
      left: -100%;
      top: 16px;
      width: 100%;
      height: 3px;
      background-color: #5bd19f;
      z-index: -1;
      opacity: 0;
      transition: 0.5s ease-in-out;
    }

    &:last-child {

      &::before,
      &::after {
        display: none;
      }
    }

    &.success {
      .circle {
        background-color: #5bd19f;
      }

      &::after {
        left: 17px;
        opacity: 1;
      }
    }

    &.cancel {
      .circle {
        background-color: #ff4241;
      }

      &::before {
        left: 17px;
        opacity: 1;
        background-color: #ff4241;
      }
    }
  }
}

.modal_choose_product {
  .modal-body {
    padding: 20px;
  }

  .Product_Box {
    padding: 40px 20px;
    margin-top: 20px;
    cursor: pointer;
    border: 1px solid #efefef;
    border-radius: 6px;
    font-size: 15px;
    text-transform: capitalize;

    &.active {
      border-color: $theme_color;
      color: $theme_color;
    }
  }
}

.input-group-prepend {
  .input-group-text {
    border-top-left-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
  }
}

.addProduct {
  textarea.form-control {
    height: 100px;
  }

  .tags {
    background-color: $inputBorder;
    width: 100%;
    padding: 4px !important;
    border-radius: 8px !important;

    .WAMuiChipInput-chipContainer-4 {
      margin-bottom: 0px;
      min-height: 40px;
      display: flex;
      align-items: center;

      &::before {
        display: none !important;
      }

      .MuiInputBase-input {
        height: 100%;
        padding: 0 !important;
      }
    }

    .WAMuiChipInput-underline-14 {
      &::after {
        display: none !important;
      }
    }

    .WAMuiChipInput-chip-16 {
      margin-bottom: 0;
      margin-top: 6px;
      margin-bottom: 6px;
    }
  }

  .MuiChip-root {
    border: 1px solid $theme-color;
    font-family: "Poppins", sans-serif !important;
    background-color: $white;
    height: 26px;

    &:hover {
      background-color: rgba(var(--primary-color-rgb), 0.2) !important;
      border-color: rgba(var(--primary-color-rgb), 0.2) !important;
    }

    .MuiChip-deleteIcon {
      color: $theme-color;
    }

    &:hover .MuiChip-deleteIcon {
      color: $black;
    }
  }

  .themeBtn {
    height: auto;
    max-width: inherit;
    width: auto;
    padding: 10px 40px;
    letter-spacing: 0;
    line-height: inherit;
    margin: 0;
  }

  .cancelBtn {
    margin: 0 !important;
    height: auto;
    margin-right: 10px !important;
  }

  .custom-control-label {
    font-size: 14px;
  }
}

.featured_image {
  height: 350px;
  overflow: hidden;
  position: relative;
  border: 6px solid $white;
  box-shadow: 0 0 10px 0 rgba($black, 0.01);
  border-radius: 4px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 4px;
  }
}

.imageUploader {
  cursor: pointer;
  padding: 8px 18px !important;
  background-color: $theme-color;
  color: $white;
  border-radius: 50px;
  margin-top: 30px;

  &:hover {
    background-color: rgba(var(--primary-color-rgb), 0.9);
  }
}

.product_gallery {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  .gallery_items {
    width: 20%;
    height: 100px;
    overflow: hidden;
    border: 1px solid #eee;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    position: relative;
    z-index: 1;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 4px;
    }

    i {
      font-size: 30px;
      color: $black;
      z-index: 2;
    }

    i.closeIcon {
      position: absolute;
      top: 5px;
      right: 5px;
      width: 18px;
      height: 18px;
      background-color: $white;
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      color: #ff2323 !important;
    }
  }
}

.iconCollapse {
  transform: rotate(90deg);
  transition: all 0.3s;
  margin-top: 2px;
}

.tierComponentHeadPlusBg {
  background: rgba(24, 160, 251, 0.12);
}

.tierComponentHeadPremiumBg {
  background: rgba(253, 216, 60, 0.12);
}

.tooltip.show {
  opacity: 1;
}

.billCycleWrap {
  &:nth-child(1) {
    padding-right: 5px;
  }

  &:nth-child(2),
  &:nth-child(3) {
    padding-right: 5px;
    padding-left: 5px;
  }

  &:nth-child(4) {
    padding-left: 5px;
  }

  .dollarWrap {
    padding-left: 12px;
    padding-right: 12px;
  }
}

.shoutOutMediaWrapCol {
  margin-bottom: 20px;

  &:nth-child(1) {
    padding-left: 0;
    padding-right: 6px;
  }

  &:nth-child(2) {
    padding-left: 6px;
    padding-right: 6px;
  }

  &:nth-child(3) {
    padding-left: 6px;
    padding-right: 0;
  }

  .audioInShoutSetting {
    width: 100%;
  }
}

.replaceBtn {
  color: $lightGray !important;
  border: 1px solid $lightGray !important;
  border-radius: 30px;
  background: $white !important;
  height: 38px;
  padding: 0 18px !important;
  line-height: 36px !important;
  display: block;
  max-width: max-content;
  margin: 10px auto 0 !important;
}

.customPaddingShoutOut {
  &:nth-child(1) {
    padding-right: 8px;
    padding-left: 0;
  }

  &:nth-child(2) {
    padding-left: 8px;
    padding-right: 8px;
  }

  &:nth-child(3) {
    padding-right: 0;
    padding-left: 8px;
  }
}

.settingsTab {

  svg,
  i {
    display: none;
  }
}

// registration
.birthday_filed select {
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}

.securityIcon {
  position: absolute;
  top: 12px;
  right: 10px;
  z-index: 999;
}

.photoUploadWrap {
  width: 100%;
  margin-top: 8px;
  margin-bottom: 25px;

  .photoUpload {
    height: 220px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    overflow: hidden;

    i {
      font-size: 30px;
      color: $lightGray;
    }
  }

  img {
    height: 220px;
    width: 100%;
    object-fit: cover;
  }

  .contentarea {
    height: 220px;
    width: 100%;

    .camera {
      position: relative;
      height: 220px;
      width: 100%;
    }

    video {
      width: 100%;
      height: 220px;
      object-fit: cover;
    }
  }

  #startbutton {
    height: 36px;
    padding: 0 5px;
    border-radius: 25px;
    background-color: $theme-color !important;
    color: $white !important;
    position: absolute;
    width: 50%;
    left: 25%;
    bottom: 10px;
    border: 0;
  }
}

.btn-snapchat {
  background: #fffc00;

  &:hover {
    background: #eeeb00;
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.4) !important;
  }

  i {
    color: $white;
    background-color: rgba($black, 0.1);
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    background-color: #eeeb00;
    border-color: #eeeb00;
  }
}

.show>.btn-snapchat.dropdown-toggle {
  background-color: #eeeb00;
  border-color: #eeeb00;
}

.btn-youtube {
  color: $white;
  background-color: #ff1635;
  border-color: #ff1635;
}

.uploadBtnStripe {
  border: 1px solid $theme-color !important;
  color: $theme-color !important;
  border-radius: 30px;
  background-color: $white !important;
  height: 36px;
  padding: 0 15px;
  line-height: 34px;
  cursor: pointer;
  display: inline-block;
  margin-top: 0;
  margin-bottom: 0 !important;
}

.memberPermissionBadge {
  border-radius: 3px;
  padding: 0 5px;
  height: 17px;
  line-height: 17px;
  font-size: 12px;
  background-color: $theme-color;
  color: $white;
  margin-left: 7px;
  font-weight: 400;
}

.reloadMedia {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $white;
  padding: 5px;
  border: 1px dashed rgba($black, 0.15);
  border-radius: 12px;

  span {
    cursor: pointer;
  }
}

.otherPgsProfileID {
  color: $theme-color;
  padding: 0;
  font-size: 12px;
  cursor: default !important;
  text-decoration: none !important;
}

.chartWrap {
  border: 1px solid #c9c9c9;
  border-radius: 20px;
  margin: 15px 0 50px;
  padding: 12px 10px 0 4px;
}

.donutChart {
  .apexcharts-legend-series {
    margin-bottom: 4px !important;
    margin-top: 0 !important;
  }

  .apexcharts-legend-marker {
    border-radius: 2px !important;
    margin-right: 8px !important;
  }

  .apexcharts-legend-text {
    font-size: 14px !important;
    font-family: "Poppins", sans-serif !important;
    color: $mediumGray !important;
    vertical-align: 1px !important;
  }
}

.topFansWrap {
  border: 1px solid #c9c9c9;
  border-radius: 20px;
  padding: 20px 15px 12px;

  li {
    border: 0;
    padding: 7px 0;

    .sumSpent {
      font-size: 14px;
      font-weight: 600;
      color: $theme-color;
      margin-left: 5px;
    }
  }
}

.fanImg {
  width: 38px;
  border-radius: 50%;
  height: 38px;
  min-width: 38px;
  border: 1px solid rgba($black, 0.08);
}

.customTabsStatement {

  svg,
  i {
    display: none;
  }
}

.vaultInfo .readMoreText {
  color: $white !important;
}

.sendReminder {
  border: 0;
  border-radius: 25px;
  background-color: $theme-color !important;
  color: $white !important;
  font-size: 14px;
  font-weight: 500;
  height: 34px;
  margin-top: -3px;
  line-height: 34px;
  padding: 0 6px;
  min-width: 135px;
}

.policyBtn {
  width: 100%;
  padding: 0;
  text-decoration: none !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $grey !important;
  font-weight: 500;
  font-size: 14px;

  span {
    width: 24px !important;
    display: inline-block;
    text-align: left;
  }

  i {
    font-size: 14px !important;
    transition: all 0.3s;
  }

  .rotateIcon {
    transform: rotate(90deg);
    transition: all 0.3s;
  }
}

.hideCarouselIndicator {
  .carousel-indicators {
    display: none;
  }
}

.processingContent {
  height: 300px;
  border: 1px solid rgba($black, 0.05);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $mediumGray;

  .btn {
    background: $theme-color !important;
    border: 0;
    box-shadow: none !important;
    color: $white !important;
    font-weight: 500;
    height: 40px;
    width: 120px;
    padding: 0 10px;
    border-radius: 20px;
  }
}

.viewVaultFolder {
  position: absolute;
  z-index: 99;
  bottom: 0;
  width: 100%;
  left: 0;
  padding: 10px 10px 15px;
  text-align: center;
}

.validation-error {
  width: 100%;
  margin-top: 3px;
  color: $error;
  font-size: 13px;
}

.no-feed {
  width: 100%;
  width: -moz-available;
  /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available;
  /* Mozilla-based browsers will ignore this. */
  width: stretch;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.iframeTrainingPg {
  width: 100%;
  height: calc(100vh - 56px);
  border: 0;
}

.goalDataWrap {
  margin-top: 12px;
  margin-bottom: 2px;
  font-size: 14px;
  color: $mediumGray;

  p {
    margin-bottom: 5px;
  }

  .goalStatus {
    margin-top: 7px;
    font-weight: 500;
    display: flex;
  }

  .progress {
    height: 18px;
    border-radius: 18px;
    border: 1px solid #e0e0e0;
    background: $white;
  }

  .progress-bar {
    background-color: $theme-color;
  }

  .btn {
    background-color: $white !important;
    border: 1px solid $error !important;
    border-radius: 20px;
    height: 28px;
    padding: 0 12px;
    color: $error !important;
    font-size: 14px;
    margin-left: auto;
    line-height: 26px;
    font-weight: 500;
  }
}

.sortReferralWrap {
  display: flex;
  justify-content: space-between;
  color: $mediumGray;
  align-items: center;

  .form-control {
    width: 130px;
    margin-left: 8px;
  }
}

.profileCarousel {
  border: 0;
  width: 100%;
  border-radius: 0;

  .carousel,
  .carousel-inner,
  .carousel-item {
    border-radius: 0;
    height: unset;
  }

  .carousel {
    overflow: hidden;
    position: relative;
  }

  .carousel-inner {
    width: 100%;
    position: relative;
  }

  .carousel .carousel-item {
    height: 100%;
    overflow: hidden;

    .imgCropWrap {
      position: absolute;
      width: 100%;

      padding-top: 33.3333333%;
      height: 0;
    }

    img,
    video {
      border-radius: 0;
      height: 100%;
      width: 100%;
    }

    video {
      object-fit: contain;
      height: 100%;
      width: 100%;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;

      &.widthAuto {
        // if width > height
        width: auto;
        height: 100%;
      }

      &.heightAuto {
        // if height > width or height = width
        width: 100%;
        height: auto;
      }
    }
  }
}

.selectMethod {
  width: 50%;
  padding-right: 3px;
  margin-top: 3px;

  &:nth-child(2) {
    padding-left: 3px;
    padding-right: 0;
  }

  input[type="radio"] {
    visibility: hidden;
    height: 0;
    width: 0;
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $mediumGray;
    padding: 15px 2px 12px;
    border-radius: 12px;
    border: 1px solid #eaeaea;
    font-weight: 500;
    height: 120px;
    cursor: pointer;
    width: 100%;

    span {
      margin-bottom: 15px;
      font-size: 14px;
    }

    i {
      font-size: 30px;
      margin-right: 10px;
      color: #b5b5b5;
    }

    p {
      font-size: 12px;
      color: $lightGray;
      margin-bottom: 4px;
      font-weight: 400;
      line-height: 1.3;
      text-align: center;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  input[type="radio"]:checked+div {
    background-color: $theme-color;
    color: $white;
    border-color: $theme-color;

    i,
    p {
      color: $white;
    }

    p {
      font-weight: 500;
    }
  }
}

.editProfileBanner {
  display: flex;
  margin-top: -10px;

  .btn {
    height: 28px;
    font-size: 12px;
    font-weight: 500;
    border-radius: 20px;
    background-color: $theme-color !important;
    padding: 0 10px;
    line-height: 26px;
    margin: 0;
    margin-left: 6px;
    color: $white !important;
    border-color: $theme-color !important;
  }

  .editProfileImg {
    position: unset;
    padding: 0;
    margin-left: 0;
    line-height: 28px;
  }

  .deleteCover {
    background-color: $white !important;
    border-color: $error !important;
    color: $error !important;
  }
}

.transferToWrap {
  display: flex;
  justify-content: space-between;
  color: $grey;
  font-size: 14px;
  margin-top: 18px;

  >div {
    width: 60%;
    text-align: right;
  }

  label {
    margin-bottom: 0;
    font-size: 14px;
  }

  p {
    font-size: 12px;
    color: $lightGray;
    margin-bottom: 4px;
  }

  .accInfo {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    span {
      width: 5px;
      height: 5px;
      display: inline-block;
      background-color: $mediumGray;
      border-radius: 50%;
      margin-right: 4px;

      &:last-child {
        margin-right: 8px;
      }
    }

    p {
      font-size: 12px;
      color: $mediumGray;
      margin-bottom: 0;
    }

    .cardBrand {
      color: $grey;
      text-transform: uppercase;
      font-weight: 600;
      margin-bottom: 0;
      font-size: 12px;
      margin-left: 25px;
    }
  }

  .addCardInfo {
    color: $theme-color;
    font-size: 12px;
    padding: 0;
    height: 30px;
  }
}

.voiceVideoMsg {
  padding: 15px;
  border-radius: 12px;
  margin-bottom: 3px;
  color: $white;
  background-color: $theme-color;
  font-size: 14px;

  div {
    font-weight: 600;
    font-style: italic;
    margin-top: 3px;
  }

  &+.themeBtn {
    line-height: 43px;
    margin-top: 3px;
    margin-bottom: 3px;
    max-width: 150px;
    font-size: 14px;
  }
}

.setNFTPriceWrap {
  display: flex;
  align-self: flex-start;
  width: 50%;
}

.paymentWrap {
  box-shadow: none;

  .card-body {
    padding: 0;
  }

  .cardBrand {
    color: #393939;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 0;
  }
}

.debitCardInfo {
  display: flex;
  margin-top: 20px;
  align-items: center;
  margin-bottom: 30px;

  .greenBg {
    background: rgba(91, 209, 159, 0.2);
    border-radius: 8px;
    height: 45px;
    width: 45px;
    margin-right: 15px;
    font-size: 26px;
    text-align: center;
    line-height: 46px;
    color: #5bd19f;
  }

  h5 {
    margin-bottom: 8px;
    font-size: 14px;
    color: $mediumGray;

    div {
      display: inline-block;
    }

    span {
      width: 5px;
      height: 5px;
      display: inline-block;
      background-color: $mediumGray;
      border-radius: 50%;
      margin-right: 5px;
      margin-bottom: 3px;
    }
  }

  p {
    font-size: 12px;
    color: $mediumGray;
    margin-bottom: 0;
  }

  .cardBrand {
    color: #393939;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 0;
    font-size: 12px;
  }
}

.openInAppWrap {
  display: none;
  width: 100%;
  left: 0;
  bottom: 0;
  border-radius: 35px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  .addPWATooltip {
    border-radius: 35px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    .btn {
      right: 11px;
      top: -8px;
    }

    .openInApp {
      position: unset;
      width: 240px;
      height: 40px;
      line-height: 40px;
      background-color: $theme-color !important;
      border: 0;
      border-radius: 20px;
      color: $white !important;
      display: block;
      margin: 22px auto;
      font-weight: 600;
    }

    &::after {
      display: none;
    }
  }
}

.callInfoWrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  z-index: 999;

  div {
    height: 28px;
    font-size: 14px;
    border-radius: 6px;
    padding-left: 7px;
    padding-right: 7px;
    line-height: 28px;
    background: $theme-color;
    color: $white;
    font-weight: 500;
    box-shadow: 0 0.125rem 0.25rem rgba($black, 0.075) !important;
  }
}

.turnedOffWrap {
  position: absolute;
  top: 50px;
  left: 10px;
  z-index: 9;
}

.turnedOff {
  font-size: 12px;
  color: $white;
  height: 30px;
  font-size: 12px;
  border-radius: 6px;
  padding-left: 7px;
  padding-right: 7px;
  line-height: 30px;
  background: #f8d7da;
  color: #721c24;
  margin-bottom: 10px;
}

.creatorRank {
  padding: 14px 15px 12px;
  box-shadow: none;
  border-radius: 10px;
  background-color: rgba(var(--primary-color-rgb), 0.1);
  border: 1px solid rgba(var(--primary-color-rgb), 0.14) !important;
  margin-top: 20px;
  width: 100% !important;

  h4 {
    font-size: 18px;
    color: $grey;
    font-weight: 600;
  }

  p {
    margin-bottom: 0;
    font-size: 15px;
  }
}

.tagNamePost {
  color: $theme-color !important;
  font-weight: 400;

  &:hover {
    text-decoration: none;
  }
}

.textRemaining {
  color: $lightGray;
  position: absolute;
  bottom: -14.5px;
  right: 2px;
  font-size: 13px;
}

// create post @mention css
.rc-mentions {
  white-space: pre-wrap;
  position: relative;

  textarea {
    width: 100%;
    color: $lightGray;
    padding: 6px 10px;
    font-size: 14px;
    border: 1px solid $inputBorder;
    background-color: $inputBorder;
    border-radius: 8px;
    letter-spacing: 0.3px;
    outline: none;

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba($black, 0.18);
      box-shadow: inset 0 0 6px rgba($black, 0.1);
      border-radius: 0;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
      width: 3px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 0;
      -webkit-box-shadow: inset 0 0 6px rgba($black, 0.18);
      box-shadow: inset 0 0 6px rgba($black, 0.1);
      background-color: #e0e0e0;
    }
  }

  .rc-mentions-measure {
    position: absolute;
    left: 0;
    top: 10px;
    right: 0;
    bottom: 0;
    pointer-events: none;
    z-index: -1;
  }
}

.rc-mentions-dropdown {
  position: absolute;
  background: $white;
  overflow: hidden;
  box-shadow: 0 0.25rem 0.5rem rgba($black, 0.12);
  border: 0;
  border-radius: 8px;

  ul.rc-mentions-dropdown-menu {
    list-style: none;
    margin: 0;
    padding: 4px 0;
    overflow-y: auto;
    max-height: 168px;
    scrollbar-color: #e0e0e0 #f5f5f5;
    scrollbar-width: thin;

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba($black, 0.18);
      box-shadow: inset 0 0 6px rgba($black, 0.1);
      border-radius: 0;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
      width: 3px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 0;
      -webkit-box-shadow: inset 0 0 6px rgba($black, 0.18);
      box-shadow: inset 0 0 6px rgba($black, 0.1);
      background-color: #e0e0e0;
    }

    li {
      cursor: pointer;
      padding: 5px 12px;

      img {
        width: 26px;
        height: 26px;
        object-fit: cover;
        margin-right: 8px;
        border-radius: 50%;
        border: 1px solid rgba($black, 0.04);
        transition: all 0.3s;
      }

      span {
        color: $mediumGray;
        font-size: 12px;
        transition: all 0.3s;
      }

      &.rc-mentions-dropdown-menu-item-active {
        img {
          transform: scale(1.2);
          transition: all 0.3s;
        }
      }
    }

    li.searchingMention.rc-mentions-dropdown-menu-item {
      color: $mediumGray;
      font-size: 14px;
      cursor: text;
    }

    li.mentionNotFound {
      color: $error !important;
      font-size: 14px;
      cursor: text !important;
    }
  }
}

.customPostCommentWrap {
  width: 100%;

  textarea {
    color: $grey !important;
    padding: 12px 36px 12px 15px !important;
    width: 100%;
    border-radius: 24px !important;
    background-color: #f2f2f2 !important;
    border-color: #f2f2f2 !important;
    min-height: 47px;
  }
}

.expandIconProfile {
  right: 15px;
  left: unset;
}

.mediaLibraryMsgPg {
  margin-left: -4px;
  margin-right: -4px;

  .notificationDay~.mediaLibraryFilesMsg {
    border-radius: 8px;
    height: 85px;
    width: calc(16.66% - 6px);
    border: 1px solid rgba($black, 0.04);
    overflow: hidden;
    margin: 4px 3px;
    display: inline-block;
    position: relative;
    cursor: pointer;

    video,
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }

    .audioImg {
      height: 40px;
      width: 28px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .videoIcon {
      position: absolute;
      bottom: 5px;
      left: 7px;
      color: $white;
      font-size: 14px;
      z-index: 99;
    }

    .mediaPrice,
    .mediaHasPurchased {
      background: $theme-color;
      color: $white;
      border-radius: 4px;
      cursor: text;
      padding: 0 4px;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      height: 16px;
      position: absolute;
      left: 4px;
      top: 4px;
      z-index: 99;
    }

    .mediaHasPurchased {
      right: 4px;
      left: unset;
    }
  }

  .notificationDay {
    padding-left: 6px;

    &:first-child {
      margin-top: 0;
    }
  }

  .mediaLibrarySpinner {
    display: block;
    text-align: center;
  }

  .blurPreview {

    img,
    video,
    .audioImg {
      filter: blur(7px);
    }

    .mediaLock {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 99;

      .fa {
        font-size: 30px;
        color: $white;
      }
    }
  }
}

.seeMedia {
  font-size: 12px;
  color: $theme-color !important;
  padding: 0 8px;
  text-transform: uppercase;
  text-decoration: none !important;
  font-weight: 500;
}

.reloadBtn {
  background: transparent !important;
  border: 0 !important;
  box-shadow: none !important;
  padding: 0 5px;
  position: absolute;
  bottom: 8%;
  left: 50%;
  transform: translate(-50%, -8%);
  z-index: 99;
  height: 20px;
  line-height: 20px;
  font-size: 14px;
  color: $lightGray !important;
}

.mediaLibraryFilesWrap {
  .reloadBtn {
    bottom: unset;
    top: 50%;
    transform: translate(-50%, -50%);

    i {
      font-size: 20px;
      line-height: unset;
      width: unset;
      color: $lightGray !important;
      float: unset;
      background-color: transparent;
      border-radius: unset;
    }
  }
}

.joinLiveEventWrap {
  display: block;
  margin: 40px auto 15px;
  max-width: 340px;
}

.joinLiveEvent {
  padding: 0 65px;
  display: block;
  width: max-content;
  height: 46px;
  line-height: 47px;
  margin: 0 auto;
}

.deleteAccountBtn {
  padding: 6px 10px;
  display: block;
  color: $error !important;
  font-size: 14px;
  margin: 20px auto 0;
  text-decoration: none !important;
}

.deleteProfile {
  h4 {
    font-size: 15px !important;
    line-height: 1.45;
    font-weight: 400;
  }
}

.playlistThumbnail {
  height: 55px;
  width: 55px;
  border-radius: 6px;
  border: 1px solid $border;
  background-color: $white;
  display: inline-block;
  position: relative;

  img {
    border-radius: 6px;
    object-fit: cover;
    height: 53px !important;
    width: 53px !important;
  }
}

// .makeFavorite {
//   position: absolute;
//   right: 11px;
//   top: 10px;
//   z-index: 100;
// }

.stickyPlayerWrap,
.newStickyPlayerWrap {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 999;

  .delPreview {
    font-size: 20px;
    height: 20px;
    width: 20px;
    line-height: 20px;
    color: $error;
    position: absolute;
    top: -30px;
    right: 12px;
    cursor: pointer;
    background-color: $white;
    border-radius: 50%;
    z-index: 9;
    box-shadow: 0 0.125rem 0.25rem rgba($black, 0.075);
  }
}

.stickyPlayer,
.newStickyPlayer {
  padding: 18px 15px;
  display: flex;
  justify-content: center;
  position: relative;
  background: $theme-color;
  transition: transform 0.3s ease-in-out;
  z-index: 99;

  h4 {
    font-size: 14px;
    color: $white;
  }

  p {
    font-size: 12px;
    color: $secondaryBtnColor;
    margin-top: 1px;
  }

  h4,
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    /* number of lines to show */
    line-clamp: 1;
    -webkit-box-orient: vertical;
    cursor: pointer;
    word-break: break-word;
  }

  .audioPlayerWrap {
    background-color: #f1f3f4;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    // padding: 0 12px;
    padding-left: 13px;
  }

  audio {
    width: 400px;
  }

  .stickyAudioInfo {
    display: flex;
    align-items: center;
    // width: 220px;
    width: 195px;
    position: absolute;
    top: 0;
    height: 100%;
    left: 15px;
  }

  .playerBtn {
    width: 30px;
    height: 30px;
    line-height: 30px;
    color: $theme-color;
    font-size: 14px;
    border-radius: 50%;
    background: #f1f3f4;
    text-align: center;

    &:hover {
      background: #e6e6e6;
    }

    &.active {
      color: #5152fc;
    }
  }
}

.newStickyPlayer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;

  .playerBtn {
    background: transparent;
    color: $white;
    font-size: 22px;
    height: 36px;
    width: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s;

    &:hover,
    &:active {
      background: rgba($mediumGray, 0.5);
      transition: all 0.3s;
    }
  }

  .playPauseBtn {
    width: 46px;
    height: 46px;
    font-size: 32px;
    margin-left: 10px;
    margin-right: 10px;

    .fa-play {
      margin-left: 4px;
    }

    .fa-pause {
      margin-left: 0;
    }
  }

  .timeline {
    background: $secondaryBtnColor;
    width: 100%;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    height: 4px;
    transition: all 0.3s;

    .dragHandle {
      -webkit-appearance: none;
      appearance: none;
      width: 100%;
      padding: 0;
      background: transparent;
      border: 0 !important;
      height: unset;
      position: absolute;
      top: -6px;
      left: 0;

      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 16px;
        height: 16px;
        border-radius: 100%;
        background-color: $white;
        box-shadow: 0 1px 6px rgba($black, 0.2);
        cursor: pointer;
      }

      &::-moz-range-thumb {
        width: 16px;
        height: 16px;
        border-radius: 100%;
        background-color: $white;
        box-shadow: 0 1px 6px rgba($black, 0.2);
        cursor: pointer;
      }
    }

    .progress {
      background: $mediumGray;
      height: 100%;
      transition: 0.3s;
    }
  }

  .controls {
    display: flex;
    align-items: center;
    flex: 1;

    .songDuration {
      display: flex;
      color: $white;
      margin-left: 10px;
      font-size: 12px;
      color: $secondaryBtnColor;

      >* {
        padding: 1px;
      }
    }
  }

  .volumeControlWrap {
    width: 175px;
    display: flex;
    justify-content: flex-end;
  }

  .volumeControl {
    position: relative;
    z-index: 2;

    .volumeButton {
      font-size: 22px;
      height: 36px;
      width: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $secondaryBtnColor;

      .volume {
        transform: scale(0.7);
      }
    }

    .volumeSlider {
      cursor: pointer;
      position: absolute;
      right: 38px;
      top: 14px;
      z-index: -1;
      width: 0;
      height: 8px;
      background: $mediumGray;
      transition: all 0.3s;
      border-radius: 20px;

      .volumePercentage {
        background: $white;
        height: 100%;
        border-radius: 20px;
      }
    }

    &:hover {
      .volumeSlider {
        right: 38px;
        width: 80px;
      }
    }
  }

  .toggleFullScreen {
    color: $white;
    font-size: 20px;
    margin-left: 8px;
    height: 36px;
    width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;

    .fa-chevron-up {
      margin-bottom: -1px;
    }
  }

  .stickyAudioInfo {
    position: relative;
    width: unset;
    flex: 1;
    left: unset;
    top: unset;
    margin-right: 20px;
  }
}

.stickyThumbWrap {
  height: 45px;
  width: 45px;
  border-radius: 5px;
  // border: 1px solid rgba($black, 0.06);
  margin-right: 8px;
  min-width: 45px;
  background-color: $white;
  cursor: pointer;

  img {
    object-fit: cover;
    border-radius: 6px;
  }
}

.fullScreenMediaPlayer,
.customFullScreenMediaPlayer {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background: #ededed;
  background: -moz-linear-gradient(to bottom, #ededed 0%, #000 100%);
  background: -webkit-linear-gradient(to bottom, #ededed 0%, #000 100%);
  background: linear-gradient(to bottom, #ededed 0%, #000 100%);
  bottom: 0;
  left: 0;
  overflow: hidden;
  padding: 50px 15px 100px;
  position: fixed;
  top: 0;
  transform: translateY(100%);
  transition: transform 0.5s ease-in-out;
  width: 100%;
  z-index: 10;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .audioThumbnail {
    height: 300px;
    width: 300px;
  }

  .songName {
    width: 300px;
    max-width: 300px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      font-size: 18px;
      color: $white;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      /* number of lines to show */
      line-clamp: 1;
      -webkit-box-orient: vertical;
    }

    button {
      margin-left: 8px;
      padding: 0 3px;
      height: 27px;
      line-height: 32px;
    }
  }

  .closeFullScreenBtn {
    position: absolute;
    top: 18px;
    right: 18px;
    width: 30px;
    height: 30px;
    text-align: center;
    color: $mediumGray;
    font-size: 18px;
  }

  &.showFullScreen {
    transform: translateY(0%);
    transition: transform 0.5s ease-in-out;

    &+.stickyPlayer {
      background-color: transparent;
      transition: transform 0.5s ease-in-out;

      .stickyAudioInfo,
      .delPreview {
        display: none;
      }
    }
  }
}

.customFullScreenMediaPlayer {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: flex-start;
  background: #111;
  padding-bottom: 90px;

  .audioThumbnailWrap,
  .playListWrap {
    width: 50%;
    padding: 0 15px;
  }

  .audioThumbnailWrap {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 140px);

    .audioThumbnailLargeWrap {
      background-color: $white;
      display: block;
      margin: 0 auto;
      position: relative;
      width: 475px;
      height: 475px;
      background-color: $white;
      border-radius: 8px;
      min-width: 475px;

      img {
        border-radius: 8px;
      }
    }

    .backgroundOverlay {
      background-color: rgba($black, 0.15);
    }

    button {
      position: absolute;
      top: 12px;
      right: 12px;
      height: 28px;
      width: 28px;
      z-index: 100;

      img {
        margin-top: 1px !important;
      }
    }
  }

  .playListWrap {
    overflow-y: auto;
    height: calc(100vh - 140px);

    h5 {
      font-size: 18px;
      font-weight: 500;
      color: $white;
      margin-bottom: 8px;
    }

    ul li {
      display: flex;
      align-items: center;
      padding: 10px 5px;
      cursor: pointer;

      &:not(:last-child) {
        border-bottom: 1px solid rgba($border, 0.08);
      }

      .songThumbnailList {
        min-width: 40px;
        min-height: 40px;
        max-height: 40px;
        background-color: $white;
        border-radius: 4px;
      }

      img {
        border-radius: 4px;
      }

      p {
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        /* number of lines to show */
        line-clamp: 1;
        -webkit-box-orient: vertical;
        color: $white;
        margin-left: 8px;
        margin-right: auto;
      }

      .songDuration {
        font-size: 12px;
        color: $secondaryBtnColor;
        margin-top: 3px;
        margin-left: 4px;
      }
    }
  }
}

.videoQuality {
  position: absolute;
  right: 6px;
  bottom: 67px;
  display: none;

  .btn.btn-secondary {
    background-color: rgba($black, 0.65) !important;
    height: 36px;
    width: 36px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;

    img {
      height: 14px;
    }
  }

  .dropdown-item {
    padding: 8px 10px 4px;
  }

  .dropdown-menu {
    top: 0 !important;
    right: 0 !important;
    transform: translate3d(0px, -137px, 0px) !important;
  }

  .custom-control-label {
    font-size: 12px;

    &::before,
    &::after {
      top: 0px;
    }
  }
}

.portraitVideoWrap,
.landscapeVideoWrap,
.squareVideoWrap {
  position: relative;
  width: 100%;
  padding-top: 100%;
  /* 1:1 */

  .videoPreview {
    height: 100%;
    border-radius: 16px;
    width: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  .playVideo {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 50px;
    width: 50px;
    line-height: 50px;
    color: $white !important;
    font-size: 50px;
    z-index: 99;
    color: $white;
    padding: 0;

    img {
      height: 50px;
    }
  }

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 16px;
  }

  &:hover {
    .videoQuality {
      display: initial;
    }
  }
}

.landscapeVideoWrap {
  padding-top: 56.25%;
  /* 16 / 9 = 1.7777 */

  video {
    object-fit: cover;
  }
}

.previewImgInModal {
  width: 100%;
  max-height: 350px;
  object-fit: contain;
  border: 1px solid rgba($black, 0.08);
  border-radius: 16px;
}

.liveEventCard {
  margin: 15px auto;

  .mediaPhotoWrap-Feed,
  .mediaPhoto-Feed,
  .shadowOverlayImg {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  video {
    border-radius: 0;
    display: block;
  }
}

.textWarning {
  color: $error !important;
}

.swal2-title {
  font-size: 18px;
  font-family: "Poppins", sans-serif;
}

div:where(.swal2-container) .swal2-html-container {
  font-size: 14px !important;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-default-outline {
  border-radius: 25px !important;
  box-shadow: none !important;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-default-outline:focus {
  box-shadow: none !important;
}

div:where(.swal2-container) button:where(.swal2-styled) {
  padding: 0 20px !important;
  font-size: 16px !important;
  height: 40px;
  line-height: 39px;
}

.createEvent {
  label {
    font-size: 14px !important;
    margin-bottom: 5px !important;
    font-weight: 500 !important;
  }

  .custom-control label {
    margin-bottom: 0 !important;
  }

  .css-13cymwt-control,
  .css-t3ipsp-control {
    color: $lightGray !important;
    font-size: 14px;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    border: 1px solid $inputBorder !important;
    background-color: $inputBorder !important;
    border-radius: 8px !important;
    letter-spacing: 0.3px;
    min-height: 46px !important;
    box-shadow: none !important;

    &:focus {
      box-shadow: none !important;
      border-color: $theme-color !important;
    }
  }
}

// live streaming new
.myCallContainer {
  width: 100%;
  height: 100%;

  .zegoUserVideo_click .zegoUserVideo_click .zegoUserVideo_click {
    font-size: 50px !important;
    font-weight: 500 !important;
    margin-bottom: 0 !important;
    width: 120px !important;
    height: 100px !important;
    border-radius: 18px !important;
    color: $theme-color !important;
  }

  .zegoUserVideo_click {
    align-items: center;
    border-radius: 0 !important;
    font-size: 18px !important;

    i {
      width: 10vmin !important;
      height: 10vmin !important;
    }

    &+div {
      div {
        height: unset !important;
        border-radius: 10px !important;
        padding: 3px 10px !important;
        max-width: 100% !important;

        p,
        span {
          margin-bottom: 0;
          font-size: 14px !important;
        }

        span+span {
          // height: 22px !important;
          width: 22px !important;
        }
      }
    }
  }

  .kdokPV9jgsyFtd2e8Ek2 .PutMpvQbQ8ffCLR93qIq {
    left: 10px !important;
    top: 50px !important;
    bottom: unset !important;
    right: unset !important;
    width: max-content !important;
    padding: 0 !important;

    div {
      max-width: unset !important;
    }

    .unmuteVideo {
      margin-bottom: 0 !important;
      font-size: 14px !important;

      // &+ span {
      //   width: 4vmin !important;
      //   height: 4vmin !important;
      // }
    }
  }

  .ZegoRoomMobile_ZegoRoom .sKtK1LFA_jOcb1MuqFNo {
    a {
      background-size: 35px 35px !important;
      width: 48px !important;
      height: 45px !important;
      border-radius: 10px !important;
      cursor: pointer;
    }

    #ZegoLiveButton {
      width: unset !important;
      font-size: 18px !important;
      padding: 0 8px !important;
      background: $theme-color !important;
    }
  }

  .sKtK1LFA_jOcb1MuqFNo {
    height: unset !important;
    padding: 15px 10px !important;
  }

  #ZegoRoomCssMobilePopMore {
    padding: 10px !important;
    bottom: 12% !important;
    right: 25% !important;
    border-radius: 15px !important;

    .dxks8Oo_gSMcyB4NAUS5 {
      div {
        background-image: none !important;
        height: unset !important;
        width: unset !important;
        margin-right: 20px !important;
        margin-left: 20px !important;

        i {
          background-size: 35px !important;
          height: 40px !important;
          width: 40px !important;
          border-radius: 8px !important;
          margin-bottom: 25px !important;
        }

        span {
          font-size: 12px !important;
        }
      }

      &+div {
        bottom: 70px !important;
        right: 25% !important;
      }
    }
  }

  .unmuteVideo {
    margin-bottom: 0 !important;
  }

  .dIzgYQV4CBbzZxzJbwbS .VFa21T9RLFCP0goDE198,
  .dIzgYQV4CBbzZxzJbwbS .eHWwrEDF57fbHO0pxJYw {
    margin-right: 90px !important;
  }
}

.createPoll,
.setGoal {
  label {
    font-size: 14px !important;
    font-weight: 500 !important;
    margin-bottom: 5px !important;
  }
}

.vaultDetailsWrap {
  padding: 10px 15px;
  background-color: $white;
  border-radius: 10px;
}

.button__Button-hkteey-0 {
  background-color: $theme-color !important;
  padding: 0 15px !important;
  border-radius: 8px !important;
  font-weight: 500 !important;
  margin-top: 80px !important;
  color: $white !important;
}

.render-actions__ActionsWrapper-dp6lnv-0 {
  transform: translate(-50%, -50%) !important;
  left: 50% !important;
  width: 100% !important;
}

.oMtNP {
  top: 20px !important;
  right: 20px !important;
  display: flex !important;
  align-items: center !important;
  font-size: 20px !important;
}

// custom video player
.customVideoWrapper {
  position: relative;
  overflow: hidden;

  .videoControlsWrap {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 9px 10px;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transform: translateY(100%);
    transition: transform 0.25s linear;
    background: linear-gradient(to top, rgba($black, 0.25), rgba($black, 0.02));
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }

  .videoSeekBarWrap {
    width: 100%;
    padding-left: 3px;
    padding-right: 3px;
    position: relative;
  }

  .videoSeekBar {
    background: $lightGray;
    cursor: pointer;
    width: 100%;
    height: 4px;
    border-radius: 20px;
  }

  .dragHandle {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    padding: 0;
    background: transparent;
    border: 0 !important;
    height: unset;
    position: absolute;
    top: -5px;
    left: 0;

    &::-webkit-slider-thumb {
      width: 14px;
      height: 14px;
      border-radius: 100%;
      background-color: $white;
      box-shadow: 0 1px 6px rgba($black, 0.2);
      cursor: pointer;
      -webkit-appearance: none;
      appearance: none;
    }

    &::-moz-range-thumb {
      width: 14px;
      height: 14px;
      border-radius: 100%;
      background-color: $white;
      box-shadow: 0 1px 6px rgba($black, 0.2);
      cursor: pointer;
    }
  }

  .videoProgress {
    background: $theme-color;
    height: 100%;
    transition: all 0.3s;
    border-radius: 20px;
  }

  .videoControls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;

    >div {
      display: flex;
      align-items: center;
    }

    .btn {
      width: 33px;
      height: 33px;
      padding: 0;
      transition: all 0.3s;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 29px;
      color: $white;
      text-decoration: none !important;
      margin: 0 5px;
      @include ripple;
      border-radius: 6px;

      img {
        height: 32px;
        width: unset;
        object-fit: contain;
        position: unset;
      }
    }
  }

  .videoTimestamp {
    font-size: 14px;
    color: $white;
    margin-left: 5px;
    font-weight: 600;
    margin-top: 3px;
  }

  .videoVolume {
    display: flex;
    align-items: center;
    position: relative;

    .dragHandle {
      width: 70px;
      top: 10px;
      right: 43px;
      left: unset;
      border-radius: 20px;
    }

    .videoSeekBar {
      width: 70px;
      display: none;
      transition: all 0.3s;
    }

    &:hover .videoSeekBar {
      display: block;
      transition: all 0.3s;
    }
  }

  &.nowPlaying {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    background-color: #111;
    font-weight: 500;
    font-size: 16px;
    border-radius: 16px;
    min-height: 300px;
  }

  .closeVideo,
  .enlargeVideo,
  .dragVideo {
    position: absolute;
    right: 7px;
    top: 5px;
    transform: translateY(-150%);
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    width: 29px;
    height: 29px;
    text-align: center;
    padding: 0;
    text-decoration: none;
    border-radius: 50%;
    background-color: rgba($black, 0.4);
    transition: transform 0.25s linear;
  }

  .enlargeVideo {
    right: unset;
    left: 7px;

    img {
      height: 18px;
      width: unset;
      object-fit: contain;
    }
  }

  .dragVideo {
    cursor: grab !important;
    right: unset;
    left: 50px;
    font-size: 20px;

    &:active {
      cursor: grabbing !important;
    }
  }

  .playPauseVideo {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 50px;
    width: 50px;
    color: $white !important;
    font-size: 50px;
    z-index: 99;
    color: $white;
    padding: 0;
    text-decoration: none;
    transition: all 0.3s;

    img {
      height: 42px;
      object-fit: contain;
      width: unset;
    }
  }

  .playingVideo {
    display: none;
    transition: all 0.3s;
  }

  .videoSettings {
    position: absolute;
    bottom: 53px;
    background: $white;
    border-radius: 6px;
    right: 15px;
    box-shadow: 0 0.125rem 0.25rem rgba($black, 0.075);
  }

  &.fullscreen {
    position: fixed;
    inset: 0;
    z-index: 1031;
    max-height: unset;

    video {
      border-radius: 0;
      width: 100%;
      height: 100%;
      max-height: unset;
    }

    .videoControlsWrap {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .videoControls .btn {
      margin: 0 7px !important;
    }

    .videoVolume {
      .dragHandle {
        right: 47px;
      }
    }
  }

  &.fullscreen.landscapeVideoWrap,
  &.fullscreen.portraitVideoWrap,
  &.fullscreen.squareVideoWrap,
  &.floating.landscapeVideoWrap,
  &.floating.portraitVideoWrap,
  &.floating.squareVideoWrap {
    padding-top: 0;
  }

  .theatre {
    border-radius: 0;
  }

  &.fullscreen.landscapeVideoWrap {
    video {
      object-fit: contain;
    }
  }

  &.floating {
    position: fixed;
    z-index: 100;
    bottom: 15px;
    right: 15px;
    width: 350px !important;
    height: 196.875px !important;
    box-shadow: 0 0.125rem 0.25rem rgba($black, 0.075);

    video {
      height: 100%;
      display: block;
      border-radius: 16px;
    }

    .videoTimestamp {
      display: none;
    }

    &:not(.react-draggable-dragging) {
      -webkit-transition: -webkit-transform 0.5s ease-out;
      /* Safari */
      transition: transform 0.5s ease-out;
    }
  }

  &:hover {
    .videoControlsWrap {
      transform: translateY(0%);
      transition: transform 0.25s linear;
    }

    .closeVideo,
    .enlargeVideo,
    .dragVideo {
      transform: translateY(0%);
      transition: transform 0.25s linear;
    }

    .playingVideo {
      display: flex;
      transition: all 0.3s;
    }
  }

  &:focus {
    .videoControlsWrap {
      transform: translateY(0%);
      transition: transform 0.25s linear;
    }
  }
}

// custom video player

.showMediaModal {
  video {
    height: 100%;
    width: 100%;
    background-color: transparent;
  }
}

.pinnedPost {
  width: 19px;
  height: 19px;
  fill: $theme-color;
}

.delPollOption {
  fill: $theme-color;
  width: 23px;
  height: 23px;
}

.bankInfoModal {
  label {
    font-size: 14px;
    margin-bottom: 5px;
  }
}

.teamMemberModal,
.addOfferModal {
  label {
    font-size: 14px;
    margin-bottom: 5px;
  }

  .custom-control-label {
    margin-bottom: 0;
  }
}

.earningSummaryDateRange {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .DateRangePicker {
    width: max-content;
  }
}

//date range css
.CalendarDay__selected {
  background-color: $theme-color !important;
  border-color: $theme-color !important;

  &:active,
  &:hover {
    background-color: $theme-color !important;
    border-color: $theme-color !important;
  }
}

.CalendarDay__selected_span {
  background-color: rgba(var(--primary-color-rgb), 0.6) !important;
  border-color: rgba(var(--primary-color-rgb), 0.7) !important;
}

.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
  background-color: rgba(var(--primary-color-rgb), 0.6) !important;
  border-color: rgba(var(--primary-color-rgb), 0.7) !important;
  color: $white !important;
}

.customTabContentMyProfile {
  padding: 0 10px;
}

.rightAlignedCalendar {
  .rdtPicker {
    right: 0;
  }
}

.videoMediaLibrary {
  .customVideoWrapper {

    .playingVideo,
    .playPauseVideo {
      // display: none;

      .fa {
        background-color: transparent;
      }
    }

    .videoControlsWrap {
      #skipBack,
      #skipForward,
      #videoMaximize,
      #videoSetting,
      #picInPic // #volOff,

      // #volHigh,
      // #volLow
        {
        display: none;
      }

      .fa {
        background-color: transparent;
      }
    }

    .videoControls {
      .btn {
        width: 25px;
        height: 30px;
        margin: 0 3px;
        font-size: 17px !important;

        img {
          height: 18px !important;
        }
      }
    }

    .videoTimestamp {
      font-size: 12px;
      margin-top: 1px;

      * {
        display: none;
      }

      #videoProgress {
        display: initial;
      }
    }

    .playingVideo {
      display: flex;

      img {
        height: 18px;
      }
    }

    .playPauseVideo {
      height: 30px;
      width: 30px;
    }

    .videoVolume {

      .videoSeekBar,
      .dragHandle {
        width: 35px;
      }

      .dragHandle {
        right: 31px;
        top: 8px;
      }
    }
  }

  .mediaLibraryFilesWrap .fa {
    font-size: 22px;
    line-height: 22px;
  }
}

.settingOptionsWrap {
  .createPostWrap .createPostBtnsWrapper {
    margin-top: 15px;
  }
}

.sendReminderToAll {
  color: $theme-color;
  padding-top: 0;
  padding-bottom: 0;
  text-decoration: underline;
  font-size: 14px;
}

.customPushNotification {
  display: flex;
  background-color: rgba(var(--primary-color-rgb), 0.2);
  border-color: $theme-color;
  padding: 18px 12px;
  width: 100%;
  max-width: 660px;
  margin: 10px auto !important;

  h4 {
    margin-bottom: 0;
    font-size: 15px;
    font-weight: 600;
  }

  p {
    font-size: 14px;
    margin-bottom: 0;
    margin-top: 8px;
    color: $lightGray;

    .btn {
      padding: 0 2px 1px;
      font-size: 14px;
      color: $theme-color;
      text-decoration: none !important;
    }
  }

  img {
    height: 80px;
    width: 80px;
    object-fit: contain;
    margin-right: 12px;
    min-width: 80px;
    background-color: white;
    border-radius: 6px;
    padding: 2px;
  }

  .close {
    top: 5px;
    right: 5px;
    border-radius: 50%;
    background-color: rgba($black, 0.4);
    height: 24px;
    width: 24px;
    padding: 0;
    font-size: 20px;
    color: $white;
    line-height: 24px;
  }
}

.profileCardSettings {
  .profileCarousel {
    .carousel {
      height: 326px;
      width: 978px;
    }

    .carousel-inner {
      height: 326px;
    }
  }
}

.defaultCoverImg {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  img {
    height: unset !important;
    width: unset !important;
    max-height: 100%;
    max-width: 100%;
    position: unset !important;
    top: unset !important;
    left: unset !important;
  }
}

.videoInImageCropModal {
  margin-top: 4px;

  video {
    object-fit: contain;
    height: 100%;
    width: 100%;
    display: block;
  }
}

.comments-Post {
  textarea {
    background-color: #fcfcfc !important;
  }

  &:has(textarea) {
    width: 95%;
  }
}

.massMsgStatsTable {
  tr td {
    vertical-align: top;
  }
}

.profileSignUp {
  max-width: 801px;
}

.uploadCoverImgSignUp {
  height: 267px;
  width: 100%;
  background-color: $inputBorder;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;

  p {
    margin-block: 10px 0;
    font-size: 14px;
  }
}

.profileCarouselSignUp {
  margin: 0 auto 35px;
  position: relative;
  width: 100%;
  max-width: 801px;
  display: block;

  >label {
    width: 100%;
  }
}

// .multiSelectWrap .basic-multi-select {
//   display: flex;

//   &:before,
//   &:after {
//     display: none;
//   }

//   .select__control {
//     border: 1px solid $inputBorder !important;
//     box-shadow: none !important;
//     border-radius: 6px !important;
//   }

//   .select__value-container {
//     padding: 2px 5px;
//   }

//   .select__indicators {
//     align-items: flex-end;
//   }

//   .MuiSelect-select:focus {
//     background: transparent;
//   }
// }

.select__menu {
  border-radius: 6px !important;
  z-index: 99 !important;
  box-shadow: 0px 0px 5px 0px rgba($black, 0.06) !important;
  border: 1px solid #e5e5e5;

  .select__menu-list {
    max-height: 203px;
    padding-top: 0;
    padding-bottom: 0;
  }

  .select__option {
    padding: 9px 10px 7px;
    font-size: 13px;
    font-weight: 400 !important;
    color: $grey;
    font-family: "Poppins", sans-serif;
    word-break: break-all;

    &:not(:last-child) {
      border-bottom: 1px dashed $inputBorder;
    }

    &:first-child {
      border-top-left-radius: 4px !important;
      border-top-right-radius: 4px !important;
    }
  }
}

.addedSubscriberToTags {
  display: flex;
  align-items: center;
  padding: 15px;
  border: 1px solid rgba(201, 201, 201, 0.8);
  border-radius: 10px;
  margin-block: 20px;

  p {
    font-size: 14px;
    margin-bottom: 0;
    flex-shrink: 0;

    span {
      font-weight: 500;
      color: $theme-color;
    }
  }

  .themeBtn {
    flex-shrink: 0;
  }
}

.creatorCategory {
  display: flex;
  flex-wrap: wrap;
  margin-block: 20px;

  .addedTags {
    border-radius: 25px;
    background-color: $white;
    border: 1px solid transparent;
    font-size: 14px;
    color: $grey;
    box-shadow: 0px 1px 0.5px 1.5px rgba($black, 0.06) !important;
    margin-right: 8px;
    margin-bottom: 10px;
    padding-inline: 12px;
    height: 34px;
    min-width: 62px;
    line-height: 33px;
  }

  .btn {
    color: rgba($error, 0.8) !important;
    padding: 0 0 0 8px;
    vertical-align: 0;
  }
}

.customCreateSelect .select__control,
.multiSelectWrap .select__control {
  padding: 0;

  &:hover {
    border-color: transparent;
  }

  .select__multi-value {
    background-color: $white;
  }

  .select__multi-value__label {
    padding-bottom: 2px;
  }

  .select__multi-value__remove {
    svg {
      fill: $error;
    }
  }
}

.customCreateSelect .select__control {
  min-height: 46px;
  height: unset;
}

.select__control--is-focused {
  border-color: $theme-color !important;
  box-shadow: none !important;
}

.tagsListConversation {
  font-size: 12px;
  margin-left: 15px;
  position: relative;
  display: flex;
  align-items: center;

  &:before {
    content: "\f111";
    color: #bbb;
    font-family: "fontAwesome";
    font-size: 4px;
    position: absolute;
    top: 6px;
    left: -10px;
  }

  span {
    color: $theme-color;
    display: inline-block;
    margin-left: 2px;
    font-weight: 500;
  }
}

.tagsListTooltip {
  .tooltip-inner {
    min-width: 200px;
  }

  ul li {
    border: 0;
    margin-bottom: 0;
    padding: 5px 2px;
    border-radius: 0 !important;

    &:not(:last-of-type) {
      border-bottom: 1px solid $inputBorder;
    }
  }
}

.recentWrapWithFilter {
  @extend .recentWrap;
  padding-bottom: 8px;
  padding-left: 5px;
  min-height: 35px;

  .btn {
    padding: 0 8px;
    margin-left: 15px;

    img {
      width: 24px;
    }
  }
}

.msgFilterPopover {
  .popover-body {
    min-width: 300px;
    max-width: 100%;
  }
}

.tabsMsg {
  margin-block: -6px 10px;
  padding-inline: 0 !important;
}

.groupImgChat {
  @extend .userImg;
  position: relative;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.css-1nmdiq5-menu {
  z-index: 99 !important;
}

.customPopoverTag .popover {
  max-width: 280px;
  right: 0;

  .multiSelectWrap .select__control {
    min-height: 46px;
    height: unset;
  }
}

.storyCarousel {
  margin: 25px auto;
  justify-content: flex-start;

  .react-multi-carousel-item {
    .storyImgWrap {
      width: 85px;
      height: 85px;
      padding: 4px;
      border: 2px solid $theme-color;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      position: relative;
      overflow: hidden;

      img {
        height: 73px;
        width: 73px;
        object-fit: cover;
        border-radius: 50%;
      }

      .fa {
        font-size: 32px;
        color: $theme-color;
        margin-top: 2px;
      }

      .liveEventInStory {
        position: absolute;
        bottom: -1px;
        left: 27px;
        background-color: $theme-color;
        color: $white;
        font-size: 10px;
        font-weight: 600;
        text-transform: uppercase;
        height: 15px;
        line-height: 15px;
        padding: 0 4px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }

      &:has(.liveEventInStory) {
        border: 2px solid transparent;
        background: linear-gradient(white, white),
          linear-gradient(to right, $theme-color, #f03f9f 33%, #db2782 66%);
        background-clip: padding-box, border-box;
        /* Gradient only on the border */
        background-origin: border-box;
      }
    }

    p {
      display: block;
      font-size: 12px;
      margin-block: 7px 0;
      width: 85px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
    }
  }

  .react-multiple-carousel__arrow {
    min-width: 35px;
    min-height: 35px;
  }
}

.storyImgUpload {
  height: 275px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $inputBorder;
  border-radius: 8px;
  flex-direction: column;
  cursor: pointer;

  div {
    text-align: center;
    font-weight: 500;
    color: $mediumGray;
    font-size: 14px;

    img {
      width: 51px;
      height: 40px;
      display: block;
      margin: 0 auto 10px;
    }
  }

  .storyImg,
  video {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 8px;
    background-color: $white;
  }
}

// .viewStoryModal {
//   .carousel {
//     .viewStory {
//       height: 100%;
//       width: 100%;
//       max-width: 500px;
//       margin-inline: auto;
//       position: relative;

//       img,
//       video {
//         height: 100%;
//         width: 100%;
//         object-fit: contain;
//       }

//       .delStory {
//         position: absolute;
//         color: #ff2323;
//         padding: 0;
//         top: 12px;
//         right: 5px;
//         height: 23px;
//         width: 23px;
//         line-height: 21px;
//         font-size: 14px;
//         border-radius: 50%;
//         background-color: $white;
//       }

//       .storyText {
//         position: absolute;
//         width: 100%;
//         bottom: 0;
//         left: 0;
//         padding-block: 15px;
//         background: linear-gradient(to top,
//             rgba($black, 0.7),
//             rgba($black, 0.35));

//         p {
//           font-size: 14px;
//           color: $white;
//           margin-bottom: 0;
//         }
//       }
//     }
//   }
// }

.emojiBtnMsg {
  position: absolute;
  bottom: 17.5px;
  right: 40px;
  padding: 0 4px;
  font-size: 22px;
  color: $theme-color !important;
  height: 26px;
  line-height: 26px;
}

.passwordCriteria {
  li {
    border: 0;
    padding: 1px 0;

    label {
      font-weight: 400;
      color: $lightGray;
      margin-bottom: 0 !important;
      font-size: 12px !important;
    }
  }
}

.followerStats {
  p {
    font-size: 14px;
    margin-bottom: 0;
    color: $mediumGray;
    font-weight: 500;
    line-height: 1.25;

    &+span {
      font-size: 12px;
    }
  }

  svg,
  .fanBadge {
    height: 50px;
    width: 50px;
    margin-right: 18px;
  }
}

.followerStatsHead {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  align-items: center;

  h5 {
    font-weight: 500;
    margin-bottom: 0;
    line-height: 1.2;
  }

  svg {
    width: 30px;
    height: 30px;
    margin-right: 0;
  }
}

.progressTrackingWrapper {
  border: 1px solid rgba(201, 201, 201, 0.5);
  border-radius: 12px;
  padding: 15px;
  margin-block: 10px 15px;

  .progressTrackingTxt {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: $grey;
    font-weight: 600;
    justify-content: space-between;
    cursor: pointer;

    .progress {
      height: 6px;
      border-radius: 30px;
      width: 100px;
      margin-left: 15px;
    }

    .progress-bar {
      background: $theme-color;
    }

    i {
      font-size: 14px;
      color: $lightGray;
      margin-left: 10px;
    }
  }

  .progressTrackingWrap {
    margin-top: 30px;

    h6 {
      font-size: 14px;
      margin-bottom: 12px;
      color: $mediumGray;
      font-weight: 600;

      &:not(:first-of-type) {
        margin-top: 10px;
      }
    }

    p {
      font-size: 12px;
      color: $mediumGray;
      margin-bottom: 6px;
      font-weight: 400;
      line-height: 1.25;
    }

    i {
      margin-bottom: 8px;
      font-size: 12px;
      margin-left: 5px;
    }

    .progressTracking {
      margin-inline: 5px;
      width: calc(16.6666% - 10px);
      align-self: flex-end;
      margin-bottom: 20px;

      .progress {
        height: 6px;
        border-radius: 30px;
      }

      .progress-bar {
        background: $theme-color;
      }
    }

    .d-flex {
      .progressTracking {
        &:first-of-type {
          margin-left: 0;
        }
      }
    }

    &.show {
      &:has(+ .progressTrackingTxt i) {
        font-size: 20px;
      }
    }
  }
}

.rankInMenu {
  width: 40px;
  height: 40px;
  padding: 3px;
  position: absolute;
  bottom: -6px;
  right: -12px;
  z-index: 99;
  background-color: $white;
  border-radius: 50%;
  box-shadow: 0 0.125rem 0.25rem rgba($black, 0.1);

  svg {
    width: 34px;
    height: 34px;
    fill: $theme-color;
  }
}

.fanBadgeFanPg {
  position: absolute;
  top: 27px;
  right: 6px;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  box-shadow: 0 0.25rem 0.5rem rgba($black, 0.1);
}

.dobWrap {
  display: flex;

  div {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-self: flex-start;
  }
}

//story view
.storyContainer {
  .storyProfile {
    position: absolute;
    top: 22px;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 1.5%;
    background: rgba($black, 0.9);
    z-index: 1000;

    .storyProfileUser {
      border: 2px solid $theme-color;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 8px;
      min-width: 40px;
      object-fit: cover;
    }

    h5 {
      font-size: 14px;
      font-weight: 500;
      color: $white;
      margin-bottom: 0;
    }

    p {
      font-size: 12px;
      margin-block: 4px 0;
      color: $lightGray;
    }

    .closeModalFeed {
      position: initial;
      background-color: transparent !important;
      font-size: 20px !important;
      color: $secondaryBtnColor !important;
    }

    .delStory {
      color: $secondaryBtnColor;
      padding: 0;
      height: 22px;
      width: 22px;
      line-height: 22px;
      font-size: 17.5px;
      background-color: transparent;
      margin-top: -1px;
      margin-right: 10px;
    }
  }

  .storyContent {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 15px 10px;
    background: rgba($black, 0.9);
    z-index: 1000;
    right: 0;

    h5 {
      color: $white;
      font-size: 14px;
      word-wrap: break-word;
      margin-bottom: 0;
    }
  }
}

.bb-feedback-button-icon {
  background-color: $theme-color !important;
}

.bb-feedback-button {
  bottom: 38px !important;
}

#onesignal-bell-container.onesignal-reset .onesignal-bell-launcher.onesignal-bell-launcher-md {
  bottom: 100px !important;
}

.header-fixed {
  .bb-feedback-button {
    bottom: 20px !important;
  }

  #onesignal-bell-container.onesignal-reset .onesignal-bell-launcher.onesignal-bell-launcher-md {
    bottom: 80px !important;
  }
}

// note: this project uses reactstrap version -> v8.
// media styles
@media all and (max-width: 1199.98px) {
  .product_gallery {
    .gallery_items {
      height: 80px;
    }
  }

  .sendReminder {
    margin-top: 5px;
  }

  .referralHistoryTable {
    .table tbody tr td {
      min-width: 120px;
    }
  }

  .profileCardSettings {
    .profileCarousel {
      .carousel {
        height: 271px;
        width: 813px;
      }

      .carousel-inner {
        height: 271px;
      }
    }
  }

  .bb-feedback-button,
  #onesignal-bell-container.onesignal-reset .onesignal-bell-launcher.onesignal-bell-launcher-md {
    right: 15px !important;
  }
}

@media all and (min-width: 992px) {
  .vaultCustomCol {
    &:nth-child(3n + 1) {
      padding-right: 8px;
    }

    &:nth-child(3n + 2) {
      padding-left: 8px;
      padding-right: 8px;
    }

    &:nth-child(3n + 3) {
      padding-left: 8px;
    }
  }

  .customPopoverBtn {
    background-color: rgba(var(--primary-color-rgb), 0.2) !important;
    border-radius: 25px;
  }

  .input-group-append .input-group-text {
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
  }

  .header-fixed .app-body {
    margin-top: 50px;
  }

  .customColPaddingLiveEvent:nth-child(odd) {
    padding-right: 10px;
  }

  .customColPaddingLiveEvent:nth-child(even) {
    padding-left: 10px;
  }

  .profileCarousel {
    width: 100% !important;
    height: 100% !important;

    .carousel {
      height: 267px;
      width: 801px;
    }

    .carousel-inner {
      height: 267px;
    }
  }
}

@media all and (max-width: 991.98px) {
  .vaultCustomCol {
    &:nth-child(odd) {
      padding-right: 8px;
    }

    &:nth-child(even) {
      padding-left: 8px;
    }
  }

  .customFilterWrap {
    flex-wrap: wrap;
  }

  .sidebar {
    height: calc(100vh - 50px);
  }

  .liveEventStreamingWrap,
  .liveEventStreaming {
    height: calc(100vh - 50px);
    width: 100%;
  }

  .liveEventStreaming * {
    padding: 0 !important;
    width: 100% !important;
  }

  .app-body {
    margin-top: 50px;
  }

  .comment-LiveStreamEvent {
    background-color: transparent;
    height: calc(100% - 185px);
  }

  .commentArea-liveStream {
    bottom: 0;
    background-color: transparent;
  }

  .manageStickerWrap {
    .customPaddingSticker {
      &:nth-child(3n + 1) {
        padding-right: 8px;
        padding-left: 15px;
      }

      &:nth-child(3n + 2) {
        padding-right: 8px;
        padding-left: 8px;
      }

      &:nth-child(3n + 3) {
        padding-left: 8px;
        padding-right: 15px;
      }
    }
  }

  .toggleBtnSubscriber {
    display: block !important;
    margin: 12px 0 5px auto;
    background-color: rgba(var(--primary-color-rgb), 0.2) !important;
    border-radius: 25px;
    padding: 0 12px;
    height: 30px;
  }

  .customFilterSubscriberWrap {
    display: none;
  }

  .customFilterSubscriber {
    padding: 16px 15px 0;
    margin-bottom: 25px;
  }

  .createPostTooltip,
  .emojiBtnCreatePost {
    display: none;
  }

  .typeMessage {
    padding-right: 10px;
  }

  .shoutOutPriceWrap {
    p {
      margin-right: 15px;

      span {
        display: block;
      }
    }
  }

  .mediaLibraryMsgPg {
    .notificationDay~.mediaLibraryFilesMsg {
      height: 60px;
    }
  }

  .mediaLibraryMsgPg {
    .notificationDay~.mediaLibraryFilesMsg {

      .mediaPrice,
      .mediaHasPurchased {
        font-size: 10px;
        height: 15px;
        line-height: 16px;
      }
    }
  }

  .feedCarousel {

    .carousel,
    .carousel-inner,
    .carousel-item {
      height: 400px;
    }
  }

  // .mediaPhotoWrap-Feed,
  // .mediaVideoWrap-Feed,
  // .mediaAudioWrap-Feed,
  // .mediaPhoto-Feed {
  //   max-height: 450px;
  // }

  .progressTrackingWrapper {
    .progressTrackingWrap {
      .progressTracking {
        width: calc(25% - 10px);
      }

      .d-flex {
        .progressTracking {
          &:nth-child(5) {
            margin-left: 0;
          }
        }
      }
    }
  }
}

@media all and (min-width: 768px) and (max-width: 991.98px) {
  .inviteBtn {
    margin-top: 26px;
  }

  .inviteCountLeft,
  .inviteBtn {
    margin-left: 20px;
  }

  .promotion_wrapper {
    .inviteBtn {
      margin-top: 25px;
    }
  }

  .massMsgFilterWrap {
    .colPaddingWrap {

      .colPadding:nth-child(1),
      .colPadding:nth-child(4) {
        padding-left: 10px;
        padding-right: 5px;
      }

      .colPadding:nth-child(2),
      .colPadding:nth-child(3) {
        padding-left: 5px;
        padding-right: 5px;
      }
    }
  }

  .billCycleWrap {
    .dollarWrap {
      padding-left: 4px;
      padding-right: 4px;
    }

    .form-control {
      padding-left: 4px;
      padding-right: 4px;
    }
  }

  .customFilterEarnings {
    .checkboxEarnings {
      width: 20%;
    }
  }

  .profileCarousel {
    .carousel {
      height: 210px;
      width: 630px;
    }

    .carousel-inner {
      height: 210px;
    }
  }

  .profileCardSettings {
    .profileCarousel {
      .carousel {
        height: 218px;
        width: 654px;
      }

      .carousel-inner {
        height: 218px;
      }
    }
  }

  .uploadCoverImgSignUp {
    height: 210px;
  }

  .profileCarouselSignUp,
  .profileSignUp {
    max-width: 630px;
  }
}

@media all and (min-width: 768px) {
  .conversationDetails {
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba($black, 0.18);
      box-shadow: inset 0 0 6px rgba($black, 0.18);
      border-radius: 0;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
      width: 3px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 0;
      -webkit-box-shadow: inset 0 0 6px rgba($black, 0.18);
      box-shadow: inset 0 0 6px rgba($black, 0.1);
      background-color: #e0e0e0;
    }
  }

  .noPaddingWeb {
    padding: 0;
  }

  .customSidebarWrap {
    .hideSideMenu .customSidebar {
      margin-right: -250px;
      margin-left: unset;
    }

    .showSideMenu .customSidebar {
      margin-right: 0;
      margin-left: unset;
    }

    .customSidebar {
      right: 0;
      left: unset;
    }
  }

  .userInfo-Sidebar {
    margin-top: 10px;
    padding-bottom: 22px;

    h5 {
      font-size: 15px;
      font-weight: 600;
      color: $grey;
      margin-bottom: 5px;
      max-width: 210px;
    }

    p {
      font-size: 12px;
      font-weight: 400;
      color: $mediumGray;
      max-width: 210px;
      margin-bottom: 12px;
    }
  }

  .msgWrapWeb {
    .chatListWeb {
      width: 260px;
    }
  }

  .customPgHeight {
    height: calc(100vh - 50px);
  }

  .app-footer {
    display: none;
  }

  .customWrapperFaq {
    background-color: $theme-color;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 250px;
    margin-bottom: 25px;

    .pgTitle {
      margin-top: 0;
      margin-bottom: 25px;
    }

    .someTipsFaq {
      margin-bottom: 0;
      // margin-top: 15px;
    }

    .faqCategoryTitle+.someTipsFaq {
      margin-top: 10px;
    }

    .pgTitle h2,
    .faqCategoryTitle,
    .someTipsFaq,
    .someTipsFaq a,
    .customBackBtn {
      color: $white !important;
    }

    .someTipsFaq a {
      text-decoration: underline;
      font-weight: 500;
    }

    .faqCategoryTitle {
      margin-top: 0;
      margin-bottom: 0;
    }

    .searchBarFaqWrap {
      width: 500px;
      margin-top: 15px;
      margin-bottom: 5px;

      .clearSearch {
        top: 13px;
      }

      input {
        background-color: $white !important;
        border-radius: 25px;

        &:focus {
          border: 0;
        }
      }
    }
  }

  .customPaddingFaq {
    padding-left: 0;
    padding-right: 0;
  }

  #accordion {
    &:first-child {
      margin-top: 8px;
    }

    &:last-child {
      margin-bottom: 18px;
    }
  }

  .addPWATooltipWrap {
    width: 350px;
    bottom: unset;
    top: 28px;
    right: 65px;
    left: unset;
  }

  .addPWATooltip {
    &:after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      margin-left: unset;
      top: 0;
      right: 6.8%;
      left: unset;
      box-sizing: border-box;
      border: 1em solid black;
      border-color: transparent transparent $white $white;
      transform-origin: 0 0;
      transform: rotate(135deg);
      box-shadow: -2px 2px 3px 0 rgba($black, 0.12);
    }
  }

  .notificationSelect {
    display: none;
  }

  .liveEventCard {

    .mediaPhotoWrap-Feed,
    .mediaPhoto-Feed,
    video {
      height: 450px;
    }
  }

  .customColPadding {
    &:nth-child(3n + 1) {
      padding-right: 7px;
    }

    &:nth-child(3n + 2) {
      padding-left: 7px;
      padding-right: 7px;
    }

    &:nth-child(3n + 3) {
      padding-left: 7px;
    }
  }

  .massMsgFilterWrap {

    .colPaddingWrap,
    .colPaddingWrapDropdown {
      margin-left: 0;
      margin-right: 0;

      .colPadding:nth-child(1) {
        padding-left: 10px;
        padding-right: 5px;
      }

      .colPadding:nth-child(2),
      .colPadding:nth-child(3) {
        padding-left: 5px;
        padding-right: 5px;
      }

      .colPadding:nth-child(4) {
        padding-left: 5px;
        padding-right: 10px;
      }
    }
  }

  .customBackBtnFaq {
    margin-top: -1px;
  }
}

@media all and (min-width: 992px) and (max-width: 1199.98px) {
  .msgWrapWeb {
    .chatListWeb {
      width: 280px;
    }
  }

  // .commentArea-liveStream {
  //   bottom: 40px;
  // }

  .comment-LiveStreamEvent {
    height: calc(100% - 190px);
  }

  .profileCarousel {
    height: 100% !important;
    width: 100% !important;
  }
}

@media all and (max-width: 767.98px) {
  .customFilterWrap {

    .form-group,
    .input-group {
      width: calc(50% - 10px);
    }

    .form-group:after {
      content: "";
      flex: auto;
    }
  }

  .customTabs {
    border-bottom: 0;

    .nav-link,
    .nav-link:hover {
      font-size: 14px;
    }
  }

  .uploadBtn {
    margin-bottom: 25px;
  }

  .paymentBtnTier {
    padding: 8px 6px 7px;
    max-width: 280px;
  }

  .scPgBg {
    height: calc(100vh - 153px);
  }

  .noPaddingWeb {
    padding: 0 5px;
  }

  .inviteBtn {
    max-width: 230px;
    margin: 20px auto 10px;
  }

  .customImageCropModal .MuiDialogContent-root {
    padding: 15px;
    width: 100%;
  }

  .addMessageWrap {
    height: calc(100vh - 170px);
  }

  .conversationDetails {
    height: calc(100vh - 329px);
    padding-right: 12px;
  }

  .subscribeBtn {
    max-width: 280px;
  }

  .customSidebarWrap .customSidebar {
    height: calc(100vh - 98px);
  }

  .bottomMenu {
    border-top: 2px solid #f0f0f0;
    border-bottom: 0;
    top: unset;

    ul {
      justify-content: space-between;
    }
  }

  .userInfo-Sidebar {
    margin-top: 10px;
    padding-bottom: 22px;

    h5 {
      font-size: 15px;
      font-weight: 600;
      color: $grey;
      margin-bottom: 5px;
      max-width: 210px;
    }

    p {
      font-size: 12px;
      font-weight: 400;
      color: $mediumGray;
      max-width: 210px;
      margin-bottom: 12px;
    }
  }

  .poweredBy {
    margin: 10px auto 0;
    display: inline-block;
  }

  .loginFooter {

    .poweredBy,
    .socialLinksFooter {
      margin: 0;
    }
  }

  .pgTitle {
    margin: 20px 0 15px;
    padding-bottom: 12px;
    border-bottom: 2px solid #e5e5e5;

    h2 {
      font-size: 18px;
    }
  }

  .customBackBtn {
    height: 22px;
    line-height: 22px;
    font-size: 15px;
    width: 22px;
  }

  .referralHistoryTable,
  .promoteAccount {
    display: none;
  }

  .invitesLeftMobView,
  .referralHistoryList,
  .sendInviteBtn {
    display: block;
  }

  .invitesButtonWrap {
    padding-bottom: 10px;
    margin-bottom: 20px;
    overflow-x: auto;
    width: 100%;
    white-space: nowrap;
    text-align: center;

    button {
      min-width: fit-content;
      margin-right: 8px;
      padding: 0 20px;
    }
  }

  .referralHistoryTxt {
    font-size: 14px;
    text-transform: uppercase;
  }

  .inviteCountLeft {
    display: none;
  }

  .authFormWrapper {
    width: 100%;
  }

  .tags {
    margin-top: 15px !important;
  }

  .installPWA {
    max-width: 150px;
    height: 36px;
    line-height: 36px;
    position: unset;
    top: unset;
    right: unset;
    margin: 30px auto 0;
    display: block;
    font-size: 14px;
  }

  .vaultFolderRow {
    margin-left: 0;
    margin-right: 0;
  }

  .notificationPgTitle {
    padding-bottom: 5px;
    margin-top: 10px;

    h2 {
      display: none;
    }
  }

  .tab-Notification {
    display: none;
  }

  .msgWrapWeb {
    border: 0;
    height: unset;
    height: calc(100vh - 170px);

    .chatListWeb {
      width: 100%;
      border-right: 0;

      .chatList {
        height: calc(100vh - 325px);
      }
    }

    .conversationWeb {
      .customBackBtn {
        display: initial;
      }
    }
  }

  .shareEarnTxt {
    width: 90%;
    margin: 10px auto 25px !important;
    color: $lightGray;
    font-weight: 400;
    font-size: 14px;
  }

  .inviteCountLeft {
    font-weight: 600;
    margin: 20px 0 !important;
    text-align: center;
  }

  .profileWrapper {
    height: 100px;
    width: 100px;
    margin-top: -50px;

    .imagePreview i {
      font-size: 42px;
      margin-top: 0;
    }
  }

  .app-header .navbar-brand {
    display: initial;
  }

  .filterOption {
    flex-wrap: wrap;
    padding: 20px 10px;
    display: none;
    justify-content: space-between;

    .form-group {
      flex: 0 0 48%;
      margin-right: 0;
    }

    &.showfilter {
      display: flex;
    }

    .reset_box {
      width: 48%;
      text-align: center;
    }
  }

  input[type="date" i]::-webkit-calendar-picker-indicator {
    /* width: auto !important; */
    margin: 0;
  }

  .filterIcon {
    display: inline-block;
  }

  .customizeFilter {
    display: none;

    &.showfilter {
      display: flex;
      align-items: center !important;
    }
  }

  .filterOption,
  .customizeFilter {
    padding: 10px;
  }

  .customizeFilter {
    .col-6 {
      padding: 0px 5px;

      .form-group {
        margin-bottom: 15px;
      }
    }
  }

  .order_card_wrapper>.card-header {
    border: none;
  }

  .orderProcess {
    flex-wrap: wrap;

    li {
      padding-bottom: 30px;
      padding-left: 0;

      &::before {
        width: 3px;
        height: 100%;
        left: 10px;
        top: 30px;
      }

      &.success {
        &::after {
          width: 3px;
          height: 100%;
          left: 10px;
          top: 30px;
        }
      }

      span {
        padding-left: 30px;
      }

      .circle {
        position: absolute;
        padding-left: 0;
        top: 10px;
      }
    }
  }

  .order_card_wrapper {
    >.card-header {
      padding-left: 10px;
      padding-right: 10px;
    }

    .card-body {
      padding: 20px 10px !important;

      .marginBottom_30 {
        padding-left: 0;
        padding-right: 0;
      }

      .product_variants {
        >.card-header {
          padding-left: 10px;
          padding-right: 10px;
        }

        .card-body {
          ul {
            li {
              align-items: flex-start;

              button {
                margin: 4px !important;

                &:last-child {
                  margin-left: 15px !important;
                }
              }
            }
          }
        }
      }
    }
  }

  .orderProcess {
    margin-left: 50px;

    li {
      span {
        &.status {
          margin-top: 0;
        }
      }
    }
  }

  .upcomingPastEventWrap {
    margin-top: 22px;
    margin-bottom: 15px;
    padding: 0 10px;

    p {
      font-size: 14px;
    }
  }

  .toggleBtn {
    display: initial;
  }

  .showSideMenu+.main:before {
    height: calc(100vh - 50px);
  }

  // .createBtn {
  //   height: 50px;
  //   width: 50px;
  //   padding: 0;
  //   border: 0;
  //   background-color: $theme-color !important;
  //   border-radius: 50%;
  //   position: fixed;
  //   right: 15px;
  //   bottom: 65px;
  //   display: initial;
  //   z-index: 99;

  //   img {
  //     width: 24px;
  //     margin-left: -1px;
  //   }
  // }

  .liveEventStreamingWrap,
  .liveEventStreaming {
    height: calc(100vh - 98px);
  }

  .liveStreamCommentWrap {
    top: 48px;
    bottom: 50px;
  }

  .shareInvite {
    display: flex;

    .btn {
      width: 32px;
      height: 32px;
      padding: 0;
      border-radius: 50%;
      margin: 3px 15px 3px 0;
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .notificationContentWrap {
    padding-top: 0.25px;
  }

  .shoutOutWrap {
    margin-top: 5px;
  }

  .postLockedOverlay {
    h4 {
      margin-bottom: 10px;
    }

    h3 {
      margin-top: 4px;
    }
  }

  .teamMembersTable {
    display: block;
    border: 0;
    margin-top: 0;
    padding: 0;
  }

  .statsTable {
    display: block;
    border: 0;
    padding: 0;
    margin-top: 15px;
  }

  .app-body {
    margin-top: 48px;
  }

  .hideTooltipMobile {
    display: none;
  }

  .conversationDetailsBroadcast {
    height: calc(100vh - 337px);
  }

  .tabContentMediaLibrary {
    max-height: 500px;
    overflow: auto;
  }

  .mediaLibraryPagination {
    .pagination {
      margin-top: 10px;
    }
  }

  .massMsgListWrap {
    margin-top: 20px;
  }

  .subscriberSelectedWrap {
    margin-top: 18px;
  }

  // .feedCarousel .carousel-control-prev,
  // .feedCarousel .carousel-control-next {
  //   display: none;
  // }

  .billCycleWrap {

    &:nth-child(1),
    &:nth-child(3) {
      padding-left: 15px;
    }

    &:nth-child(2),
    &:nth-child(4) {
      padding-right: 15px;
    }
  }

  .shoutOutMediaWrapCol {
    .audioInShoutSetting {
      max-width: 330px;
    }

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .shoutOutMediaWrap .uploadMedia-ShoutOut {
    height: 125px;
  }

  .customPaddingShoutOut {
    &:nth-child(odd) {
      padding-right: 7px;
      padding-left: 0;
    }

    &:nth-child(even) {
      padding-right: 0;
      padding-left: 7px;
    }
  }

  .settingsTab {
    border-bottom: 1px solid #e6e6e6;

    span {
      display: none;
    }

    .nav-link {
      svg {
        display: block;
        height: 22px;
        width: 22px;
        margin-left: auto;
        margin-right: auto;
      }

      .iconSubscription {
        height: 25px;
        width: 25px;
      }

      i {
        font-size: 23px;
        display: block;
        text-align: center;
        color: $black;
      }

      .activeTabIcon {
        display: none;
      }
    }

    .nav-link.active {
      i {
        display: none;
      }

      .activeTabIcon {
        display: block;
        color: $theme-color;
      }

      svg {
        fill: $theme-color;
      }
    }
  }

  .chartWrap {
    margin-bottom: 40px;
    padding: 12px 0 0 0;
  }

  .topFansWrap {
    border: 0;
    padding: 0;
    margin-top: 10px;
  }

  .customTabsStatement {
    border-bottom: 1px solid #e6e6e6;

    span {
      display: none;
    }

    .nav-link {
      text-align: center;

      svg {
        height: 26px;
        width: 26px;
        display: initial;
      }

      i {
        font-size: 26px;
        color: $mediumGray;
        display: block;
        text-align: center;
        margin-top: 4px;
      }
    }

    .nav-link.active {
      text-align: center;

      svg {
        fill: $theme-color;
      }

      i {
        color: $theme-color;
        text-align: center;
      }
    }
  }

  .sendReminder {
    font-size: 12px;
    border-radius: 5px;
    padding: 0 2px;
    min-width: 110px;
    height: 28px;
    line-height: 28px;
  }

  .freeSubscriptionSwitch {

    .custom-control-label::before,
    .custom-control-input:checked~.custom-control-label::before {
      top: 3px;
    }
  }

  .mediaPhotoWrap-Feed,
  .mediaAudioWrap-Feed,
  .mediaPhoto-Feed {
    max-height: 450px;

    video {
      max-height: 450px;
    }
  }

  .liveEventCard {

    .mediaPhotoWrap-Feed,
    .mediaPhoto-Feed,
    video {
      height: 350px;
    }
  }

  .customColPadding {
    &:nth-child(odd) {
      padding-right: 7px;
      padding-left: 0;
    }

    &:nth-child(even) {
      padding-left: 7px;
      padding-right: 0;
    }
  }

  .massMsgFilterWrap {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 10px;

    .colPaddingWrapDropdown .colPadding .MuiFormGroup-root {
      margin: 5px 0;
    }

    .colPadding {
      &:nth-child(odd) {
        padding-right: 5px;
      }

      &:nth-child(even) {
        padding-left: 5px;
      }
    }

    .btn {
      margin-bottom: 10px;
    }

    .addBtn {
      margin-bottom: 0;
    }
  }

  // custom video player
  .customVideoWrapper {
    &.floating {
      bottom: 55px;
      width: 200px !important;
      height: 112.5px !important;
      border-radius: 16px;
    }
  }

  .myCallContainer {
    .ZegoRoomMobile_ZegoRoom .sKtK1LFA_jOcb1MuqFNo {
      bottom: 50px !important;
    }
  }

  .iframeTrainingPg {
    height: calc(100vh - 105px);
  }

  .inviteCreatorForm {
    margin-top: 20px;
  }

  .customTableColWidth {
    min-width: 150px;
  }

  .customFilterEarnings {
    .checkboxEarnings {
      width: 33%;
    }
  }

  .phoneSettings {
    width: 100%;
  }

  .tabsLiveEvent {

    .nav-link,
    .nav-link:hover {
      font-size: 12px;
    }
  }

  .uploadCoverImgSignUp {
    height: 170px;
  }

  .profileCarouselSignUp,
  .profileSignUp {
    max-width: 510px;
  }

  .profileWrapper {
    .uploadBtn {
      margin-bottom: unset;
      bottom: -5px;
      right: 0;
    }
  }

  .creatorCategory {
    .addedTags {
      padding-inline: 10px;
      font-size: 12px;
    }
  }

  .addedSubscriberToTags {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 30px;
    width: calc(100% - 20px);
    margin-inline: auto;
    padding-inline: 10px;

    p {
      margin-bottom: 10px;
    }

    .multiSelectWrap {
      margin-left: 0 !important;
      width: 100%;
    }

    .themeBtn {
      margin-left: 0 !important;
      margin-top: 15px;
    }
  }

  .storyCarousel {
    margin-block: 18px 15px;

    .react-multi-carousel-item {
      .storyImgWrap {
        width: 72px;
        height: 72px;

        img {
          height: 60px;
          width: 60px;
        }

        .fa {
          font-size: 28px;
        }
      }

      p {
        width: 72px;
      }
    }
  }

  .earningsDataWrap {
    .earningsData {
      p {
        font-size: 12.5px;
      }

      h5 {
        font-size: 12px;
      }
    }

    div {

      &:has(.btn),
      .btn {
        font-size: 12px;
      }
    }
  }

  .followerStats {
    display: initial;
    border: 0;
  }

  .storyContainer {
    padding-top: 0px;
  }

  .header-fixed {
    .bb-feedback-button {
      bottom: 57px !important;
    }

    #onesignal-bell-container.onesignal-reset .onesignal-bell-launcher.onesignal-bell-launcher-md {
      bottom: 105px !important;
    }
  }

  .bb-feedback-button-icon {
    width: 36px !important;
    height: 36px !important;
  
    .bb-logo-logo {
      height: 22px !important;
      width: 22px !important;
      top: 7px !important;
      left: 7px !important;
    }
  }

  #onesignal-bell-container.onesignal-reset .onesignal-bell-launcher.onesignal-bell-launcher-md .onesignal-bell-launcher-button {
    height: 36px !important;
    width: 36px !important;
  }

  .hasCreateBtn {
    .addBtn {
      padding: 0 10px;

      &:has(.createIcon) {
        padding: 0;
        border-radius: 50%;
        width: 38px;
      }

      img {
        height: 22px;
        vertical-align: -5px;
      }
    }
  }
}

@media all and (min-width: 576px) and (max-width: 767.98px) {
  .profileCarousel {
    .carousel {
      height: 170px;
      width: 510px;
    }

    .carousel-inner {
      height: 170px;
    }
  }

  .profileCardSettings {
    .profileCarousel {
      .carousel {
        height: 158px;
        width: 474px;
      }

      .carousel-inner {
        height: 158px;
      }
    }
  }
}

@media all and (max-width: 575.98px) {
  .loginFooter {
    flex-direction: column;
    align-items: center;

    .socialLinksFooter-Login {
      margin-bottom: 8px;
    }
  }

  .noPadding {
    padding-left: 0;
    padding-right: 0;
  }

  .noMargin {
    margin-left: 0;
    margin-right: 0;
  }

  .shadowOverlayImg,
  .mediaPhoto-Feed,
  .mediaPhotoWrap-Feed,
  .mediaVideoWrap-Feed,
  .cardFeed,
  .cancelledEventWrap,
  .customTabContent-Notifications,
  table,
  .chatList,
  .commentSection,
  .mediaVideoWrap-Feed video,
  .mediaPhotoWrap-Feed video {
    border-radius: 0 !important;
    box-shadow: none;
  }

  .ppvPreviewStatsWrap {
    padding: 0;
    margin-bottom: 10px;
  }

  .commentSection {
    border-bottom: 1px solid rgba($black, 0.08);
    margin-bottom: 0;
    padding-bottom: 2px;
    margin-top: 0;
  }

  .cardFeed {
    margin-top: 0;
    margin-bottom: 15px;

    .card-body {
      padding: 0;
    }

    .card-header {
      border: 0;
      padding-left: 10px;
      padding-right: 10px;
    }

    .postTime-Feed {
      margin-left: 6px;
    }
  }

  .subscribeBtn {
    font-size: 14px;
  }

  .scPgBg {
    height: 100%;
  }

  .faqSectionHeader {
    font-size: 16px;
  }

  .mediaPhoto-Feed {
    border-left: 0;
    border-right: 0;
  }

  .tabsWrap-InfluencerDetails {
    padding: 0 10px;

    .customTabs {
      padding: 0;
    }

    .subscribeInfBtn {
      font-size: 14px;
    }
  }

  .customTabContent .tab-pane {
    padding: 2px 0;
  }

  .searchIcon {
    left: 23px;
  }

  .searchChatList {
    width: calc(100% - 28px);
    margin: 0 auto 22px;
  }

  .postLockedOverlay {
    border-left: 0;
    border-right: 0;
    border-radius: 0;
  }

  .table tbody td,
  .tableDataStatus {
    font-size: 12px;
  }

  .conversationDetails {
    height: 100%;
    padding-bottom: 8px;
  }

  .modalFeed_closeIcon {
    margin-top: 0;
  }

  .customBreadcrumb .breadcrumb {
    li {
      font-size: 16px;
    }

    li.active {
      margin-top: 4px;
    }
  }

  .createPostTxtWrap {
    padding: 0 10px;

    h4 {
      font-size: 18px;
    }
  }

  .createPostWrap {
    padding-left: 10px;
    padding-right: 10px;
  }

  .settingOptionsWrap .createPostWrap {
    padding-left: 0;
    padding-right: 0;
  }

  .profileCard .card-header {
    padding: 0 0 2px;
  }

  .pgTitle .massMsgStatsTitle {
    font-size: 21px;
    margin-top: 1px;
  }

  .influencerDetailsWrap {
    flex-direction: column;
    align-items: flex-start;
    padding: 15px 15px 12px;
  }

  .influencerName {
    margin-top: 0;

    h4 {
      margin-bottom: 2px !important;
    }

    p {
      margin-top: 2px;
    }
  }

  .profileDescWrap {
    padding: 6px 15px 15px !important;
    border-bottom: 1px solid rgba($black, 0.08);
  }

  .profileDesc {
    margin: 4px 0;
  }

  .typeMsgWrap,
  .commentArea-liveStream {
    position: fixed;
    bottom: 50px;
    left: 0;
    width: 100%;
    padding: 8px;
    background: $white;
    z-index: 99;
  }

  .commentArea-liveStream {
    background-color: transparent;
    padding: 8px 0 10px;
    position: unset;
  }

  .comment-LiveStreamEvent {
    height: calc(100% - 178px);
    padding-bottom: 10px;
  }

  .alert {
    width: 94%;
    margin: 20px auto;
  }

  .nonSubscriberView {
    margin-bottom: 10px;
  }

  .mediaPhotoWrap-Feed {
    .groupMediaWrap {
      height: 200px;
    }
  }

  .shoutOutPriceWrap {
    margin-top: 15px;
    justify-content: space-between;

    p {
      margin-right: 5px;

      span {
        display: inline-block;
      }
    }

    .input-group {
      .form-control {
        padding-left: 8px;
        padding-right: 5px;
      }

      .dollarWrap {
        padding: 0 16px;
      }
    }
  }

  .filesSelectedTxt {
    font-size: 12px;
    width: 115px;
    flex-wrap: wrap;
  }

  .customImageCropModal .MuiDialogActions-root {
    padding: 0.55rem 0.85rem;
  }

  .vaultFolderDetailsWrap {
    padding: 0;
  }

  .authPgFormWrap {
    border: 0;
    padding: 0;
  }

  .birthday_filed {
    flex-flow: row;
  }

  .mobile_view {
    justify-content: flex-start;
  }

  .customTabs {
    padding-left: 10px;
    padding-right: 10px;
  }

  .notificationContentWrap {
    padding: 0;
  }

  .pgPadding {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .countryWithCode {
    select {
      width: 144px;
      font-size: 12px;
      padding: 5px;
    }
  }

  .authFormWrapper {
    min-height: calc(100vh - 55px);
  }

  .loginWrapper {
    margin-top: 0;
  }

  .customPgHeight {
    background: $white;
  }

  .customTabContent .tab-pane {
    padding: 2px 0;
  }

  .customTabs-Notifications {
    margin-top: 10px;
    padding: 0;
  }

  .customTabsStatement {
    margin-top: 10px;
    padding: 0;

    .nav-link,
    .nav-link:hover {
      font-size: 12px;
    }
  }

  .vaultMediaWrap {
    width: 33.33%;
  }

  .product_gallery {
    .gallery_items {
      height: 60px;
    }
  }

  .mobile_view {
    justify-content: flex-start;
  }

  .app-header .navbar-brand {
    display: initial;
  }

  .PPVCustomPadding {
    .col-6:nth-child(even) {
      padding-right: 0;
      padding-left: 7px;
    }

    .col-6:nth-child(odd) {
      padding-left: 0;
      padding-right: 7px;
    }
  }

  .ppvDetailsWrap {
    padding: 0 10px;
    margin-top: 20px;
  }

  .customMarginMob {
    margin-left: 10px;
    margin-right: 10px;
  }

  .customFilterMob {
    .addBtn {
      padding: 0 20px;
      height: 42px;
      line-height: 42px;
    }

    .dateRange {
      width: 100%;
    }
  }

  .massMsgFilterWrap {
    margin-bottom: 0;
    padding: 10px;
    border-bottom: 1px solid rgba($black, 0.08);
    border-radius: 0;

    .addBtn {
      margin-left: 10px;
      padding: 0 16px;
    }

    .colPaddingWrap,
    .colPaddingWrapDropdown {
      margin-left: -10px;
      margin-right: -10px;

      .colPadding:nth-child(1),
      .colPadding:nth-child(3) {
        padding-left: 10px;
        padding-right: 5px;
      }

      .colPadding:nth-child(2),
      .colPadding:nth-child(4) {
        padding-left: 5px;
        padding-right: 10px;
      }
    }
  }

  .customPadding {
    padding: 0;
  }

  .customPaddingMob {
    width: 100%;
    padding: 0 10px !important;
  }

  .mediaAudioWrap-Feed {
    border-radius: 0;
  }

  .vaultCustomCol {
    &:nth-child(odd) {
      padding-left: 0;
      padding-right: 6px;
    }

    &:nth-child(even) {
      padding-right: 0;
      padding-left: 6px;
    }
  }

  .vaultFolderWrap {
    .shadowOverlayImg {
      border-radius: 12px !important;
    }
  }

  .vaultDesc {
    background: #fafafa;
    padding-left: 10px;
    padding: 12px 10px;
  }

  .customTimeSelector .rdtPicker {
    right: 0;
  }

  .manageStickerWrap {
    padding: 8px 5px 15px;

    .customPaddingSticker {
      &:nth-child(odd) {
        padding-right: 7px;
        padding-left: 10px;
      }

      &:nth-child(even) {
        padding-left: 7px;
        padding-right: 10px;
      }
    }
  }

  .liveStreamCommentWrap {
    top: unset;
    bottom: 50px;
    width: 100%;
    height: 400px;

    &.show+.liveStreamCommentToggle {
      right: 15px;
    }
  }

  .commentSectionWrap-liveStream {
    max-width: unset;
    width: 100%;
    height: 100%;
  }

  .creatorName-liveStream,
  .liveStreamName {
    color: $lightGray;
  }

  .shoutOutWrap {
    padding: 0;
  }

  .footerFeed {
    padding-left: 5px;
    padding-right: 5px;

    .btn {
      &:first-child {
        padding-left: 6px;
      }
    }

    span {
      &:first-child {
        margin-left: 6px;
      }
    }
  }

  .filterWrap-Feed {
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 6px;
  }

  .msgStatsMob {
    padding: 0;

    .customColMassMsg {
      padding-left: 10px !important;
      padding-right: 10px !important;
    }
  }

  .customMarginStatement {
    margin-left: -10px;
    margin-right: -10px;

    .col-6:nth-child(1) {
      padding-left: 10px;
      padding-right: 5px;
    }

    .col-6:nth-child(2) {
      padding-right: 10px;
      padding-left: 5px;
    }

    .col-12 {
      padding-right: 10px;
      padding-left: 10px;
    }
  }

  .earningStatsSummary {
    padding: 0;
    border: 0;
    margin-top: 18px;

    li {
      border-bottom: 0;
      padding: 10px 0;
    }
  }

  .customFilterEarnings {
    .checkboxEarnings {
      width: 50%;
    }
  }

  .myProfileCard {
    .coverImgWrap {
      height: 250px;
      min-height: 250px;
    }

    .themeBtn {
      max-width: 200px;
      font-size: 14px;
    }

    .editProfile {
      max-width: 120px;
      margin-right: 10px;
    }
  }

  .toggleBtnSubscriber {
    margin-right: 10px;
  }

  .customFilterSubscriber {
    padding-bottom: 16px;
  }

  .mediaBtn {
    img {
      height: 19px;
    }

    .videoIcon {
      height: 18px;
    }

    .audioIcon {
      height: 21px;
    }

    .priceIcon {
      height: 20px;
    }
  }

  .addPreviewVideo {
    margin-top: 8px;
  }

  .msgPreviewWrap {
    margin-top: 5px;
  }

  .sendMsgBtn {
    img {
      height: 21px;
    }
  }

  .dateTimePickerWrap .rdtPicker .rdtDays table {
    width: 100%;
  }

  .addMessageWrap {
    height: calc(100vh - 202px);
  }

  .mediaLibraryTab {
    padding: 0;

    .nav-item {
      margin-bottom: 0;
    }

    .nav-link,
    .nav-link:hover,
    .nav-link:active {
      font-size: 12px;
      padding: 0.3rem;
      height: 52px;
    }

    .nav-link span {
      display: block;
    }
  }

  .massMsgListWrap {
    padding-top: 0;

    .searchChatList {
      width: 100%;
    }
  }

  .subscriberSelectedWrap {
    width: calc(100% - 20px);
    margin-left: auto;
    margin-right: auto;

    .subscriberSelected {
      padding: 6px 5px;
    }
  }

  .billCycleWrap {

    &:nth-child(1),
    &:nth-child(3) {
      padding-left: 0;
    }

    &:nth-child(2),
    &:nth-child(4) {
      padding-right: 0;
    }

    .dollarWrap {
      padding-left: 5px;
      padding-right: 5px;
    }

    .form-control {
      padding-left: 5px;
      padding-right: 6px;
    }
  }

  .subscriptionTable {
    tr td {
      font-size: 13px;
    }

    tr th {
      line-height: 1.2;
    }

    .btn:not(:last-child) {
      margin-right: 3px;
    }
  }

  // .expandIcon {
  //   display: none;
  // }

  .expandIconProfile {
    display: initial;
  }

  .donutChart {
    .apexcharts-legend.apx-legend-position-right {
      top: 5px !important;
    }

    .apexcharts-legend-text {
      font-size: 12px !important;
      vertical-align: 2px !important;
    }
  }

  .vaultEarningStatsWrap {
    background-color: #fafafa;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 30px;
    margin-top: 25px;
  }

  .goalDataWrap {
    padding-left: 10px;
    padding-right: 10px;
  }

  .sortReferralWrap {
    .form-control {
      width: 100px;
    }
  }

  .selectMethod div p {
    font-size: 11px;
  }

  .openInAppWrap {
    display: initial;
  }

  .mediaLibraryMsgPg {
    .notificationDay~.mediaLibraryFilesMsg {
      width: calc(25% - 6px);
    }
  }

  .passwordInput {
    padding-right: 35px;
  }

  .cardFeed+.commentSection {
    padding-left: 10px;
    padding-right: 10px;
  }

  .portraitVideoWrap,
  .landscapeVideoWrap,
  .squareVideoWrap {

    .videoPreview,
    video {
      border-radius: 0;
    }

    // &:focus {
    //   .videoQuality {
    //     display: initial;
    //   }
    // }
  }

  .portraitVideoWrap {
    padding-top: 177.77%;
  }

  .mediaPhotoWrap-Feed,
  .mediaAudioWrap-Feed,
  .mediaPhoto-Feed {
    max-height: 400px;

    video {
      max-height: 400px;
    }
  }

  .postLockedOverlay {
    .iconLock {
      height: 51px;
    }
  }

  .pollOptionWrap,
  .totalVotes {
    padding-left: 10px;
    padding-right: 10px;
  }

  .createPostBtnsWrapper {
    align-items: flex-start;
  }

  .createPostBtnsWrap {
    flex-wrap: wrap;
  }

  .feedCarousel {
    border-radius: 0;

    .carousel,
    .carousel-inner,
    .carousel-item {
      border-radius: 0;
      height: 350px;
    }

    video,
    .carouselImg,
    .videoPreview {
      border-radius: 0;
    }
  }

  .liveEventCard {
    margin-top: 10px;
  }

  .upcomingPastEventWrap {
    margin-bottom: 20px;
  }

  .customFilterShare {
    .setExpirationDateWrap {
      .custom-control {
        width: 50%;
      }
    }
  }

  .customFilterWrap {
    margin-top: -8px;
    border-bottom: 1px solid rgba($black, 0.08);
    border-radius: 0;

    .dateRange {
      width: 100%;
    }
  }

  .mediaVideoWrap-Feed {
    max-height: unset;

    video,
    .customVideoWrapper {
      max-height: unset;
    }
  }

  .vaultFolderWrap {

    .card-body,
    .folderCoverPic {
      height: 180px;
    }

    video {
      max-height: 180px;
    }
  }

  .vaultMediaThumbWrap {
    height: 180px;

    video,
    .vaultMediaThumb {
      height: 180px;
    }
  }

  .vaultDetailsWrap {
    padding: 0;
  }

  .tabContentMediaLibrary {
    max-height: 350px;
  }

  .joinLiveEventWrap {
    border-radius: 15px;
    padding: 30px 10px;
    text-align: center;
    margin: 15px auto;
  }

  // custom video player
  .customVideoWrapper {
    &.nowPlaying {
      border-radius: 0;
    }

    .videoVolume {
      display: none;
    }

    .videoControlsWrap {
      border-radius: 0;
    }

    .videoControls .btn {
      margin: 0 3px;
    }

    .playPauseVideo {
      height: 40px;
      width: 40px;
      font-size: 40px;

      img {
        height: 34px;
      }
    }
  }

  // custom video player

  .notificationPgTitle {
    margin-bottom: 0;
  }

  .customTabsMyProfile {
    .customTabs {
      padding: 0;
    }
  }

  .customTabContentMyProfile {
    padding: 0;
  }

  .tierInfoBody {
    padding: 15px 10px 5px;
  }

  .customPaddingShoutOut {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .sendReminderToAll {
    font-size: 12px;
  }

  .profileCarousel,
  .profileCardSettings .profileCarousel {
    .carousel {
      height: 100%;
      width: 100%;
    }

    .carousel-inner {
      height: 100%;
    }
  }

  .tabsLiveEvent {
    padding-left: 5px;
    padding-right: 5px;
    align-items: flex-end;
  }

  .uploadCoverImgSignUp {
    height: 125px;

    .uploadImg {
      height: 50px;
    }
  }

  .profileCarouselSignUp,
  .profileSignUp {
    max-width: 100%;
  }

  .profileTabSignUp {
    padding-inline: 0;
  }

  .tagsListConversation {
    div {
      max-width: 7ch;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .progressTrackingWrapper {
    .progressTrackingWrap {
      .progressTracking {
        width: calc(50% - 10px);
      }

      .d-flex {
        .progressTracking {
          &:nth-child(3) {
            margin-left: 0;
          }
        }
      }
    }
  }

  .makeFavorite {
    margin-left: 3px;
  }

  .receiverName-Chat {
    h4 {
      max-width: 7ch;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .storyCarousel {
    padding-inline: 10px;
  }

  .referralHistoryList {
    li {
      .referralInfo {
        p {
          max-width: 18ch;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .bb-feedback-button {
    bottom: 20px !important;
  }

  #onesignal-bell-container.onesignal-reset .onesignal-bell-launcher.onesignal-bell-launcher-md {
    bottom: 70px !important;
  }
}

@media all and (max-width: 480px) {
  .Card_image_wrap {
    height: 150px;
  }

  .order_card_wrapper {
    .card-body .product_variants {
      .card-body ul li button:last-child {
        margin-left: 25px !important;
      }
    }
  }

  .orderProcess {
    margin-left: 30px;
  }
}

@media all and (min-width: 1200px) {
  .msgWrapWeb {
    .chatListWeb {
      width: 380px;
    }
  }

  .liveEventCard {

    .mediaPhotoWrap-Feed,
    .mediaPhoto-Feed,
    video {
      height: 400px;
    }
  }

  .customColPadding {
    &:nth-child(4n + 1) {
      padding-right: 7px;
      padding-left: 15px;
    }

    &:nth-child(4n + 2) {
      padding-left: 7px;
      padding-right: 7px;
    }

    &:nth-child(4n + 3) {
      padding-right: 7px;
      padding-left: 7px;
    }

    &:nth-child(4n + 0) {
      padding-left: 7px;
      padding-right: 15px;
    }
  }
}

// ie11 floating footer temp fix, react only
@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
  #root {
    display: flex;
    flex-direction: column;
  }
}

.checkEmail {
  height: 70px;
  display: block;
  margin: -10px auto 0;
}

#otpless {
  display: block;
  margin: 0 auto;
  cursor: pointer;
}

.FIE_text-font-family-option {
  label {
    margin: 0 !important;
  }
}